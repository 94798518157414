import React from 'react';

const Aboutus = () => {
  return (
    <>
      {/* <section>
        <div className="about-solfin header-space">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-12">
                        <div className="solfin-heading">
                            <h2 className="fw-bold heading-color">About <span className="text-theme-green">us</span></h2>
                        </div>
                    </div>
                </div>
             
                <div className="who-we-are-about">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="what-we-do-left">
                                <div className="solfin-heading">
                                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                                    WHO WE  ARE
                                  </div>
                                  <h2 className="fw-bold heading-color">Lets <span className="text-theme-green">Build</span> our Sustainable Future</h2>
                                </div>
                               
                                <div className="text-para mt-xl-5 mt-4 pb-lg-1">
                                  <p>Solfin leverages the EPC relationships to its customers for world class Solar installations and leverages its banking relationships to provide faster access to finance </p>
                                </div>
                                <div className="solfin-action mt-xl-5 mt-4">
                                  <a href="#" className="text-decoration-none"><span className="btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5">Learn More</span> <img className="ms-3" src="/images/long-next-arrow.svg" alt="Learn More" title="Learn More"/></a>
                                </div>
                              </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-we-we-are-img position-relative">
                                <div className="img-hover-effect">
                                    <img src="/images/man-with-white-helmet-near-solar-panel.jpg" alt="Who We Are" title="Who We Are"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    
    </section> */}

      {/*<section>
        <div className="solfin-vision-mission">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-12">
                        <div className="solfin-heading">
                            <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">OUR VISION AND MISSION</div>
                            <h2 className="fw-bold heading-color">Our <span className="text-theme-green">Vision</span> &amp; Mission</h2>
                          </div>
                         
                          <div className="vision-mission-text mt-xl-5 mt-4">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer.</p>
                          </div>
                    </div>
                  
                </div>
              
                <div className="list-mission">
                    <div className="row mt-xl-5 mt-md-3 mt-1 g-4">
                        <div className="col-md-4">
                            <div className="feature">
                                <div className="feature-wrap theme-green-bg p-xl-5 p-4">
                                <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                                    <img src="/images/energy-solutions.svg" alt="Energy Solutions" title="Energy Solutions"/>
                                </div>
                                
                                <div className="feature-text mt-xl-5 mt-4">
                                    <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                               
                                </div>
                               
                            </div>   
                        </div>
                      
                        <div className="col-md-4">
                            <div className="feature">
                                <div className="feature-wrap light-gray-bg p-xl-5 p-4">
                                <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                                    <img src="/images/energy-solutions.svg" alt="Energy Solutions" title="Energy Solutions"/>
                                </div>
                             
                                <div className="feature-text mt-xl-5 mt-4">
                                    <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                                
                                </div>
                               
                            </div>
                            
                        </div>
                     
                        <div className="col-md-4">
                            <div className="feature">
                                <div className="feature-wrap theme-green-bg p-xl-5 p-4">
                                <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                                    <img src="/images/energy-solutions.svg" alt="Energy Solutions" title="Energy Solutions"/>
                                </div>
                               
                                <div className="feature-text mt-xl-5 mt-4">
                                    <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                              
                                </div>
                            
                            </div>
                         
                        </div>
                       
                        <div className="col-md-4">
                            <div className="feature">
                                <div className="feature-wrap light-gray-bg p-xl-5 p-4">
                                <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                                    <img src="/images/energy-solutions.svg" alt="Energy Solutions" title="Energy Solutions"/>
                                </div>
                               
                                <div className="feature-text mt-xl-5 mt-4">
                                    <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                             
                                </div>
                               
                            </div>
                          
                        </div>
                        
                        <div className="col-md-4">
                            <div className="feature">
                                <div className="feature-wrap theme-green-bg p-xl-5 p-4">
                                <div className="feature-icon d-flex align-items-center justify-content-center rounded-pill bg-white ms-md-auto">
                                    <img src="/images/energy-solutions.svg" alt="Energy Solutions" title="Energy Solutions"/>
                                </div>
                               
                                <div className="feature-text mt-xl-5 mt-4">
                                    <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                              
                                </div>
                             
                            </div>
                         
                        </div>
                       
                        <div className="col-md-4">
                            <div className="feature">
                                <div className="feature-wrap light-gray-bg p-xl-5 p-4">
                                <div className="feature-icon theme-green-bg d-flex align-items-center justify-content-center rounded-pill ms-md-auto">
                                    <img src="/images/energy-solutions.svg" alt="Energy Solutions" title="Energy Solutions"/>
                                </div>
                               
                                <div className="feature-text mt-xl-5 mt-4">
                                    <h6 className="fw-bold heading-color">Lorem ipsum</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                </div>
                                
                                </div>
                               
                            </div>
                        
                        </div>
                        
                    </div>
                   
                </div>
                
            </div>
        </div>
    </section>
*/}
      <section>
        <div className="solfin-team mt-5">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <div className="small-heading mb-3 fw-semibold position-relative text-uppercase">
                    THE TEAM
                  </div>
                  <h2 className="fw-bold heading-color">
                    Our <span className="text-theme-green">Team</span> Members
                  </h2>
                </div>
                {/* <!-- /solfin-heading --> */}
              </div>
              {/* <!-- /col-12 --> */}
            </div>
            {/* <!-- /row --> */}
            <div className="team-members mt-xl-5 mt-3">
              <div className="row g-3 pt-lg-3">
                <div className="col-lg-3 col-sm-6">
                  <div className="member-detail">
                    <div className="member-img img-hover-effect overflow-hidden position-relative">
                      <img
                        src="/images/gautam.png"
                        alt="Gautam Kaushik"
                        title="Gautam Kaushik"
                        height={'400px'}
                      />
                    </div>
                    {/* <!-- /member-img overflow-hidden position-relative --> */}
                    <div className="member-info mt-xl-2 mt-md-2 mt-1 mb-md-0 mb-4">
                      <h6 className="mb-xl-2 mb-1 fw-bold">Gautam Kaushik</h6>
                      <small>Founder and Director</small>
                    </div>
                    {/* <!-- /member-info --> */}
                  </div>
                  {/* <!-- /member-detail --> */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="member-detail">
                    <div className="member-img img-hover-effect overflow-hidden position-relative">
                      <img
                        src="/images/pramod.png"
                        alt="Pramod Mahanta"
                        title="Pramod Mahant"
                        height={'400px'}
                      />
                    </div>
                    {/* <!-- /member-img overflow-hidden position-relative --> */}
                    <div className="member-info mt-xl-2 mt-md-2 mt-1 mb-md-0 mb-4">
                      <h6 className="mb-xl-2 mb-1 fw-bold">Pramod Mahanta</h6>
                      <small>Founder and Director</small>
                    </div>
                    {/* <!-- /member-info --> */}
                  </div>
                  {/* <!-- /member-detail --> */}
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="member-detail">
                    <div className="member-img img-hover-effect overflow-hidden position-relative">
                      <img
                        src="/images/ankit-doshi.jpeg"
                        alt="Ankit Doshi"
                        title="Ankit Doshi"
                        height={'400px'}
                      />
                    </div>
                    {/* <!-- /member-img overflow-hidden position-relative --> */}
                    <div className="member-info mt-xl-2 mt-md-2 mt-1 mb-md-0 mb-4">
                      <h6 className="mb-xl-2 mb-1 fw-bold">Ankit Doshi</h6>
                      <small>Director and Investor</small>
                    </div>
                    {/* <!-- /member-info --> */}
                  </div>
                  {/* <!-- /member-detail --> */}
                </div>
                {/* <!-- /col --> */}
                <div className="col-lg-3 col-sm-6">
                  <div className="member-detail">
                    <div className="member-img img-hover-effect overflow-hidden position-relative">
                      <img
                        src="/images/chintan-shah.jpg"
                        alt="Chintan Shah"
                        title="Chintan Shah"
                        height={'400px'}
                      />
                    </div>
                    {/* <!-- /member-img overflow-hidden position-relative --> */}
                    <div className="member-info mt-xl-2 mt-md-2 mt-1 mb-md-0 mb-4">
                      <h6 className="mb-xl-2 mb-1 fw-bold">Chintan Shah</h6>
                      <small>Director and Investor</small>
                    </div>
                    {/* <!-- /member-info --> */}
                  </div>
                  {/* <!-- /member-detail --> */}
                </div>
                {/* <!-- /col --> */}
                <div className="col-lg-3 col-sm-6">
                  <div className="member-detail">
                    <div className="member-img img-hover-effect overflow-hidden position-relative">
                      <img
                        src="/images/rushabh-doshi.jpeg"
                        alt="Rushabh Doshi"
                        title="Rushabh Doshi"
                        height={'400px'}
                      />
                    </div>
                    {/* <!-- /member-img overflow-hidden position-relative --> */}
                    <div className="member-info mt-xl-2 mt-md-2 mt-1 mb-md-0 mb-4">
                      <h6 className="mb-xl-2 mb-1 fw-bold">Rushabh Doshi</h6>
                      <small>Director and Investor</small>
                    </div>
                    {/* <!-- /member-info --> */}
                  </div>
                  {/* <!-- /member-detail --> */}
                </div>
                {/* <!-- /col --> */}

                {/* <!-- /col --> */}
              </div>
              {/* <!-- /feature-wrap --> */}
            </div>
            {/* <!-- /team-members --> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutus;
