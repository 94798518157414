import React from 'react';
import {useQuery} from 'react-query';
import * as querydata from '../../../dataService/DataService';

const RateMatrix = props => {
  const getallratewattagecomponents = useQuery(
    'wattagecomponents',
    querydata.getallratewattagecomponents
  );

  const getallratematrixcomponents = useQuery(
    'matrixcomponents',
    querydata.getallratematrixcomponents
  );

  if (getallratewattagecomponents.isLoading) {
    return <div>Loading...</div>;
  }

  if (getallratewattagecomponents.error) {
    return <div>Error:</div>;
  }

  if (!getallratewattagecomponents.data) {
    return <div>No data fetched. </div>;
  }
  if (getallratematrixcomponents.isLoading) {
    return <div>Loading...</div>;
  }

  if (getallratematrixcomponents.error) {
    return <div>Error:</div>;
  }

  if (!getallratematrixcomponents.data) {
    return <div>No data fetched. </div>;
  }
  return (
    <>
      <div className="solfin-table">
        <div className="table-responsive">
          <div className="table-wrap">
            <table className="table mb-0" id="ratematrixtable">
              <thead>
                <tr>
                  <th>Sr. no.</th>
                  <th>Components</th>
                  {getallratewattagecomponents &&
                    // getallratewattagecomponents.data &&
                    // getallratewattagecomponents.data.data &&
                    getallratewattagecomponents.data.data.map(
                      (wattageObj, index) => (
                        <React.Fragment key={index}>
                          <th>{wattageObj.option}</th>
                        </React.Fragment>
                      )
                    )}
                </tr>
              </thead>
              <tbody id="ratematrixbody">
                {getallratewattagecomponents &&
                  getallratewattagecomponents.data &&
                  getallratewattagecomponents.data.data &&
                  getallratematrixcomponents.data.data.map(
                    (outerObj, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{outerObj.option}</td>
                          {getallratewattagecomponents &&
                            getallratewattagecomponents.data &&
                            getallratewattagecomponents.data.data &&
                            getallratewattagecomponents.data.data.map(
                              (innerObj, index1) => (
                                <React.Fragment key={index1}>
                                  <td>
                                    <div
                                      data-component_name={outerObj.option}
                                      data-rate_matrix_component_id={
                                        outerObj.option_id
                                      }
                                      data-wattage={innerObj.option}
                                      data-rate_matrix_wattage_component_id={
                                        innerObj.option_id
                                      }
                                      className="input_details input-group"
                                    >
                                      {outerObj.option_code == 'TAT' ? (
                                        <input
                                          id={
                                            outerObj.option_code +
                                            innerObj.option_code
                                          }
                                          onChange={props.updateTotals}
                                          type="number"
                                          min="0"
                                          step="0.01"
                                          className="inputvalues form-control value-input d-flex align-items-center p-1 "
                                          disabled={props.disabled}
                                        />
                                      ) : (
                                        <>
                                          <span className="input-group-text fw-normal">
                                            ₹
                                          </span>

                                          <input
                                            id={
                                              outerObj.option_code +
                                              innerObj.option_code
                                            }
                                            onChange={props.updateTotals}
                                            data-code={innerObj.option_code}
                                            type="number"
                                            className="inputvalues form-control value-input d-flex align-items-center p-1 "
                                            disabled={props.disabled}
                                            min="0"
                                            step="0.01"
                                          />

                                          <span className="input-group-text fw-normal">
                                            /Kwp
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </React.Fragment>
                              )
                            )}
                        </tr>
                      </React.Fragment>
                    )
                  )}
                <tr className="totals">
                  <td>&nbsp;</td>
                  <td>
                    <span className="fw-bold text-uppercase w-100 d-flex justify-content-end">
                      Total Cost
                    </span>
                  </td>

                  {getallratewattagecomponents &&
                    getallratewattagecomponents.data &&
                    getallratewattagecomponents.data.data &&
                    getallratematrixcomponents.data.data.map(
                      (outerObj, index) => (
                        <React.Fragment key={index}>
                          {getallratewattagecomponents &&
                            getallratewattagecomponents.data &&
                            getallratewattagecomponents.data.data &&
                            getallratewattagecomponents.data.data.map(
                              (innerObj, index1) => (
                                <React.Fragment key={index1}>
                                  {getallratewattagecomponents &&
                                  getallratewattagecomponents.data &&
                                  getallratewattagecomponents.data.data &&
                                  getallratematrixcomponents.data.data.length ==
                                    index + 1 ? (
                                    <td>
                                      <div className="input-group">
                                        <span className="input-group-text fw-normal">
                                          ₹
                                        </span>
                                        <input
                                          data-code={innerObj.option_code}
                                          disabled
                                          type="number"
                                          className="totalvalues form-control value-input d-flex align-items-center p-1"
                                          min="0"
                                          step="0.01"
                                        />
                                        <span className="input-group-text fw-normal">
                                          /Kwp
                                        </span>
                                      </div>
                                    </td>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              )
                            )}
                        </React.Fragment>
                      )
                    )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RateMatrix;
