import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Box, Button, Modal, Typography } from '@mui/material';
import { login } from '../../dataService/DataService';

const loginSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Please enter your email'),
  password: Yup.string().required('Please enter your password')
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maaxWidth: 556,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 2
};

const CustomModal = ({
  isModalOpen,
  setIsModalOpen,
  multipleUserData,
  handleMultipleUserSubmit
}) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-title"
          component="h5"
          sx={{ textAlign: 'center', lineHeight: 1.2 }}
        >
          You have multiple account types tied to this email. Please choose your
          login preference:
        </Typography>
        <Box mt={6} className="buttons-group d-flex justify-content-center">
          {multipleUserData.map((data) => (
            <Button
              key={data.user_type_id}
              onClick={() => handleMultipleUserSubmit(data.user_type_id)}
              variant="contained"
              color="primary"
              sx={{
                maxWidth: 'unset !important',
                width: 'unset !important'
              }}
              className="btn green-btn"
            >
              {data.user_type}
            </Button>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

const Login = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [multipleUserData, setMultipleUserData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      userTypeId: null
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      loginMutation.mutate(values);
    }
  });

  const loginMutation = useMutation(login, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      if (data.multipleUsers) {
        setMultipleUserData(data.data);
        setIsModalOpen(true);
      } else {
        handleLoginSuccess(data);
      }
    }
  });

  const handleLoginSuccess = useCallback(
    (data) => {
      const userTypeCode = data.otherUserData.user_type_code;
      switch (userTypeCode) {
        case 'ADMIN':
          navigate('/admin/application');
          break;
        case 'EPC':
          navigate(
            data.isDistributor ? '/distributor-dashboard' : '/epc-dashboard'
          );
          break;
        case 'CUSTOMER':
          navigate('/customer-dashboard');
          break;
        case 'DSA':
          navigate('/dsa-dashboard');
          break;
        case 'MANUFACTURER_PARTNER':
          navigate('/manufacturer-dashboard');
          break;
        default:
          toast.error('Unknown user type');
      }
    },
    [navigate]
  );

  const handleMultipleUserSubmit = useCallback(
    (userTypeId) => {
      formik.setFieldValue('userTypeId', userTypeId);
      formik.submitForm();
      setIsModalOpen(false);
    },
    [formik]
  );

  const toggleShowPassword = useCallback(
    (event) => {
      event.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword]
  );

  return (
    <section className="solfin-login cunsumer-top-space header-space pt-2 pb-2">
      <div className="container-md">
        <div className="row">
          <div className="col-lg-6">
            <div className="right-side-news rounded-4 position-relative overflow-hidden border-0">
              <div className="text-ad-news">
                <h5 className="fw-bold mb-2">Go solar with Solfin</h5>
                <p>
                  A transition to clean energy is about making an investment in
                  our future
                </p>
              </div>
              <div className="bottom-image text-end ps-4">
                <img
                  className="w-100"
                  src="/images/rooftop-area.png"
                  alt="Rooftop Area"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form onSubmit={formik.handleSubmit} method="post" id="l-form">
              <div className="login-form ms-auto">
                <h5 className="fw-bold">Log in</h5>
                <p>Please enter your details to sign in.</p>
                <div className="form-fields">
                  <div className="mb-4">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      {...formik.getFieldProps('email')}
                      className="form-control"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger">{formik.errors.email}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="password">Password</label>
                    <div className="password-eye">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        {...formik.getFieldProps('password')}
                        className="form-control"
                      />
                      <span className="btn" onClick={toggleShowPassword}>
                        <i
                          className={`fa fa-eye${showPassword ? '' : '-slash'}`}
                          id="togglePassword"
                        ></i>
                      </span>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                  <div className="mb-4 d-flex align-items-center justify-content-between">
                    <label
                      className="solfin-checkbox mb-0 position-relative d-flex align-items-center"
                      htmlFor="RememberMe"
                    >
                      <input
                        className="position-absolute"
                        type="checkbox"
                        id="RememberMe"
                      />
                      <div className="custom-check rounded-1 me-2"></div>
                      <span>Remember me</span>
                    </label>
                    <div className="forgot-password">
                      <Link
                        to="/forgot-password"
                        className="fw-medium text-decoration-none"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="mb-4 pt-3">
                    <button
                      id="login-btn"
                      type="submit"
                      className="btn btn-header rounded-3 w-100 text-nowrap text-uppercase green-btn calculate-btn"
                      disabled={loginMutation.isLoading}
                    >
                      {loginMutation.isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        'Login'
                      )}
                    </button>
                  </div>
                  <div className="user-log-option text-center pt-2">
                    Don`&apos;`t have an account?{' '}
                    <Link className="text-dark fw-semibold" to="/register">
                      Register with us
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        multipleUserData={multipleUserData}
        handleMultipleUserSubmit={handleMultipleUserSubmit}
      />
    </section>
  );
};

export default Login;
