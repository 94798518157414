import React from 'react';

const CustomerHeader = () => {
  return (
    <>
      <header className="inner-header smooth position-absolute start-0 top-0 w-100 pt-md-3 pt-2 mt-1">
        <div className="container custom-container">
          <div className="header-wrap bg-white rounded-20 bg-transparent">
            <div className="mein-header bg-transparent">
              <div className="row align-items-lg-center justify-content-between">
                <div className="col navigation">
                  <div className="solfin-logo">
                    <a href="">
                      <img
                        src="/images/solfin-logo.svg"
                        alt="Solfin"
                        title="Solfin"
                      />
                    </a>
                  </div>
                  {/* <!-- /solfin-logo --> */}
                  <div className="touch-in-solfin d-xl-none d-flex align-items-center">
                    <a
                      href="../login.html"
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                    >
                      Login
                    </a>
                  </div>
                  <button
                    className="navbar-toggler d-xl-none d-xl-block"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars" aria-hidden="true"></span>
                  </button>
                </div>
                {/* <!-- /col --> */}
                <div className="col">
                  <div className="solfin-heading">
                    <h2 className="fw-bold heading-color d-flex mb-0 pt-3 pb-3">
                      Customer
                      <div className="text-theme-green">&nbsp;Registration</div>
                    </h2>
                  </div>
                </div>
                {/* <!-- /col --> */}
                <div className="col d-none d-xl-block right-col"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default CustomerHeader;
