import React, {useState} from 'react';
import {useQuery} from 'react-query';
import * as querydata from '../../dataService/DataService';
import {toast} from 'react-toastify';
import Pagination from '../../components/Pagination';

const EpcPartnerList = () => {
  const [searchQry, setSearchQry] = useState('');
  const [page, setPage] = useState(1);

  const epcPublishingDetails = useQuery(
    'getEpcPublishingDetails',
    querydata.getEpcPublishingDetails,
    {
      onError: error => {
        toast.error(error);
      },
    }
  );

  if (!epcPublishingDetails.data) {
    return <div>Loading... </div>;
  }

  const filterData = epcPublishingDetails?.data?.data.filter(
    asd =>
      asd.company_name.toLowerCase().includes(searchQry) ||
      asd.short_description.includes(searchQry) ||
      asd.service_area.toLowerCase().includes(searchQry)
  );

  return (
    <>
      <div
        className="tab-pane fade"
        id="PartnersList-tab-pane"
        role="tabpanel"
        aria-labelledby="PartnersList-tab"
        tabIndex="0"
      >
        <div className="solfin-search d-flex justify-content-end">
          <form action="#" method="get">
            <div className="position-relative">
              <div className="form-group mb-2">
                <input
                  id="Address"
                  className="form-control bg-transparent pt-2 pb-2 px-3"
                  placeholder="Search here..."
                  name="address"
                  onChange={e => setSearchQry(e.target.value.toLowerCase())}
                />
                <span className="fa fa-search position-absolute end-0 top-0 h-100 d-flex align-items-center px-3 opacity-50"></span>
              </div>
            </div>
          </form>
        </div>
        {epcPublishingDetails &&
        epcPublishingDetails.data &&
        epcPublishingDetails.data.data.length > 0 ? (
          <div className="list-of-partners">
            <div className="row">
              {filterData
                .slice(page * 9 - 9, page * 9)
                .slice(page * 9 - 9, page * 9)
                .map((epc, index) => (
                  <div className="col-xl-4 col-md-6" key={index}>
                    <div className="partners-view border rounded-4">
                      <div className="contact-wrap pb-4">
                        <div className="partner-name d-flex align-items-center justify-content-between">
                          <h6 className="fw-semibold">{epc.company_name}</h6>
                          <div className="parnter-logo pp-logo">
                            <img
                              src={epc.logo}
                              alt="Holisol Logistics"
                              title="Holisol Logistics"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="engineer-partner-img">
                        <img
                          src={epc.image}
                          alt="Holisol Logistics"
                          title="Holisol Logistics"
                        />
                      </div>

                      <div className="contact-wrap">
                        {epc.short_description.length > 160 ? (
                          <p>
                            {epc.short_description.substring(0, 160)}{' '}
                            <a
                              href="#"
                              className="morebtn text-decoration-underline fw-semibold"
                              data-bs-toggle="modal"
                              data-bs-target={`#short-description${epc.epc_id}`}
                            >
                              View More
                            </a>
                          </p>
                        ) : (
                          <p>{epc.short_description}</p>
                        )}
                        <div className="served-areas mt-3 mb-4">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                              <strong>Area Serves</strong>
                            </div>
                            {epc.service_area.split(',').length > 2 && (
                              <a
                                href="#"
                                className="morebtn text-decoration-underline fw-semibold"
                                data-bs-toggle="modal"
                                data-bs-target={`#modal-number${epc.epc_id}`}
                              >
                                View More
                                <ServiceAreaModal data={epc} />
                              </a>
                            )}
                          </div>
                          <div className="serves-area mt-2">
                            {epc.service_area
                              .split(',')
                              .slice(0, 2)
                              .map((area, areaIndex) => (
                                <a
                                  key={areaIndex}
                                  className="tag text-decoration-none fw-light border rounded-5"
                                  href="#"
                                >
                                  {area.trim()}
                                </a>
                              ))}
                          </div>
                        </div>
                        {/* <div className="served-areas">
                                          <div className="d-flex justify-content-between">
                                            <strong>Deals in</strong>
                                            {epc.sectors.split(",").length >
                                              2 && (
                                              <a
                                                href="#"
                                                className="morebtn text-decoration-underline fw-semibold"
                                                data-bs-toggle="modal"
                                                data-bs-target="#DealsInModal"
                                              >
                                                View More
                                              </a>
                                            )}
                                          </div>
                                          <div className="serves-area mt-2">
                                            {epc.sectors
                                              .split(",")
                                              .slice(0, 2)
                                              .map((sector, dealIndex) => (
                                                <a
                                                  className="tag text-decoration-none fw-light border rounded-5 pt-2 pb-2 pe-3 ps-3"
                                                  href="#"
                                                >
                                                  {sector.trim()}
                                                </a>
                                              ))}
                                          </div>
                                        </div> */}
                      </div>
                    </div>
                    <ShortDesciptionModal data={epc} />
                  </div>
                ))}
            </div>
            <Pagination page={page} setPage={setPage} filterData={filterData} />
          </div>
        ) : (
          <div className="fw-bold heading-color blank-partners-list">
            Our partners are taking a little time to get dressed up for the big
            reveal... <span className="text-theme-green"></span>
          </div>
        )}
      </div>
    </>
  );
};

export default EpcPartnerList;

const ServiceAreaModal = ({data}) => {
  return (
    <div
      className="modal fade"
      id={`modal-number${data.epc_id}`}
      tabIndex="-1"
      aria-labelledby="MoreTagsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="MoreTagsModalLabel">
              Area Serves
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <a
              className="tag text-decoration-none fw-light border rounded-5 pt-2 pb-2 pe-3 ps-3"
              href="#"
            >
              {data.service_area}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceAreaModal1 = ({data}) => {
  return (
    <div
      className="modal fade"
      id={`shortdescription${data.epc_id}`}
      tabIndex="-1"
      aria-labelledby="MoreTagsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="MoreTagsModalLabel">
              Area Serves
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <a
              className="tag text-decoration-none fw-light border rounded-5 pt-2 pb-2 pe-3 ps-3"
              href="#"
            >
              {data.epc_id}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShortDesciptionModal = ({data}) => {
  return (
    <div
      className="modal fade"
      id={`short-description${data.epc_id}`}
      tabIndex="-1"
      aria-labelledby="MoreTextModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="MoreTextModalLabel">
              {data.company_name}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>{data.short_description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
