import React from 'react';
import {Link} from 'react-router-dom';
const FairPracticeCode = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    <span className="text-theme-green">
                      Fair Practice Code of{' '}
                    </span>{' '}
                    <span className="text-theme-green">
                      Dhanvikas Fiscal Services Private Limited
                    </span>
                  </h2>
                </div>
                <div className="mt-xl-4 mt-3">
                  <div className="solfin-heading">
                    <h5 className="fw-bold heading-color">Introduction</h5>
                  </div>
                  <p>
                    Dhanvikas Fiscal Services Private Limited (“Solfin” or “the
                    Company”) is a NBFC registered with the Reserve Bank of
                    India (“RBI”) is presently engaged in the business of
                    financing sustainable, renewable and climate friendly
                    projects throughout India. In accordance with the applicable
                    laws (hereinafter defined), the Company has framed and
                    adopted this Fair Practices Code (“FPC” or “this Code”)
                    which sets the fair practice standards while dealing with
                    its customers.
                  </p>
                  <p>
                    As per Chapter VII, of the RBI Master Direction – Reserve
                    Bank of India (Non-Banking Financial Company – Scale Based
                    Regulation) Directions, 2023, RBI/ DoR/ 2023-24/ 105n DoR.
                    FIN. REC. No.45 /03. 10. 119/ 2023- 24 dated October 19,
                    2023, the Non-Banking Financial Company’s (“NBFC”) shall
                    adopt the guidelines on Fair Practices Code.
                  </p>
                  <p>
                    The Company has adopted this Fair Practices Code for its
                    lending operations which intends to provide assurance to all
                    the borrowers of the Company’s commitment to fair dealing,
                    and transparency in its business transactions.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Applicability</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      The FPC shall apply to all the products and services
                      offered by the Company.
                    </span>
                  </li>
                  <li>
                    <span>
                      The Code shall apply to all the employees, its agents,
                      representatives, third party vendors, service providers,
                      Directors of the Company and other persons authorised to
                      represent the Company in its normal course of business.
                    </span>
                  </li>
                  <li>
                    <span>
                      The Company’s Fair practices code shall apply across all
                      aspects of its operations including marketing, loan
                      origination, processing, servicing and collection
                      activities.
                    </span>
                  </li>
                </ul>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">Regulatory Context</h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    This Code takes into account regulatory documents published
                    by regulatory bodies (referred as ‘Applicable Laws’), in
                    particular: -
                  </p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        ‘Fair Practice Code (Chapter VII)’ under the Master
                        Direction issued by Reserve Bank of India (Non- Banking
                        Financial Company – Scale Based Regulation) Directions,
                        2023, as amended time to time and applicable to
                        NBFC-ICC;
                      </span>
                    </li>
                    <li>
                      <span>
                        Guidelines on Digital Lending issued by Reserve Bank of
                        India vide notification RBI/2022-23/111
                        DOR.CRE.REC.66/21.07.001/2022-23 dated September 02,
                        2022, as amended time to time; and
                      </span>
                    </li>
                    <li>
                      <span>
                        Guidelines on ‘Fair Lending Practice - Penal Charges in
                        Loan Accounts’ issued by Reserve Bank of India vide
                        notification no. RBI/2023-24/53
                        DoR.MCS.REC.28/01.01.001/2023-24 dated August 18, 2023
                        along with Frequently Asked Questions thereon, as
                        amended time to time.
                      </span>
                    </li>
                    <li>
                      <span>
                        Applicable circulars, guidelines and clarifications as
                        may be issued from time to time by RBI.
                      </span>
                    </li>
                    <li>
                      <span>
                        Pursuant to any subsequent amendments in the above
                        stated guidelines or other applicable acts/ regulations,
                        if there is any change in any of the parameters framed
                        by the Board then the Act/ Regulation will have
                        overriding effect on the parameters.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">Relevant Documents</h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    This Code shall be read along with the other policies of the
                    Company as applicable and amended from time to time.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">Objectives</h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    The Company has put in place the FPC with an endeavour to
                    achieve synchronization of best practices when the Company
                    is dealing with its stakeholders. The primary objectives for
                    adopting the FPC is as mentioned below:
                  </p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        To promote good and fair practices by setting minimum
                        standards in dealing with customers;
                      </span>
                    </li>
                    <li>
                      <span>
                        To increase transparency so that the customer can have a
                        better understanding of what they can reasonably expect
                        of the services;
                      </span>{' '}
                    </li>
                    <li>
                      <span>
                        To promote a fair and cordial relationship between
                        customer and the Company:
                      </span>
                    </li>
                    <li>
                      <span>
                        To ensure that the products and services meet relevant
                        laws and regulations in force in India;
                      </span>
                    </li>
                  </ul>
                  <p>
                    The Company’s Board of Directors and the management are
                    responsible for establishing practices designed to ensure
                    that its operations reflect a strong commitment to fair
                    lending and that all employees are aware of that commitment.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Non-Discrimination Policy
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    The Company will not discriminate between its customers on
                    the basis of gender, physical ability, race or religion. The
                    Company will also not discriminate visually impaired or
                    physically challenged applicants on the ground of disability
                    in extending products, services, facilities, etc. However,
                    this does not preclude the Company from participating in
                    credit-linked schemes framed for weaker sections of the
                    society.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Loan Application & Processing
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        The Company shall ensure that all communications to the
                        borrower shall be in the English language understood by
                        the borrower. For this purpose, the Company shall obtain
                        relevant declaration from the customer towards the
                        language understood by the customer.
                      </span>
                    </li>
                    <li>
                      <span>
                        The loan application form of the Company shall cover
                        loan requirements, kyc, declarations and all such other
                        information and documents which will enable the Company
                        to make an informed judgement on the loan approval and
                        at the same time inform the borrower on his legal and
                        commercial responsibilities.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Company shall indicate on its website, or on loan
                        documents, as the case may be, complete list of
                        documents that are required to be submitted by the
                        borrower along with the loan application.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Company shall provide an acknowledgement for receipt
                        of all loan applications through appropriate mode
                        indicating the time frame within which the loan
                        application will be disposed of.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Company shall verify the loan applications within a
                        reasonable period of time and if additional details /
                        documents are required, it would intimate the borrowers
                        immediately. In case the loan application is not
                        approved by the Company, the borrower would be intimated
                        about such rejection.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Loan Appraisal and Terms & Conditions
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        All loan applications shall be evaluated by the Company
                        on the predefined eligibility criteria for the financial
                        products on the basis of the information and documents
                        provided by the applicant. If required, the Company may
                        verify all or any information/ document in a manner
                        permissible by law.
                      </span>
                    </li>
                    <li>
                      <span>
                        Upon approval of loan application, the Company shall
                        convey in writing to the borrower the amount of loan
                        being sanctioned by means of sanction letter, Key Fact
                        Statement (“KFS”) and Loan Agreement.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Company shall mention the penal charges in bold in
                        the loan documents.
                      </span>
                    </li>
                    <li>
                      <span>
                        Acceptance of Loan sanction letter to be kept on record.
                      </span>
                    </li>
                    <li>
                      <span>
                        Copy of Executed Loan agreement to be sent to Borrower
                        along with all documents/annexures at the time of
                        disbursement.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">Recovery Mechanism</h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        The staff of the Company as well as the representatives
                        of external agencies engaged by the Company for recovery
                        of loans shall be properly trained to communicate
                        appropriately with the customers with due care and
                        sensitivity, considering aspects such as soliciting
                        customers, hours of calling, privacy of customer
                        information and conveying the correct terms and
                        conditions of the products etc.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Company’s collection policy/ process shall be built
                        on courtesy, fair treatment and persuasion. The Company
                        believes in fostering customer confidence and long-term
                        relationship.
                      </span>
                    </li>
                    <li>
                      <span>
                        All the staff members of the Company or third-party
                        recovery agents shall follow the guidelines set out
                        below in collection of dues:
                      </span>
                      <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                        <li>
                          <span>
                            Customer would be contacted ordinarily on his
                            registered mobile number; or at the place of his/
                            her choice and in the absence of any specified
                            place, at the place of his/ her residence.
                          </span>
                        </li>
                        <li>
                          <span>
                            Identity and authority to represent the Company
                            shall be made known to the customer at the first
                            instance.
                          </span>
                        </li>
                        <li>
                          <span>
                            Customer&#39;s privacy shall be respected.
                          </span>
                        </li>
                        <li>
                          <span>
                            Interaction with the customer shall be in a civil
                            manner and the customer should not be called at odd
                            hours i.e. before 8:00 a.m. and after 7:00 p.m.
                          </span>
                        </li>
                        <li>
                          <span>
                            Customer&#39;s request to avoid calls at a
                            particular time or at a particular place shall be
                            honoured, as far as possible.
                          </span>
                        </li>
                        <li>
                          <span>
                            All assistance should be given to resolve disputes
                            or differences regarding dues in a mutually
                            acceptable and in an orderly manner.
                          </span>
                        </li>
                        <li>
                          <span>
                            During visits to customer&#39;s place for dues
                            collection, decency and decorum shall be maintained.
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>
                        The Company and their agents shall not resort to
                        intimidation or harassment of any kind, either verbal or
                        physical, against any person in their debt collection
                        efforts, including acts intended to humiliate publicly
                        or intrude the privacy of the customers’ family members,
                        referees and friends, making threatening and anonymous
                        calls or making false and misleading representations.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Company shall ensure that prior intimation of the
                        details of the recovery agent authorised to approach the
                        borrower for recovery and any changes thereon is given
                        to the borrower.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Responsibility of board of Directors
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    The Board of Directors of the Company has laid down the
                    appropriate grievance redressal mechanism within the
                    organization. The mechanism ensures that all disputes
                    arising out of the decisions of lending institutions'
                    functionaries are heard and disposed of at least at the next
                    higher level.
                  </p>
                  <p>
                    The Board of Directors periodically reviews the compliance
                    of the Fair Practices Code and the functioning of the
                    grievance’s redressal mechanism at various levels of
                    management.
                  </p>
                </div>
              </div>
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Integrated Ombudsman Scheme for Non-Banking Financial
                    Companies
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        The Company shall adhere to Reserve Bank – Integrated
                        Ombudsman Scheme, 2021 (RBI – IOS), as amended time to
                        time.
                      </span>
                    </li>
                    <li>
                      <span>
                        Under the RBI – IOS, the Company has appointed a
                        Principal Nodal Officer (PNO) and/ or Nodal Officer (NO)
                        who shall be responsible for representing the Company
                        and furnishing information to the Ombudsman in respect
                        of complaints filed against the Company.
                      </span>
                    </li>
                    <li>
                      <span>
                        For the benefit of the customers, at the places where
                        business is transacted, the name and contact details
                        (Telephone/Mobile number and email) of the PNO and NO
                        along with the details of the complaint lodging portal
                        of the Ombudsman (https://cms.rbi.org.in) shall be
                        displayed.
                      </span>
                    </li>
                    <li>
                      <span>
                        A complaint can be filed through any of the following
                        methods:
                      </span>
                      <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                        <li>
                          <span>
                            Online - on CMS portal of RBI at
                            https://cms.rbi.org.in.
                          </span>
                        </li>
                        <li>
                          <span>
                            Physical complaint (letter/post) in the form as
                            specified to “Centralised Receipt and Processing
                            Centre, 4th Floor, Reserve Bank of India, Sector
                            -17, Central Vista, Chandigarh - 160017”.
                          </span>
                        </li>
                        <li>
                          <span>
                            Complaints with full details can be sent by email
                            (crpc@rbi.org.in)
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>
                        The salient features of the Scheme are displayed
                        prominently at the offices and website of the Company in
                        such a manner that a person visiting the office has
                        adequate information on the Scheme.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Grievance Redressal Mechanism
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    The Company shall set up a Grievance Redressal Policy. The
                    same shall be displayed on the website of the Company.
                  </p>
                </div>
              </div>
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Language and Mode of communicating Fair Practice Code
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    This code as established by the Company has been documented
                    in English language as understood by its customers and is
                    based on the guidelines as outlined by the RBI, the same is
                    approved by its Board of Directors.
                  </p>
                  <p>
                    For the information of various stakeholders, the Company
                    shall place this Code on Company’s website in language
                    understood by its customers.
                  </p>
                </div>
              </div>
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">Review</h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    This Code shall be reviewed annually by the Board of
                    Directors of the Company. Any change/amendment in applicable
                    laws with regard to maintenance of an appropriate Fair
                    Practices Code shall be deemed to be incorporated in this
                    Policy by amendment and this Code shall be accordingly
                    amended as and when required.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default FairPracticeCode;
