import React from 'react';

const RefundPolicy = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    Cancellation &amp; Refund{' '}
                    <span className="text-theme-green">Policy</span>
                  </h2>
                </div>
                <div className="mt-xl-4 mt-3">
                  <p>
                    All rights reserved. This document constitutes Property of
                    XSFinance and Investment Pvt. Ltd, and no part of this
                    document may be reproduced or transmitted in any form or by
                    any means, electronic or mechanical, including photocopying
                    or recording or in any manner whatsoever without explicit
                    consent of XSFinance and Investment Pvt. Ltd. Any violation
                    shall be treated as violation of terms of employment and
                    appropriate action shall `be taken accordingly.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
            {/* <!-- /row --> */}
            <div className="row ">
              <div className="para-text mt-xl-4">
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <div className="para-text mt-xl-4 mt-3">
                      <p>
                        Amount once paid through the payment gateway shall not
                        be refunded other than in the following circumstances:
                      </p>
                      <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                        <li>
                          <span>
                            Multiple times debiting of Customer’s Card/Bank
                            Account due to technical error.
                          </span>
                        </li>
                        <li>
                          <span>
                            Customer's account being debited with excess amount
                            in a single transaction due to technical error.
                          </span>
                        </li>
                        <li>
                          <span>
                            In such cases, excess amount excluding Payment
                            Gateway charges would be refunded to the Customer.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span>
                      The Customer will have to make an application for refund
                      along with the transaction number and original payment
                      receipt if any generated at the time of making payments.
                    </span>
                  </li>
                  <li>
                    <span>
                      The application in the prescribed format should be sent to
                      support@solfin.co.in
                    </span>
                  </li>
                  <li>
                    <span>
                      The application will be processed manually and after
                      verification, if the claim is found valid, the amount
                      received in excess will be refunded through electronic
                      mode in favour of the applicant and confirmation sent to
                      the mailing address given in the online registration form,
                      within a period of 21 calendar days on receipt of such
                      claim. It will take up to 21 days for the money to show in
                      your bank account depending on your bank’s policy
                    </span>
                  </li>
                  <li>
                    <div className="para-text mt-xl-4 mt-3">
                      <p>
                        Company assumes no responsibility and shall incur no
                        liability if it is unable to affect any Payment
                        Instruction(s) on the Payment Date owing to any one or
                        more of the following circumstances:
                      </p>
                      <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                        <li>
                          <span>
                            If the Payment Instruction(s) issued by you is/are
                            incomplete, inaccurate, and invalid and delayed.
                          </span>
                        </li>
                        <li>
                          <span>
                            If the Payment Account has insufficient funds/limits
                            to cover for the amount as mentioned in the Payment
                            Instruction(s)
                          </span>
                        </li>
                        <li>
                          <span>
                            If the funds available in the Payment Account are
                            under any encumbrance or charge.
                          </span>
                        </li>
                        <li>
                          <span>
                            If your Bank refuses or delays honouring the Payment
                            Instruction(s)
                          </span>
                        </li>
                        <li>
                          <span>
                            Circumstances beyond the control of Company
                            (including, but not limited to, fire, flood, natural
                            disasters, bank strikes, power failure, systems
                            failure like computer or telephone lines breakdown
                            due to an unforeseeable cause or interference from
                            an outside force)
                          </span>
                        </li>
                        <li>
                          <span>
                            In case the payment is not affected for any reason,
                            you will be intimated about the failed payment by an
                            e-mail
                          </span>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <span>
                      User agrees that Company, in its sole discretion, for any
                      or no reason, and without penalty, may suspend or
                      terminate his/her account (or any part thereof) or use of
                      the Services and remove and discard all or any part of
                      his/her account, user profile, or his/her recipient
                      profile, at any time. Company may also in its sole
                      discretion and at any time discontinue providing access to
                      the Services, or any part thereof, with or without notice.
                      User agrees that any termination of his /her access to the
                      Services or any account he/she may have, or portion
                      thereof may be affected without prior notice, and also
                      agrees that Company will not be liable to user or any
                      third party for any such termination. Any suspected,
                      fraudulent, abusive or illegal activity may be referred to
                      appropriate law enforcement authorities. These remedies
                      are in addition to any other remedies Company may have at
                      law or in equity. Upon termination for any reason, user
                      agrees to immediately stop using the Services.
                    </span>
                  </li>
                  <li>
                    <span>
                      Company may elect to resolve any dispute, controversy or
                      claim arising out of or relating to this Agreement or
                      Service provided in connection with this Agreement as per
                      the remedies applicable as per law.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}

            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default RefundPolicy;
