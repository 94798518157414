const GSTDetails = ({
  values,
  errors,
  handleChange,
  setIsGSTConsentChecked,
  isGSTConsentChecked,
}) => {
  const handleCheckboxChange = e => {
    setIsGSTConsentChecked(e.target.checked);
  };
  return (
    <>
      <div className="heading mb-xl-4 mb-3">
        <span className="fw-semibold saving-title">Company GST Details</span>
      </div>
      <div className="row">
        <div className="heading mb-xl-4 mb-3">
          <span className="fw-semibold saving-title">
            <b>Note:</b> Solfin, its NBFC partner- Dhanvikas Fiscal Services
            Private Limited and its third party service providers, do not store
            GST user name and password.
          </span>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-4">
            <label
              htmlFor="gstin"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold">GST Number</span>
              <input
                type="text"
                id="gstin"
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                placeholder="GST Number"
                name="gstIn"
                value={values.gstIn}
                onChange={handleChange}
              />
            </label>
          </div>
          {/* <!-- /form-group --> */}

          <div className="">
            <div className="form-group mb-4">
              <label
                htmlFor="gstusername"
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">GST Portal User Name</span>
                <input
                  type="text"
                  id="gstusername"
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                  placeholder="GST user name"
                  name="gstUserName"
                  value={values.gstUserName}
                  onChange={handleChange}
                />
              </label>
            </div>
            {/* <!-- /form-group --> */}
          </div>
          <div className="">
            <div className="form-group mb-4">
              <label
                htmlFor="gstPassword"
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">GST Portal Password</span>
                <input
                  type="password"
                  id="gstPassword"
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                  placeholder="Password"
                  name="gstPassword"
                  value={values.gstPassword}
                  onChange={handleChange}
                />
              </label>
            </div>
            {/* <!-- /form-group --> */}
          </div>
        </div>
        <div
          id="hideformcheckbox"
          className="check-conditions d-flex justify-content-between align-items-center hideform"
        >
          <label
            htmlFor="AllowGSTTerms"
            className="checkboxes-view p-0 position-relative d-flex"
          >
            <input
              type="checkbox"
              checked={isGSTConsentChecked}
              onChange={handleCheckboxChange}
              name=""
              className="position-absolute top-0"
              id="AllowGSTTerms"
            />
            <div className="now-check position-relative mt-0"></div>
            <p className="mb-0" style={{lineHeight: '25px'}}>
              I hereby give my consent to Solfin, its NBFC partner- Dhanvikas
              Fiscal Services Private Limited and its third party service
              providers, to fetch my firm's GST Certificates and GST Returns
              from GST Portal for the purpose of credit underwriting for
              availing credit limit.
            </p>
          </label>
        </div>
      </div>
    </>
  );
};

export default GSTDetails;
