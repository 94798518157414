import React from 'react';
import {Link} from 'react-router-dom';

const CookiePolicy = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    Cookie <span className="text-theme-green">Policy</span>
                  </h2>
                </div>
                <div className="mt-xl-4 mt-3">
                  <p>
                    Solfin is the brand name owned by XSFinance and Investment
                    Private Limited and is also licensed to Dhanvikas Fiscal
                    Services Private Limited in which it owns a stake.
                  </p>
                  <p>
                    This Cookie Policy describes how Solfin, a brand owned by XS
                    Finance and Investment Private Limited, stores, collects,
                    uses data in user's browser cookies and similar tracking
                    technologies to enhance your browsing experience and collect
                    information about how you use our site.We may store and/or
                    access information on a device and process personal data,
                    such as your IP address and browsing data, for personalised
                    advertising and content, advertising and content
                    measurement, audience research and services development.
                    Additionally, we may utilize precise geolocation data and
                    identification through device scanning. Please note that
                    your consent will be valid across all our subdomains. You
                    can manage your cookie preferences through your browser
                    settings.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">What is Cookie?</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  Cookies are small text files stored on your device (computer,
                  tablet, smartphone, or another device) to enhance your
                  experience on the website.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  Websites use cookies for many different purposes, including
                  but not limited to:
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      Get information about the traffic on our website.
                    </span>
                  </li>
                  <li>
                    <span>
                      Provide you with essential functionalities of our website
                    </span>
                  </li>
                  <li>
                    <span>Track your browsing behavior</span>
                  </li>
                  <li>
                    <span>
                      Collect information about how users use our website
                    </span>
                  </li>
                  <li>
                    <span>Track your engagement with social services</span>
                  </li>
                  <li>
                    <span>Remember your preferences for our website</span>
                  </li>
                  <li>
                    <span>Personalize content and advertisements.</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Key concepts</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    First and third-party cookies:
                  </h5>
                </div>

                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      Whether a cookie is ‘first’ or ‘third’ party refers to the
                      domain placing the cookie. First- party cookies are those
                      set by a website that is being visited by the user at the
                      time.Third-party cookies are cookies that are set by a
                      ̦domain other than that of the website being visited by the
                      user. If a user visits a website and another entity sets a
                      cookie through that website, this would be a third-party
                      cookie.
                    </span>
                  </li>
                  ̦
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Persistent cookies</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  These cookies remain on a user’s device for the period of time
                  specified in the cookie. They are activated each time that the
                  user visits the website that created that particular cookie.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Session cookies</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  These cookies allow website operators to link the actions of a
                  user during a browser session. A browser session starts when a
                  user opens the browser window and finishes when they close the
                  browser window. Session cookies are created temporarily. Once
                  you close the browser, all session cookies are deleted.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  Changing your cookie settings
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  You can change your cookie settings by clicking on the Cookie
                  button at the bottom of this policy. You can also block
                  cookies by activating the setting on your browser that allows
                  you to refuse the setting of all or some cookies. However, if
                  you use your browser settings to block all cookies (including
                  strictly necessary cookies), you may not be able to access all
                  or parts of our site. Please note that internet browsers allow
                  you to change your cookie settings. These settings are usually
                  found in the ‘options’ or ‘preferences’ menu of your internet
                  browser. In order to understand these settings, the following
                  links may be helpful. Otherwise you should use the ‘Help’
                  option in your internet browser for more details.
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <Link to="https://support.microsoft.com/search/results?query=cookies+in+microsoft+edge&amp;isEnrichedQuery=false">
                      Cookie settings in Microsoft Edge
                    </Link>
                  </li>
                  <li>
                    <Link to="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">
                      Cookie settings in Firefox
                    </Link>
                  </li>
                  <li>
                    <Link to="https://support.google.com/chrome/?hl=en&amp;answer=95647#topic=7439538">
                      Cookie settings in Chrome
                    </Link>
                  </li>
                  <li>
                    <Link to="https://support.apple.com/en-us/105082">
                      Cookie settings in Safari
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Your consent</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  Strictly necessary cookies do not require your consent. For
                  performance, functionality, targeting and social media
                  cookies, we request your consent before placing them on your
                  device.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  Can you withdraw your consent?
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  If you wish to withdraw your consent at any time, you will
                  need to delete your cookies using your internet browser
                  settings.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Contact Us</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  If you have any questions, concerns, or requests regarding
                  this Cookie Policy or the processing of your personal
                  information,
                  <br />
                  please contact us at: <strong>Phone:</strong>{' '}
                  <a href="tel:+919599446877">+91-9599446877</a>{' '}
                  <strong>Email:</strong>{' '}
                  <a href="mailto:info@solfin.co.in">info@solfin.co.in</a>
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}

            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default CookiePolicy;
