import React from 'react';
import {NavLink} from 'react-router-dom';

const HomePageAdditions = () => {
  return (
    <>
      <div className="save-your-energy">
        <div className="container custom-container">
          <div className="row align-items-center justify-content-between flex-md-nowrap flex-wrap">
            <div className="col">
              <div className="save-energy-headline ps-xl-5 ps-4">
                <h3 className="fw-bold m-0 text-nowrap">
                  Want a <span className="text-theme-green">Cleaner</span> &{' '}
                  <span className="text-theme-green">Greener</span> tomorrow?
                </h3>
              </div>
            </div>
            {/* <!-- /col-md-7 --> */}
            <div className="col">
              <div className="right-energy-action">
                <NavLink to="/Estimator" className="text-decoration-none">
                  <span className="btn btn-primary calculate-btn fw-bold rounded-pill">
                    Let’s talk
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
    </>
  );
};

export default HomePageAdditions;
