const TopBar = ({
  email = 'support@solfin.co.in',
  phone = '+919599446877'
}) => (
  <div className="top-header text-white bg-black pt-1 pb-1">
    <div className="row mt-2 mb-1">
      <div className="col-md-12 text-end">
        <a
          className="text-decoration-none text-theme-green fw-bold"
          href={`mailto:${email}`}
        >
          {email}
        </a>
        <span className="devider ms-5 me-5">|</span>
        <a
          className="text-decoration-none text-theme-green fw-bold"
          href={`tel:${phone}`}
        >
          {phone}
        </a>
      </div>
    </div>
  </div>
);

export default TopBar;
