import Select from '../../../components/Select';
const RegistrationType = ({
  values,
  requiredCompanyDocuments,
  getEpcRegistrationType,
  handleChange,
  errors,
}) => {
  return (
    <>
      <div className="row">
        <div className="heading mb-xl-4 mb-3">
          <span className="fw-semibold saving-title">
            Please select your company type:
          </span>
        </div>
        <div className="col-md-6">
          <div className="form-group pb-2 mb-4">
            <Select
              values={values}
              dropdownidText="registrationTypeId"
              dropdownName="registrationTypeId"
              dropdownname="customerType"
              dropdowndata={getEpcRegistrationType}
              dropdownLabelName="Registration Type"
              handleChange={handleChange}
              defaultValue={values.registrationTypeId}
              errors={errors}
              disabled={false}
            />
            {errors.registrationTypeId ? (
              <p className="form-error text-danger">
                {errors.registrationTypeId}
              </p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        {/* <!-- /col-md-6 --> */}
      </div>

      {/* <!-- /row --> */}
      {/* <!-- /col-lg-4 col-md-6 --> */}
      {requiredCompanyDocuments && requiredCompanyDocuments.length > 0 ? (
        <>
          <div className="heading mb-xl-4 mb-3">
            <span className="fw-semibold saving-title">
              Following Documents and Details will be Required in Next Steps,
              Please Keep all the documents and details ready before starting:
            </span>
          </div>
          <div className="">
            {/* <!-- /col-lg-4 col-md-6 --> */}
            <div className="">
              {requiredCompanyDocuments.map((doc, index) =>
                doc.userInputRequired == false ? (
                  ''
                ) : (
                  <div
                    key={`${doc.documentName}-${index}`}
                    className="form-group mb-4"
                  >
                    <span className="fw-bold">
                      {index + 1}
                      {'. '} {doc.documentName}{' '}
                      {doc.numberOfFiles > 1
                        ? 'of last ' + doc.numberOfFiles + ' financial years'
                        : ''}
                      {!doc.mandatory ? '' : '*'}
                    </span>
                  </div>
                )
              )}
              {/* <!-- /form-group --> */}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default RegistrationType;
