import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import Header from '../components/Header';
import Footer from '../components/Footer';

const RouteWrapper = ({ children, showHeaderFooter = true }) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyOffset = 152;

  const handleScroll = useCallback(() => {
    if (window.scrollY > stickyOffset) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showHeaderFooter && <Header isSticky={isSticky} />}
      <Box>{children}</Box>
      {showHeaderFooter && <Footer />}
    </>
  );
};

export default RouteWrapper;
