import React, {useEffect, useState} from 'react';

const ResidentialLoanProducts = () => {
  const [applyloanUrl, setApplyLoanUrl] = useState('');

  useEffect(() => {
    const currentDomain = window.location.hostname;
    if (currentDomain.includes('stg') || currentDomain.includes('localhost')) {
      setApplyLoanUrl('https://stg.finance.solfin.co.in');
    } else {
      setApplyLoanUrl('https://finance.solfin.co.in');
    }
  }, []);

  return (
    <section>
      <div className="about-solfin loan-products header-space  pt-1">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="solfin-heading">
                <h2 className="fw-bold heading-color">
                  Financing <span className="text-theme-green">Options</span>
                </h2>
              </div>
            </div>
            {/* <!-- /col-12 --> */}

            <div className="loan-products">
              <ul className="loan-product-list list-unstyled d-flex justify-content-center">
                <li>
                  <div className="loan-product rounded-4 overflow-hidden">
                    <div className="product-name d-flex align-items-center justify-content-center">
                      <h6 className="fw-bold m-0">
                        6 Month{' '}
                        <span className="text-theme-green">No Cost EMI</span>
                      </h6>
                    </div>

                    <div className="uses">
                      <strong>Maximum Amount</strong>
                      <span>6 Lakhs</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Rate of Interest</strong>
                      <span>upto 10% p.a</span>
                    </div>
                    <div className="uses">
                      <strong>Processing Fees</strong>
                      <span>3% of Loan Sanctioned</span>
                    </div>
                    <div className="uses">
                      <strong>Discount</strong>
                      <span>
                        Equivalent to total interest has to be paid during six
                        months
                      </span>
                    </div>
                    <div className="loan-action text-center">
                      <a
                        href={applyloanUrl + '/onboard/#/login'}
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn "
                      >
                        Apply
                      </a>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="loan-product rounded-4 overflow-hidden">
                    <div className="product-name d-flex align-items-center justify-content-center">
                      <h6 className="fw-bold m-0">
                        12 Months{' '}
                        <span className="text-theme-green">Low cost EMI</span>
                      </h6>
                    </div>
                    <div className="uses">
                      <strong>Maximum Amount</strong>
                      <span>6 Lakhs</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Rate of Interest</strong>
                      <span>upto 10% p.a</span>
                    </div>
                    <div className="loan-action loan-action-m-t text-center">
                      <a
                        href={applyloanUrl + '/onboard/#/login'}
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn "
                      >
                        Apply
                      </a>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="loan-product rounded-4 overflow-hidden">
                    <div className="product-name d-flex align-items-center justify-content-center">
                      <h6 className="fw-bold m-0">
                        24 Month{' '}
                        <span className="text-theme-green">Low Cost EMI</span>
                      </h6>
                    </div>
                    <div className="uses">
                      <strong>Maximum Amount</strong>
                      <span>6 Lakhs</span>
                    </div>
                    <div className="uses rate-interst">
                      <strong>Rate of Interest</strong>
                      <span>upto 10% p.a</span>
                    </div>
                    {/* <div className="uses">
                                    <strong>Processing Fees</strong>
                                    <span>No Processing Fee</span>
                                </div> */}

                    <div className="loan-action loan-action-m-t text-center">
                      <a
                        href={applyloanUrl + '/onboard/#/login'}
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn "
                      >
                        Apply
                      </a>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="loan-product rounded-4 overflow-hidden">
                    <div className="product-name d-flex align-items-center justify-content-center">
                      <h6 className="fw-bold m-0">
                        36 Month{' '}
                        <span className="text-theme-green">Low Cost EMI</span>
                      </h6>
                    </div>

                    <div className="uses">
                      <strong>Maximum Amount</strong>
                      <span>6 Lakhs</span>
                    </div>

                    <div className="uses rate-interst">
                      <strong>Rate of Interest</strong>
                      <span>upto 10% p.a</span>
                    </div>
                    <div className="loan-action loan-action-m-t text-center">
                      <a
                        href={applyloanUrl + '/onboard/#/login'}
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn "
                      >
                        Apply
                      </a>
                    </div>
                  </div>
                </li>

                {/* <li>
                        <div className="loan-product rounded-4 overflow-hidden">
                            <div className="product-name d-flex align-items-center justify-content-center d-flex align-items-center justify-content-center">
                                <h6 className="fw-bold m-0">Pay <span className="text-theme-green">per Use</span></h6>
                            </div>
                            <div className="uses end-use">
                                <strong>End Use</strong>
                                <span>Solar Only</span>
                            </div>
                            <div className="uses">
                                <strong>Tenure</strong>
                                <span>Max 9 Years</span>
                            </div>
                            <div className="uses interest-computation">
                                <strong>Interest Computation on</strong>
                                <span>Units Generated * Fixed Rate</span>
                            </div>
                            <div className="uses">
                                <strong>Maximum Amount</strong>
                                <span>No Limit</span>
                            </div>
                            <div className="uses">
                                <strong>EMI</strong>
                                <span>Monthly</span>
                            </div>
                            <div className="uses rate-interst">
                                <strong>Rate of Interest</strong>
                                <span>NA</span>
                            </div>
                            <div className="uses">
                                <strong>Colending Finance</strong>
                                <span>No</span>
                            </div>
                            <div className="uses">
                                <strong>Processing Fees</strong>
                                <span>1% of Loan Sanctioned</span>
                            </div>

                            <div className="loan-action text-center">
                                <a href="#" className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn disabled">Coming Soon</a>
                            </div>

                        </div>
                    </li> */}
              </ul>
              <div>
                <div className="d-flex ">
                  *
                  <strong>
                    Documents Required For Loan: Aadhaar Card, PAN Card, Bank
                    Statement, Income Tax Return, Electricity Bill.
                  </strong>
                </div>
              </div>
            </div>

            {/* <!-- /row --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /saving-calculator --> */}
    </section>
  );
};

export default ResidentialLoanProducts;
