import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation, useQuery} from 'react-query';
import * as querydata from '../../dataService/DataService';
import {useCookies} from 'react-cookie';
import StateSelect from '../../components/StateSelect';
import CitySelect from '../../components/CitySelect';
import {toast} from 'react-toastify';

export const estimatorRegisterSchema = Yup.object({
  customerName: Yup.string().required('Please enter full name'),
  email: Yup.string().email().required('Please enter your email'),
  phone: Yup.string()
    .required('Mobile number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number'),
  //pinCode: Yup.string().required("Please enter your pincode"),
  pinCode: Yup.string()
    .required('PinCode is required')
    .length(6, 'Pincode must be exactly 6 digits')
    .matches(/^\d+$/, 'Pincode must only contain digits'),
  cityId: Yup.number().required('Please select city'),
  stateId: Yup.number()
    .notOneOf([-1], 'Please select state')
    .required('Please select state'),
  locationTypeId: Yup.string().required('Please select Location Type'),
  avgConsumption: Yup.string()
    .required('Please enter Average Consumption')
    .matches(/^\d+$/, 'Average Consumption must only contain digits'),
  electricityRate: Yup.string()
    .required('Please enter Electricity Rate')
    .matches(/^\d+(\.\d+)?$/, 'Electricity Rate must only contain digits'),
  areaAvailable: Yup.string()
    .required('Please enter Available Area')
    .matches(/^\d+$/, 'Available Area must only contain digits'),
  consumptionToShift: Yup.string().required(
    'Please select % consumption shift'
  ),
  connectedToGrid: Yup.string().required('Please select Grid Connection'),
});

const Estimator = () => {
  //clear all fields
  const closeerrormessage = () => window.location.reload();

  //initialize Navigate method
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState('-1');

  const [percentage, setPercentage] = useState(50);
  const [cal, setCal] = useState({});
  const [cookies, setCookie] = useCookies([]);
  const [editable, setEditable] = useState(true);
  const [showOtpDiv, setShowOtpDiv] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [smsOtp, setSmsOtp] = useState('');
  const [isSmsOtpVerified, setIsSmsOtpVerified] = useState(false);
  const [smsOtpEditable, setSmsOtpEditable] = useState(true);
  const [emailOtpEditable, setEmailOtpEditable] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);

  // Get specific query parameters
  const recalculate = queryParams.get('recalculate');
  let cachedDetails = {};
  if (recalculate == 'true') {
    cachedDetails = JSON.parse(sessionStorage.getItem('formdata'));
  }

  // values defined for formik
  const initialValues = {
    customerName: cachedDetails.customerName ? cachedDetails.customerName : '',
    email: cachedDetails.email ? cachedDetails.email : '',
    phone: cachedDetails.phone ? cachedDetails.phone : '',
    cityId: cachedDetails.cityId ? cachedDetails.cityId : '',
    pinCode: cachedDetails.pinCode ? cachedDetails.pinCode : '',
    stateId: cachedDetails.stateId ? cachedDetails.stateId : -1,
    locationTypeId: cachedDetails.locationTypeId
      ? cachedDetails.locationTypeId
      : '',
    avgConsumption: cachedDetails.avgConsumption
      ? cachedDetails.avgConsumption
      : '',
    electricityRate: cachedDetails.electricityRate
      ? cachedDetails.electricityRate
      : '',
    areaAvailable: cachedDetails.areaAvailable
      ? cachedDetails.areaAvailable
      : '',
    consumptionToShift: cachedDetails.consumptionToShift
      ? cachedDetails.consumptionToShift
      : 50,
    connectedToGrid: cachedDetails.connectedToGrid
      ? cachedDetails.connectedToGrid
      : '1',
    salutation: cachedDetails.salutation ? cachedDetails.salutation : '',
    sanctionedLoad: cachedDetails.sanctionedLoad
      ? cachedDetails.sanctionedLoad
      : '',
  };

  //build Model and fill data in formik model
  const {values, handleBlur, handleChange, handleSubmit, errors, touched} =
    useFormik({
      initialValues: initialValues,

      validationSchema: estimatorRegisterSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        formData.append(
          'customer_name',
          values.salutation + ' ' + values.customerName
        );
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('city_id', values.cityId);
        formData.append('pincode', values.pinCode);
        formData.append('location_type_id', values.locationTypeId);
        formData.append('avg_consumption', values.avgConsumption);
        formData.append('electricity_rate', values.electricityRate);
        formData.append('area_available', values.areaAvailable);
        formData.append('consumption_to_shift', percentage);
        formData.append('connect_to_grid', values.connectedToGrid);
        formData.append('sanctionedLoad', values.sanctionedLoad);
        updateStatusMutation.mutate(values);
        setCookie('formdata', values, {path: '/'});
        sessionStorage.setItem('formdata', JSON.stringify(values));
        action.resetForm();
      },
    });

  const [error, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!errors.customerName && !touched.customerName) {
      if (!values.customerName) {
        newErrors.customerName = 'Please enter full name';
        toast.error('Please enter full name');
        document.getElementById('FullName').focus();
      }
    } else {
      if (!values.customerName) {
        toast.error('Please enter full name');
        touched.customerName = 'Please enter full name';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('FullName').focus();
      }
    }
    if (!errors.locationTypeId && !touched.locationTypeId) {
      if (!values.locationTypeId) {
        newErrors.locationTypeId = 'Please select Location Type';
        toast.error('Please select Location Type');
        document.getElementById('FullName').focus();
      }
    } else {
      if (!values.locationTypeId) {
        toast.error('Please select Location Type');
        touched.locationTypeId = 'Please select Location Type';
        newErrors.locationTypeId = 'Please select Location Type';
        document.getElementById('FullName').focus();
      }
    }

    if (!errors.email && !touched.email) {
      if (!values.email) {
        newErrors.email = 'Please enter your email';
        toast.error('Please enter your email');
        document.getElementById('Email').focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        newErrors.email = 'Invalid email format';
        toast.error('Invalid email format');
        document.getElementById('Email').focus();
      }
    } else {
      if (!values.email) {
        toast.error('Please enter your email');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Email').focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        toast.error('Invalid email format');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Email').focus();
      }
      touched.email = 'Please enter your email';
    }

    if (!errors.phone && !touched.phone) {
      if (values.phone.length === 0) {
        newErrors.phone = 'Mobile number is required';
        toast.error('Mobile number is required');
        document.getElementById('Phone').focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        newErrors.phone = 'Invalid mobile number';
        toast.error('Invalid mobile number');
        document.getElementById('Phone').focus();
      }
    } else {
      if (values.phone.length === 0) {
        toast.error('Mobile number is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Phone').focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        toast.error('Invalid mobile number');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Phone').focus();
      }
      touched.phone = 'Mobile number is required';
    }

    if (!errors.pinCode && !touched.pinCode) {
      if (!values.pinCode) {
        newErrors.pinCode = 'PinCode is required';
        toast.error('PinCode is required');
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        newErrors.pinCode = 'Pincode must only contain digits';
        toast.error('Pincode must only contain digits');
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length != 6) {
        newErrors.pinCode = 'Pincode must be exactly 6 digits';
        toast.error('Pincode must be exactly 6 digits');
        document.getElementById('Pincode').focus();
      }
    } else {
      if (!values.pinCode) {
        toast.error('PinCode is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        toast.error('Pincode must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length != 6) {
        toast.error('Pincode must be exactly 6 digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      }
      touched.pinCode = 'PinCode is required';
    }

    if (!errors.stateId && !touched.stateId) {
      if (values.stateId == -1) {
        newErrors.stateId = 'Please select State';
        toast.error('Please select State');
        document.getElementById('State').focus();
      }
    } else {
      if (values.stateId == -1) {
        toast.error('Please select States');
        touched.stateId = 'Please select States';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('State').focus();
      }
    }

    if (!errors.cityId && !touched.cityId) {
      if (!values.cityId) {
        newErrors.cityId = 'Please select City';
        toast.error('Please select City');
        document.getElementById('City').focus();
      }
    } else {
      if (!values.cityId) {
        toast.error('Please select City');
        touched.cityId = 'Please select City';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('City').focus();

        // newErrors.city_Id = 'Please select City';
      }
    }
    // if(!errors.avgConsumption && !touched.avgConsumption){
    //   if (!values.avgConsumption) {
    //     newErrors.avgConsumption = 'Please enter Average Consumption';
    //     toast.error("Please enter Average Consumption");
    //   }
    // }else{
    //   if (!values.avgConsumption) {
    //     toast.error("Please enter Average Consumption");
    //     touched.avgConsumption="Please enter Average Consumption";
    //    // newErrors.city_Id = 'Please select City';
    //   }
    // }

    if (!errors.avgConsumption && !touched.avgConsumption) {
      if (!values.avgConsumption) {
        newErrors.avgConsumption = 'Please enter Average Consumption';
        toast.error('Please enter Average Consumption');
        document.getElementById('ConsumedUnits').focus();
      } else if (!/^\d+$/.test(values.avgConsumption)) {
        newErrors.avgConsumption =
          'Average Consumption must only contain digits';
        toast.error('Average Consumption must only contain digits');
        document.getElementById('ConsumedUnits').focus();
      }
    } else {
      if (!values.avgConsumption) {
        toast.error('Please enter Average Consumption');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('ConsumedUnits').focus();
      } else if (!/^\d+$/.test(values.avgConsumption)) {
        toast.error('Average Consumption must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('ConsumedUnits').focus();
      }
      touched.avgConsumption = 'Please enter Average Consumption';
    }

    if (!errors.sanctionedLoad && !touched.sanctionedLoad) {
      if (!values.sanctionedLoad) {
        newErrors.sanctionedLoad = 'Please enter Sanctioned Load';
        toast.error('Please enter Sanctioned Load');
        document.getElementById('SanctionedLoad').focus();
      } else if (!/^\d+$/.test(values.sanctionedLoad)) {
        newErrors.sanctionedLoad = 'Sanctioned Load must only contain digits';
        toast.error('Sanctioned Load must only contain digits');
        document.getElementById('SanctionedLoad').focus();
      }
    } else {
      if (!values.sanctionedLoad) {
        toast.error('Please enter Average Sanctioned Load');
        newErrors.sanctionedLoad = 'Please enter Sanctioned Load';
        document.getElementById('SanctionedLoad').focus();
      } else if (!/^\d+$/.test(values.sanctionedLoad)) {
        toast.error('Sanctioned load must only contain digits');
        newErrors.sanctionedLoad = 'Please enter Sanctioned load';
        document.getElementById('SanctionedLoad').focus();
      }
      touched.sanctionedLoad = 'Please enter Sanctioned load';
    }

    if (!errors.electricityRate && !touched.electricityRate) {
      if (!values.electricityRate) {
        newErrors.electricityRate = 'Please enter Electricity Rate';
        toast.error('Please enter Electricity Rate');
        document.getElementById('ElectricityRate').focus();
      } else if (!/^\d+(\.\d+)?$/.test(values.electricityRate)) {
        newErrors.electricityRate = 'Electricity Rate must only contain digits';
        toast.error('Electricity Rate must only contain digits');
        document.getElementById('ElectricityRate').focus();
      }
    } else {
      if (!values.electricityRate) {
        toast.error('Please enter Electricity Rate');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('ElectricityRate').focus();
      } else if (!/^\d+(\.\d+)?$/.test(values.electricityRate)) {
        toast.error('Electricity Rate must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('ElectricityRate').focus();
      }
      touched.electricityRate = 'Please enter Electricity Rate';
    }

    if (!errors.areaAvailable && !touched.areaAvailable) {
      if (!values.areaAvailable) {
        newErrors.areaAvailable = 'Please enter Available Area';
        toast.error('Please enter Available Area');
        document.getElementById('RooftopArea').focus();
      } else if (!/^\d+$/.test(values.areaAvailable)) {
        newErrors.areaAvailable = 'Area Available must only contain digits';
        toast.error('Area Available must only contain digits');
        document.getElementById('RooftopArea').focus();
      }
    } else {
      if (!values.areaAvailable) {
        toast.error('Please enter Available Area');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('RooftopArea').focus();
      } else if (!/^\d+$/.test(values.areaAvailable)) {
        toast.error('Area Available must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('RooftopArea').focus();
      }
      touched.areaAvailable = 'Please enter Available Area';
    }

    return newErrors;
  };
  const sendOTP = event => {
    event.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      // Submit form data or perform further actions here
      setEditable(false);

      setSmsOtpEditable(true);
      setEmailOtpEditable(true);
      const sendOtpData = {
        email: values.email,
        phone: values.phone,
      };
      sendOtp.mutate(sendOtpData);
    } else {
      setErrors(newErrors);
    }
  };

  const sendOtp = useMutation(querydata.sendOtpRequest, {
    onError: error => {
      toast.error(error.message);
      setEditable(true);
      // console.log(error.message);
      // console.log(error);
    },
    onSuccess: data => {
      setShowOtpDiv(true);
      const hideform = document.getElementsByClassName('hideform');
      // Loop through the elements and hide each one
      for (let i = 0; i < hideform.length; i++) {
        hideform[i].style.display = 'none';
      }
      const hideform1 = document.getElementsByClassName('hideform1');
      // Loop through the elements and hide each one
      for (let i = 0; i < hideform1.length; i++) {
        hideform1[i].style.display = 'none';
      }
      toast.success('OTP Sent. Please Check your Email and Phone');
      // console.log("Succesful",{data});
    },
  });

  const handleOtpChange = event => {
    const emailOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;
    if (otpRegex.test(emailOTP)) {
      setOtp(emailOTP);
      if (emailOTP.length === 6) {
        verifyEmailOtp.mutate(emailOTP);
      }
    }
  };
  const handlelocationChange = e => {
    handleChange(e);
    error.locationTypeId = '';
  };
  const smsOtpChange = event => {
    const smsOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;

    if (otpRegex.test(smsOTP)) {
      setSmsOtp(smsOTP);
      if (smsOTP.length === 6) {
        verifySmsOtp.mutate(smsOTP);
      }
    }
  };
  const verifySmsOtp = useMutation(querydata.verifySmsOTP, {
    onError: error => {
      toast.error('Incorrect Email OTP');
    },
    onSuccess: data => {
      toast.success('SMS OTP Verified Successfully');
      setIsSmsOtpVerified(true);
      setSmsOtpEditable(false);
    },
  });

  const verifyEmailOtp = useMutation(querydata.verifyEmailOTP, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess: data => {
      toast.success('Email OTP Verified Successfully');
      setIsOtpVerified(true);
      setEmailOtpEditable(false);
      // console.log("Succesful",{data});
    },
  });

  const handleEdit = () => {
    setEditable(true); // Enable form editing when the edit button is clicked.
    setShowOtpDiv(false); // Hide OTP div on edit button click
    setOtp('');
    setSmsOtp('');
    setIsOtpVerified(false);
    setIsSmsOtpVerified(false);
    setEmailOtpEditable(true);
    setSmsOtpEditable(true);
    const hideform = document.getElementsByClassName('hideform');
    const hideform1 = document.getElementsByClassName('hideform1');

    // Loop through the elements and hide each one
    for (let i = 0; i < hideform.length; i++) {
      hideform[i].style.display = 'flex';
    }
    for (let i = 0; i < hideform1.length; i++) {
      hideform1[i].style.display = 'inline';
    }
  };

  const handleConsumptionChange = e => {
    handleChange();
    setPercentage(e.target.value);
  };

  const getFilteredCities = useQuery(
    ['getFilteredCities', +values.stateId],
    () => querydata.getFilteredCities(values.stateId)
  );
  const locationtype = useQuery('LocationType', querydata.locationType);
  //form post
  const updateStatusMutation = useMutation(querydata.insertLeads, {
    onError: error => {
      //toast.error(error.message)
    },
    onSuccess: data => {
      // Wiconsole.log(data);ll be called 3 times
      // toast.success(data.message);

      // localStorage.setItem('Calculations', JSON.stringify(data.calculations));
      const selectedLocationTypeId = +values.locationTypeId;
      const filteredLocationType = locationtype.data.data.find(
        type => type.location_type_id === selectedLocationTypeId
      );
      const selectedLocationTypeCode = filteredLocationType
        ? filteredLocationType.location_type_code
        : null;

      sessionStorage.setItem(
        'selectedLocationTypeCode',
        JSON.stringify(selectedLocationTypeCode)
      );
      sessionStorage.setItem('Calculations', JSON.stringify(data.calculations));
      setCal(data.calculations);
      // alert("Data Saved Successfully")
      navigate('/mysavings');
    },
  });
  return (
    <>
      {
        //reloadonce()
      }
      <section>
        <div className="saving-calculator header-space pt-1">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="saving-header calculator-savaing-header mb-2 d-flex align-items-center justify-content-between">
                  <h5 className="fw-bold">Calculate my Savings</h5>
                  {/* <div className="close-page">
                    <Link to="/" className="close-now-page"><img src="images/close.svg" alt="Close" title="Close" /></Link>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 pe-xl-5">
                <form id="estimatorform">
                  <div className="saving-calculate-form">
                    <div className="heading mb-xl-4 mb-3">
                      <span className="fw-semibold saving-title">
                        Personal Details
                      </span>
                    </div>
                    <div className="row hideform">
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="FullName"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Full Name</span>
                            <div className="d-flex user-name-with-salutation">
                              <select
                                id="Salutation"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                name="salutation"
                                value={values.salutation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              >
                                <option value="">Select</option>
                                <option value="Mr.">Mr.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Mrs.">Mrs.</option>
                              </select>
                              <input
                                type="text"
                                id="FullName"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Customer Name"
                                name="customerName"
                                value={values.customerName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              />
                            </div>
                          </label>
                          {/* {errors.customerName && touched.customerName ? (
                            <p className="form-error text-danger">{errors.customerName}</p>
                          ) : null} */}
                          {errors.customerName && touched.customerName ? (
                            <p className="form-error text-danger">
                              {errors.customerName}
                            </p>
                          ) : error.customerName ? (
                            <p className="form-error text-danger">
                              {error.customerName}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Email"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Email</span>
                            <input
                              type="email"
                              id="Email"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Email Address"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />
                          </label>
                          {/* {errors.email && touched.email ? (
                            <p className="form-error text-danger">{errors.email}</p>
                          ) : null} */}
                          {errors.email && touched.email ? (
                            <p className="form-error text-danger">
                              {errors.email}
                            </p>
                          ) : error.email ? (
                            <p className="form-error text-danger">
                              {error.email}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Phone"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Phone</span>
                            <input
                              type="tel"
                              id="Phone"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Phone Number"
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />
                          </label>
                          {/* {errors.phone && touched.phone ? (
                            <p className="form-error text-danger">{errors.phone}</p>
                          ) : null} */}
                          {errors.phone && touched.phone ? (
                            <p className="form-error text-danger">
                              {errors.phone}
                            </p>
                          ) : error.phone ? (
                            <p className="form-error text-danger">
                              {error.phone}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Pincode"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Pincode</span>
                            <input
                              type="text"
                              id="Pincode"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Area Pincode"
                              name="pinCode"
                              value={values.pinCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />
                          </label>
                          {/* {errors.pinCode && touched.pinCode ? (
                            <p className="form-error text-danger">{errors.pinCode}</p>
                          ) : null} */}
                          {errors.pinCode && touched.pinCode ? (
                            <p className="form-error text-danger">
                              {errors.pinCode}
                            </p>
                          ) : error.pinCode ? (
                            <p className="form-error text-danger">
                              {error.pinCode}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <StateSelect
                            values={values}
                            setSelectedState={setSelectedState}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={!editable}
                          />
                          {errors.stateId && touched.stateId ? (
                            <p className="form-error text-danger">
                              {errors.stateId}
                            </p>
                          ) : error.stateId ? (
                            <p className="form-error text-danger">
                              {error.stateId}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-xl-4 col-md-6">
                        <div className="form-group mb-4">
                          <CitySelect
                            name="cityId"
                            parameter={values}
                            selectedState={selectedState}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                            disabled={!editable}
                          />
                          {errors.cityId && touched.cityId ? (
                            <p className="form-error text-danger">
                              {errors.cityId}
                            </p>
                          ) : error.cityId ? (
                            <p className="form-error text-danger">
                              {error.cityId}
                            </p>
                          ) : !errors.cityId &&
                            error.cityId &&
                            touched.cityId ? (
                            <p className="form-error text-danger">
                              Please select a city
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-12">
                        <div className="form-group mb-2">
                          <div className="checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                            <div className="location-title mb-0">
                              <span className="fw-bold">Location type</span>
                            </div>
                            {locationtype.data &&
                              locationtype.data.data.map(locationType => {
                                return (
                                  <>
                                    <label className="checkboxes-view p-0">
                                      <div className="check-now position-relative d-flex align-items-center">
                                        <input
                                          type="radio"
                                          name="locationTypeId"
                                          className="position-absolute top-0"
                                          key={locationType.location_type_id}
                                          value={locationType.location_type_id}
                                          onChange={handlelocationChange}
                                          onBlur={handleBlur}
                                          disabled={!editable}
                                        />
                                        <div className="now-check rounded-pill position-relative"></div>
                                        <span disabled={!editable}>
                                          {locationType.location_type}
                                        </span>
                                      </div>
                                    </label>
                                  </>
                                );
                              })}

                            {/* {errors.locationTypeId && touched.locationTypeId ? (
                      <p className="form-error text-danger">{errors.locationTypeId}</p>
                    ) : null} */}
                          </div>
                          {errors.locationTypeId && touched.locationTypeId ? (
                            <p className="form-error text-danger">
                              {errors.locationTypeId}
                            </p>
                          ) : error.locationTypeId ? (
                            <p className="form-error text-danger">
                              {error.locationTypeId}
                            </p>
                          ) : null}
                          {/* <!-- /checkbox-labels --> */}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                  <div className="saving-calculate-form mt-2 hideform1">
                    <div className="heading mb-xl-4 mb-3">
                      <span className="fw-semibold saving-title">
                        Electricity Details
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="ConsumedUnits"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">
                              Avg units consumed/month
                            </span>
                            <input
                              type="text"
                              id="ConsumedUnits"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Enter Units"
                              name="avgConsumption"
                              value={values.avgConsumption}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />
                          </label>
                          {/* {errors.avgConsumption && touched.avgConsumption ? (
                      <p className="form-error text-danger">{errors.avgConsumption}</p>
                    ) : null} */}
                          {errors.avgConsumption && touched.avgConsumption ? (
                            <p className="form-error text-danger">
                              {errors.avgConsumption}
                            </p>
                          ) : error.avgConsumption ? (
                            <p className="form-error text-danger">
                              {error.avgConsumption}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="ElectricityRate"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Electricity Rate</span>
                            <input
                              type="text"
                              id="ElectricityRate"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Rate/Unit"
                              name="electricityRate"
                              value={values.electricityRate}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />

                            <span className="unit-label fw-light position-absolute">
                              ₹/unit
                            </span>
                          </label>
                          {/* {errors.electricityRate && touched.electricityRate ? (
                      <p className="form-error text-danger">{errors.electricityRate}</p>
                    ) : null} */}
                          {errors.electricityRate && touched.electricityRate ? (
                            <p className="form-error text-danger">
                              {errors.electricityRate}
                            </p>
                          ) : error.electricityRate ? (
                            <p className="form-error text-danger">
                              {error.electricityRate}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="SanctionedLoad"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Sanctioned Load</span>
                            <input
                              type="text"
                              id="SanctionedLoad"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Sanctioned Load"
                              name="sanctionedLoad"
                              value={values.sanctionedLoad}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />
                            <span className="unit-label fw-light position-absolute">
                              Kilo Watt
                            </span>
                          </label>
                          {/* {errors.electricityRate && touched.electricityRate ? (
                      <p className="form-error text-danger">{errors.electricityRate}</p>
                    ) : null} */}
                          {errors.sanctionedLoad && touched.sanctionedLoad ? (
                            <p className="form-error text-danger">
                              {errors.sanctionedLoad}
                            </p>
                          ) : error.sanctionedLoad ? (
                            <p className="form-error text-danger">
                              {error.sanctionedLoad}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="RooftopArea"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">
                              Rooftop Area Available
                            </span>
                            <input
                              type="text"
                              id="RooftopArea"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Area"
                              name="areaAvailable"
                              value={values.areaAvailable}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={!editable}
                            />
                            <span className="unit-label fw-light position-absolute">
                              Sq. ft.
                            </span>
                          </label>
                          {/* {errors. areaAvailable && touched. areaAvailable ? (
                      <p className="form-error text-danger">{errors. areaAvailable}</p>
                    ) : null} */}
                          {errors.areaAvailable && touched.areaAvailable ? (
                            <p className="form-error text-danger">
                              {errors.areaAvailable}
                            </p>
                          ) : error.areaAvailable ? (
                            <p className="form-error text-danger">
                              {error.areaAvailable}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-6">
                        <div className="form-group mb-1">
                          <label
                            htmlFor="Counsumption"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">
                              % of consumption to be shifted to solar
                            </span>
                            <div className="unit-label counsumption-slider w-100">
                              <div className="set-range d-flex align-items-center w-100">
                                <div className="left-range-set">
                                  <div className="range">
                                    <input
                                      type="range"
                                      min="0"
                                      max="100"
                                      step="1"
                                      name="consumptionToShift"
                                      defaultValue="50"
                                      onChange={handleChange}
                                      // onChange={(e) => { handleChange(); setPercentage(e.target.value);
                                      //          }}
                                      value={values.consumptionToShift}
                                      onBlur={handleBlur}
                                      disabled={!editable}
                                    />
                                  </div>
                                </div>
                                {/* <!-- /left-range-set --> */}
                                <div className="right-range-value">
                                  <span className="border rounded-2 w-100 d-flex p-1 pt-0 pb-0 fw-bold">
                                    {values.consumptionToShift}%
                                  </span>
                                </div>
                                {/* <!-- /right-range-value --> */}
                              </div>
                              {/* <!-- /set-range --> */}
                            </div>
                          </label>
                          {errors.consumptionToShift &&
                          touched.consumptionToShift ? (
                            <p className="form-error text-danger">
                              {errors.consumptionToShift}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-xl-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="ConnectGrid"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Connect to grid</span>
                            <input
                              type="tel"
                              id="ConnectGrid"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder=""
                              readOnly
                            />
                            <div className="unit-label fw-light position-absolute">
                              <div className="checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                                <label
                                  htmlFor="GridYes"
                                  className="checkboxes-view p-0 me-4"
                                >
                                  <div className="check-now position-relative d-flex align-items-center">
                                    <input
                                      type="radio"
                                      defaultChecked
                                      id="GridYes"
                                      name="connectedToGrid"
                                      className="position-absolute top-0"
                                      value={1}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled={!editable}
                                    />
                                    <div className="now-check rounded-pill position-relative"></div>
                                    <span>Yes</span>
                                  </div>
                                  {/* <!-- /check-now --> */}
                                </label>
                                <label
                                  htmlFor="GridNo"
                                  className="checkboxes-view p-0"
                                >
                                  <div className="check-now position-relative d-flex align-items-center">
                                    <input
                                      type="radio"
                                      id="GridNo"
                                      name="connectedToGrid"
                                      className="position-absolute top-0"
                                      value={0}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      disabled={!editable}
                                    />
                                    <div className="now-check rounded-pill position-relative"></div>
                                    <span>No</span>
                                  </div>
                                  {/* <!-- /check-now --> */}
                                </label>
                              </div>
                              {/* <!-- /checkbox-labels --> */}
                            </div>
                          </label>
                          {errors.connectedToGrid && touched.connectedToGrid ? (
                            <p className="form-error text-danger">
                              {errors.connectedToGrid}
                            </p>
                          ) : null}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                  {/* <div className="col-12">
                    <div className="solfin-action mt-lg-4 mt-2 mb-4">
                      <input type="submit" value="send otp" className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn" />
                      <input type="reset" value="cancel" onClickCapture={closeerrormessage}
                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border" />
                    </div>
                  </div> */}
                  {/* <!-- /col-12 --> */}
                  {/* <div className="col-xl-5 col-lg-7">
                    <div className="send-otp-field d-flex align-items-center">
                      <div className="form-group w-100">
                        <label htmlFor="EnterOTP" className="border rounded-4 w-100 position-relative">
                          <span className="fw-bold">Enter OTP</span>
                          <input type="text" id="EnterOTP" className="form-control border-0 ps-0 bg-transparent pt-1 pb-1" placeholder="----" />
                        </label>
                      </div>
                      {/* <!-- /form-group --> */}
                  {/* <div className="resend-otp ms-4">
                        <a href="#" className="text-dark text-decoration-none fw-semibold text-nowrap">Resend OTP</a>
                      </div>
                    </div>
                  </div>  */}
                  {/* <!-- /col-lg-4 col-md-6 --> */}
                  {!showOtpDiv ? (
                    <div className="col-lg-12 mb-3">
                      {/* <!-- /form-group --> */}
                      <div className="check-conditions mb-0">
                        <label
                          htmlFor="AllowTerms"
                          className="checkboxes-view p-0 position-relative d-flex"
                        >
                          <input
                            onChange={() => setIsChecked(prev => !prev)}
                            type="checkbox"
                            name=""
                            className="position-absolute top-0"
                            id="AllowTerms"
                          />
                          <div className="now-check position-relative m-0"></div>
                          <p>
                            <span>
                              By clicking here, I state that I have read and
                              understood the{' '}
                            </span>
                            <a
                              className="fw-bold text-dark text-decoration-underline"
                              href="/Termsandconditions"
                              target="_blank"
                            >
                              Terms and Conditions
                            </a>
                            <span>, </span>
                            <a
                              className="fw-bold text-dark text-decoration-underline"
                              href="/Privacypolicy"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                            <span> and </span>
                            <a
                              className="fw-bold text-dark text-decoration-underline"
                              href="/CookiePolicy"
                              target="_blank"
                            >
                              Cookie Policy
                            </a>
                          </p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="col-12">
                    <div className="solfin-action mt-1 mb-4">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="solfin-action mb-4">
                          {editable ? (
                            <input
                              type="submit"
                              value="Submit"
                              disabled={!isChecked}
                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                              onClick={sendOTP}
                            />
                          ) : (
                            <>
                              {sendOtp.isLoading ? (
                                <button
                                  className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn2"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    aria-hidden="true"
                                  ></span>
                                  <span
                                    className="visually-hidden"
                                    role="status"
                                  >
                                    Loading...
                                  </span>
                                </button>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <>
                        {/* {showOtpDiv && (
                          <div className="provided-email-otp">
                            <div className="otp-label">
                              <p>
                                OTPs have been sent to your email{" "}
                                <b>{values.email} </b>and phone{" "}
                                <b>{values.phone}</b>
                              </p>
                            </div>
                            <div className="row gx-5">
                              <div className="col-md-6">
                                <div className="send-otp-field">
                                  <div className="form-group w-100">
                                    <label
                                      for="EnterOTP"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">
                                        Enter Email OTP
                                      </span>
                                      <input
                                        type="text"
                                        id="EnterOTP"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Enter 6 digit OTP"
                                        value={otp}
                                        onChange={handleOtpChange}
                                        disabled={!emailOtpEditable}
                                      />
                                    </label>
                                  </div>

                                  <div className="resend-otp mt-2">
                                    <button
                                      className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                      onClick={sendOTP}
                                    >
                                      Resend OTP
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="send-otp-field">
                                  <div className="form-group w-100">
                                    <label
                                      for="EnterOTP"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">
                                        Enter SMS OTP
                                      </span>
                                      <input
                                        type="text"
                                        id="EnterOTP"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Enter 6 digit OTP"
                                        value={smsOtp}
                                        onChange={smsOtpChange}
                                        disabled={!smsOtpEditable}
                                      />
                                    </label>
                                  </div>
                                  <div className="resend-otp mt-2">
                                    <button
                                      className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                      onClick={sendOTP}
                                    >
                                      Resend OTP
                                    </button>
                                  </div>
                                </div>
                              </div>


                              {(isOtpVerified && isSmsOtpVerified)? (
                                <div className="col-12">
                                  <div className="solfin-action mt-4 mb-4 d-flex">
                                    {updateStatusMutation.isLoading ? (
                                      <button
                                        class="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn1"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          aria-hidden="true"
                                        ></span>
                                        <span
                                          class="visually-hidden"
                                          role="status"
                                        >
                                          Loading...
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        value="Calculate"
                                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                        onClick={handleSubmit}
                                      >
                                        {" "}
                                        Calculate{" "}
                                      </button>
                                    )}
                                    <input
                                      type="reset"
                                      value="cancel"
                                      onClickCapture={closeerrormessage}
                                      className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                                    />
                                  </div>
                                </div>
                              ):(
                                <>
                                <p>
                                  If you haven't received the OTPs,
                                  please check your email/phone number.
                                  In order to change them, click on Edit
                                  and re-submit the form
                                </p>

                                <button
                                  type="button"
                                  onClick={handleEdit}
                                  className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                >
                                  Back
                                </button>
                              </>

                              )}
                            </div>
                          </div>
                        )} */}

                        {showOtpDiv ? (
                          <div className="provided-email-otp">
                            <div className="otp-label">
                              <p>
                                OTPs have been sent to your email{' '}
                                <b>{values.email} </b>and phone{' '}
                                <b>{values.phone}</b>
                              </p>
                              {/* <p>Please enter the OTP shared on your Email provided in the form above</p> */}
                            </div>
                            <div className="row gx-5">
                              <div className="col-md-6">
                                <div className="send-otp-field">
                                  <div className="form-group w-100">
                                    <label
                                      htmlFor="EnterOTP"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">
                                        Enter Email OTP
                                      </span>
                                      <input
                                        type="text"
                                        id="EnterOTP"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Enter 6 digit OTP"
                                        value={otp}
                                        onChange={handleOtpChange}
                                        disabled={!emailOtpEditable}
                                      />
                                    </label>
                                  </div>
                                  {/* <!-- /form-group --> */}
                                  <div className="resend-otp mt-2">
                                    <button
                                      className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                      onClick={sendOTP}
                                    >
                                      Resend OTP
                                    </button>
                                  </div>
                                </div>
                                {/* <!-- /send otp field --> */}
                              </div>
                              {/* <!-- /col-md-6 --> */}
                              <div className="col-md-6">
                                <div className="send-otp-field">
                                  <div className="form-group w-100">
                                    <label
                                      htmlFor="EnterOTP"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">
                                        Enter SMS OTP
                                      </span>
                                      <input
                                        type="text"
                                        id="EnterOTP"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Enter 6 digit OTP"
                                        value={smsOtp}
                                        onChange={smsOtpChange}
                                        disabled={!smsOtpEditable}
                                      />
                                    </label>
                                  </div>
                                  <div className="resend-otp mt-2">
                                    <button
                                      className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                      onClick={sendOTP}
                                    >
                                      Resend OTP
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- /row --> */}
                          </div>
                        ) : (
                          ''
                        )}
                        {isOtpVerified && isSmsOtpVerified ? (
                          <div className="col-12">
                            <div className="solfin-action mt-4 mb-4 d-flex">
                              {updateStatusMutation.isLoading ? (
                                <button
                                  className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn1"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    aria-hidden="true"
                                  ></span>
                                  <span
                                    className="visually-hidden"
                                    role="status"
                                  >
                                    Loading...
                                  </span>
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  value="Register"
                                  className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                  onClick={handleSubmit}
                                >
                                  {' '}
                                  Register{' '}
                                </button>
                              )}
                              {/* <input
                                type="reset"
                                value="cancel"
                                onClickCapture={closeerrormessage}
                                className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                              /> */}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <br />

                        {showOtpDiv && !editable ? (
                          <>
                            {isOtpVerified && isSmsOtpVerified ? (
                              ''
                            ) : (
                              <>
                                <p>
                                  If you haven't received the OTPs, please check
                                  your email/phone number. In order to change
                                  them, click on Back and re-submit the form
                                </p>
                                <button
                                  type="button"
                                  onClick={handleEdit}
                                  className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                >
                                  Back
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    </div>
                  </div>

                  {/* <!-- /col-12 --> */}
                </form>
              </div>
              {/* <!-- /col-lg-8 --> */}
              <div className="col-lg-4">
                <div className="right-side-news rounded-4 position-relative overflow-hidden mt-lg-5">
                  <div className="text-ad-news pb-3">
                    <h5 className="fw-bold mb-4">
                      Go solar with <br />
                      Solfin
                    </h5>
                    <p>
                      A transition to clean energy is about making an investment
                      in our future
                    </p>
                    <div className="solfin-action mt-lg-4 mt-3 d-none">
                      <a href="#" className="text-decoration-none">
                        <span className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn text-white">
                          Learn More
                        </span>{' '}
                        <img
                          className="ms-3"
                          src="/images/long-next-arrow.svg"
                          alt="Learn More"
                          title="Learn More"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="bottom-image mt-4 ps-4">
                    <img
                      className="w-100"
                      src="/images/rooftop-area.png"
                      alt="Rooftop Area"
                      title="Rooftop Area"
                    />
                  </div>
                </div>
                {/* <!-- /right-side-news --> */}
              </div>
              {/* <!-- /col-lg-4 --> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default Estimator;
