const ContactSalesForLease = () => {
  return (
    <>
      <section>
        <div className="saving-calculator header-space">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="coming-soon-page text-center">
                  <img
                    src="/images/coming-soon.svg"
                    alt="Solfin"
                    title="Coming Soon"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h1 className="fw-bold heading-color">
                  Please write to us at{' '}
                  <a
                    className="text-theme-green"
                    href="mailto:sales@solfin.co.in"
                  >
                    sales@solfin.co.in
                  </a>{' '}
                  if any lease is required.
                </h1>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default ContactSalesForLease;
