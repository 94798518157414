import { Route, Routes } from 'react-router-dom';

import RouteWrapper from './RouteWrapper';

import Header from '../components/Header';
import Register from '../pages/register/Register';
import Dsa from '../pages/dsa/Dsa';
import Epc from '../pages/epc/Epc';
import Home from '../pages/home/Home';
import Aboutus from '../pages/aboutus/AboutUs';
import Estimator from '../pages/estimator/Estimator';
import Termsandconditions from '../pages/termsandconditions/Termsandconditions';
import PartnerRegistrationComplete from '../pages/partnerregistrationcomplete/PartnerRegistrationComplete';
import RegistrationComplete from '../pages/registrationcomplete/RegistrationComplete';
import HomePageAdditions from '../components/HomePageAdditions';
import MySavings from '../pages/estimator/MySavings';
import Login from '../pages/login/Login';
import ComingSoon from '../pages/comingSoon/ComingSoon';
import Mission from '../pages/others/Mission';
import PremiumPartners from '../pages/premiumPartners/PremiumPartners';
import ResidentialLoanProducts from '../pages/loan/LoanProducts';
import Knowledge from '../pages/knowledge/Knowledge';
import ForgotPassword from '../pages/login/ForgotPassword';
import ResetPassword from '../pages/login/ResetPassword';
import NewCustomerRegisterComplete from '../pages/registrationcomplete/NewCustomerRegisterComplete';
import OldCustomerRegisterComplete from '../pages/registrationcomplete/OldCustomerRegisterComplete';
import CustomerHeader from '../components/CustomerHeader';
import Lease from '../pages/loan/Lease';
import RefundPolicy from '../pages/refundpolicy/RefundPolicy';
import CookiePolicy from '../pages/cookiePolicy/cookiePolicy';
import WantFinance from '../pages/finance/WantFinance';
import DhanvikasPrivacyPolicy from '../pages/privacypolicy/DhanvikasPrivacypolicy';
import XsPrivacyPolicy from '../pages/privacypolicy/XSFinanacePrivacypolicy';
import DistributorJoinV2 from '../pages/distributor/DistributorJoin';
import FairPracticeCode from '../pages/fairPracticeCode/FairPracticeCode';
import GrievanceRedressalPolicy from '../pages/grievanceRedressalPolicy/GrievanceRedressalPolicy';

import ContactSales from '../pages/contactSales/ContactSales';
import ContactSalesForLease from '../pages/contactSales/ContactSalesLease';

const AllRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RouteWrapper>
            <Home />
            <HomePageAdditions />
          </RouteWrapper>
        }
      />
      <Route
        exact
        path="/Register"
        element={
          <RouteWrapper>
            <Register />
          </RouteWrapper>
        }
      />
      <Route
        path="/finance"
        element={
          <RouteWrapper>
            <WantFinance />
          </RouteWrapper>
        }
      />
      <Route
        exact
        path="/RegisterCustomer"
        element={
          <>
            <CustomerHeader />
            <Register />
          </>
        }
      />
      <Route
        path="/PremiumPartners"
        element={
          <RouteWrapper>
            <PremiumPartners />
          </RouteWrapper>
        }
      />
      <Route
        path="/CookiePolicy"
        element={
          <RouteWrapper>
            <CookiePolicy />
          </RouteWrapper>
        }
      />

      <Route
        path="/Epc"
        element={
          <RouteWrapper>
            <Epc />
          </RouteWrapper>
        }
      />
      <Route
        path="/Dsa"
        element={
          <RouteWrapper>
            <Dsa />
          </RouteWrapper>
        }
      />
      <Route
        path="/Aboutus"
        element={
          <RouteWrapper>
            <Aboutus />
          </RouteWrapper>
        }
      />
      <Route
        path="/Estimator"
        element={
          <RouteWrapper>
            <Estimator />
          </RouteWrapper>
        }
      />
      <Route
        path="/MySavings"
        element={
          <RouteWrapper>
            <MySavings />
          </RouteWrapper>
        }
      />
      <Route
        path="/loan-products/residential"
        element={
          <RouteWrapper>
            <ResidentialLoanProducts />
          </RouteWrapper>
        }
      />
      <Route
        path="/knowledge"
        element={
          <RouteWrapper>
            <Knowledge />
          </RouteWrapper>
        }
      />

      <Route
        path="/Privacypolicy"
        element={
          <RouteWrapper>
            <XsPrivacyPolicy />
          </RouteWrapper>
        }
      />
      <Route
        path="/DhanvikasPrivacypolicy"
        element={
          <RouteWrapper>
            <DhanvikasPrivacyPolicy />
          </RouteWrapper>
        }
      />
      <Route
        path="/Refundpolicy"
        element={
          <RouteWrapper>
            <RefundPolicy />
          </RouteWrapper>
        }
      />
      <Route
        path="/FairPracticeCode"
        element={
          <RouteWrapper>
            <FairPracticeCode />
          </RouteWrapper>
        }
      />

      <Route
        path="/GrievanceRedressalPolicy"
        element={
          <RouteWrapper>
            <GrievanceRedressalPolicy />
          </RouteWrapper>
        }
      />
      <Route
        path="/Termsandconditions"
        element={
          <RouteWrapper>
            <Termsandconditions />
          </RouteWrapper>
        }
      />
      <Route
        path="/distributor/join"
        element={
          <>
            <Header />
            <DistributorJoinV2 />
          </>
        }
      ></Route>
      <Route path="/RegistrationComplete" element={<RegistrationComplete />} />
      <Route
        path="/cust-registration-complete"
        element={<OldCustomerRegisterComplete />}
      />
      <Route
        path="/cust-registered"
        element={<NewCustomerRegisterComplete />}
      />
      <Route
        path="PartnerRegistrationComplete"
        element={<PartnerRegistrationComplete />}
      />
      <Route
        path="/login"
        element={
          <RouteWrapper>
            <Login />
          </RouteWrapper>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <RouteWrapper>
            <ForgotPassword />
          </RouteWrapper>
        }
      />
      <Route
        path="/reset-password/:token"
        element={
          <>
            <ResetPassword />
          </>
        }
      />

      <Route
        path="/lease"
        element={
          <RouteWrapper>
            <Lease />
          </RouteWrapper>
        }
      />
      <Route
        path="/coming-soon"
        element={
          <RouteWrapper>
            <ComingSoon />
          </RouteWrapper>
        }
      />
      <Route
        path="/mission"
        element={
          <RouteWrapper>
            <Mission />
          </RouteWrapper>
        }
      />
      <Route
        path="/contact-sales"
        element={
          <RouteWrapper>
            <ContactSales />
          </RouteWrapper>
        }
      />
      <Route
        path="/lease-contact-sales"
        element={
          <RouteWrapper>
            <ContactSalesForLease />
          </RouteWrapper>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
