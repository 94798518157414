import React from 'react';
import {Link} from 'react-router-dom';
const XsPrivacyPolicy = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    <span className="text-theme-green">
                      XSFinance and Investment Private Limited
                    </span>{' '}
                    Privacy <span className="text-theme-green">Policy</span>
                  </h2>
                </div>
                <div className="mt-xl-4 mt-3">
                  <p>
                    XSFinance and Investment Private Limited is a Company
                    registered under the Companies Act 2013 and having its
                    registered office at D-001, POINEER ARAYA, SECTOR-62,
                    Gurgaon, Gurgaon, Sadar Bazar, Haryana, India, 122001,
                    (hereinafter referred as “Company”, “We”, “Us” or “Our”).
                  </p>
                  <p>
                    We respect Your privacy and are committed to protecting it
                    through Our compliance with this privacy policy (“Policy”)
                    in compliance with the Information Technology (Reasonable
                    Security Practices and Procedures and Sensitive Personal
                    Data or Information) Rules, 2011, Reserve Bank of India’s
                    Guidelines on Digital Lending and other applicable laws.
                  </p>
                  <p>This Policy describes:</p>
                  <p>
                    a) the types of information that We may collect from You
                    when You access, use or avail any products or services We
                    offer through Our website(s) namely “Solfin”, application(s)
                    and other online platform(s) (collectively, Our "Platform")
                    which is owned, managed and operated by Us; and
                  </p>
                  <p>
                    b) Our practices for collecting, using, storing,
                    transferring, processing, maintaining, protecting, and
                    disclosing that information.
                  </p>
                  <p>
                    For the purposes of this privacy policy the term “User(s)”
                    and wherever the context so requires “You”, “Your”,
                    “Yourself” shall mean any natural or legal person who
                    accesses or uses the Platform and in the event that a
                    natural person is representing a business entity or a body
                    corporate, reference to such terms shall include a reference
                    to such business entity and/or body corporate as well.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  YOUR ACKNOWLEDGMENT AND CONSENT
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  By visiting Our Platform You agree to be bound by the terms
                  and conditions of this Policy. If You do not agree please do
                  not use or access Our Platform.
                </p>
                <p>
                  By mere use of the Platform, You expressly and unconditionally
                  agree to the terms and conditions of this Policy and consent
                  to the usage, storage and handling of the information
                  submitted by You in accordance with the terms contained
                  herein.
                </p>
                <p>
                  This Policy applies only to the User Information (defined
                  below) that We collect through Our Platform. When You submit
                  User Information on the Platform, it shall be deemed that You
                  have granted Us the right to collect, store, process, handle
                  and use such User Information, in accordance with this Policy
                  (as amended from time to time).
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  INFORMATION WE COLLECT
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  We collect various types of information from and about Users
                  of Our Platform, including:
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      “Personal Information” which includes any information
                      concerning personal or material circumstances of an
                      identified or identifiable individual and can be used to
                      uniquely identify or contact a person and/or the business
                      entity that a person represents; and/or
                    </span>
                  </li>
                  <li>
                    <span>
                      “Sensitive Personal Information” which means any Personal
                      Information of an individual, which consists of
                      information relating to passwords; financial information
                      such as bank account, or other payment instrument details;
                      sexual orientation. Information freely available or
                      accessible in public domain is not treated as Sensitive
                      Personal Information. Depending on who You are (e.g., a
                      merchant, customer, consumer, supplier or business
                      partner) and how You interact with Us (e.g., through
                      Platform, telephone, IVR, API, online or offline), We may
                      collect, use, receive, store, analyze, combine, transfer
                      or otherwise process different categories of Your Personal
                      Information (including Sensitive Personal Information).
                      Any information that is freely available or accessible in
                      public domain or furnished under the Right to Information
                      Act, 2005 or any other law for the time being in force
                      shall not be regarded as Sensitive Personal Information
                      for the purposes of this Policy.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  INFORMATION YOU PROVIDE TO US
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  You provide Your explicit consent for collection of
                  information, on Our Platform (which may be collected from the
                  parties as per this Policy), which includes but is not limited
                  to (“User Information”):
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      {' '}
                      Your full name, address, email address, telephone number,
                      date of birth and bank details and any proof of Your
                      identity and/or address, postal code, profile picture,
                      password and other information You may provide with Your
                      account (including but not limited to, account holder,
                      account name, account number, transaction authentication
                      number “TAN” and password, available balance and
                      transaction history of Your bank account), ITR, Tax
                      Certificates or any other income documents as necessary by
                      Us, such as Your gender, mobile phone number and website
                      and all other information, You may provide Us through
                      third-party sign-in services such as Facebook and Google .
                      In such events/ cases, We fetch and store whatever
                      information is made available to Us by You through these
                      sign-in services or otherwise;{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      Your device’s camera, microphone, location or any other
                      facility as may be required for KYC purposes, and which
                      will be accessed only one time and only for KYC purposes;
                    </span>
                  </li>
                  <li>
                    <span>
                      Your preferences including settings such as time zone and
                      language;
                    </span>
                  </li>
                  <li>
                    <span>
                      IP addresses, application, device or browser type,
                      versions and configurations, operating systems, device
                      brand and model, time zone setting, content, unique
                      identifiers associated with Your device(s) and pages that
                      You access on the Platform, and the dates and times that
                      You visit the Platform, and paths taken;
                    </span>
                  </li>
                  <li>
                    <span>
                      Credit related information or assessment, credit
                      information (and income information (e.g., employment
                      contract, salary details, employer name, tax returns, bank
                      account statements, income statements, balance sheet,
                      cashflow statement), credit or score or reliability scores
                      provided by third parties such as Our Financiers,
                      financial institutions (including TransUnion CIBIL Limited
                      and other credit bureaus) or Account Aggregators or
                      similar third parties as permitted under applicable laws
                      of India including Our business partners, independent
                      service providers and Our group entities;
                    </span>
                  </li>
                  <li>
                    <span>
                      Financial information such as personal bank account
                      numbers and bank account data including ACH, NEFT, IMPS
                      and UPI ID details, salary and income details;
                    </span>
                  </li>
                  <li>
                    <span>
                      Know Your Customer (KYC) information including all proofs
                      of identity and address, photograph, Permanent Account
                      Number (PAN), etc.;
                    </span>
                  </li>
                  <li>
                    <span>
                      Details of Your visits to Platform, including but not
                      limited to, location data, web logs and other
                      communication data;
                    </span>
                  </li>
                  <li>
                    <span>
                      Information provided, uploaded, shared by You through Our
                      Platforms, including inter alia your PAN details, TAN
                      details, details of incorporation, government and business
                      related approvals/ consents/ licenses, tax certificates,
                      invoices, photographs, the users You follow, current and
                      prior purchase or browsing details, contact or
                      notification information, and other information in Your
                      account profile;
                    </span>
                  </li>
                  <li>
                    <span>
                      Your search details such as search terms you have looked
                      up and results You selected;
                    </span>
                  </li>
                  <li>
                    <span>
                      Your browsing details including time, frequency, features
                      used, etc. by You;
                    </span>
                  </li>
                  <li>
                    <span>
                      Communications between You and other Financiers,
                      merchants, and other third parties through Our Platforms;
                    </span>
                  </li>
                  <li>
                    <span>
                      If You avail any services through Our Platform, We may
                      collect and store information about You to process Your
                      requests and automatically complete forms for future
                      transactions, including (but not limited to) Your phone
                      number, address, email, billing information and bank
                      account information. This information may be shared with
                      third parties which assist in processing and fulfilling
                      Your requests, including PCI compliant payment gateway
                      processors, payment aggregators, other lenders, etc. When
                      You submit bank account information, We encrypt the
                      information using industry standard technologies. If You
                      write reviews about businesses/ services with which You
                      conduct transactions through Our Platforms, We may
                      publicly display information that You transacted with
                      those businesses/ services;
                    </span>
                  </li>
                  <li>
                    <span>
                      The information provided by You such as ratings, reviews,
                      tips, photos, comments, likes, bookmarks, friends, lists,
                      etc. to be published or displayed (hereinafter, "posted")
                      on publicly accessible areas of Our Platforms, or
                      transmitted to other Users of Our Platforms or third
                      parties (collectively, "User Inputs");
                    </span>
                  </li>
                  <li>
                    <span>
                      Information collected from third party business partners,
                      Our Financiers, Financial Organisations, lending service
                      providers, technical sub-contractors, analytics providers,
                      search information providers, etc. such as delivery
                      address or contact information or other details which may
                      be combined with the User Information collected on the
                      Platform and as provided in this Policy; and
                    </span>
                  </li>
                  <li>
                    <span>Information collected through cookies.</span>
                  </li>
                </ul>
                <p>
                  The User Information includes any Personal Information and
                  Sensitive Personal Information.
                </p>
                <p>
                  You acknowledge and expressly consent to the fact that certain
                  third-party service providers such as partners, merchants,
                  marketers, third-party Websites, researchers, financial
                  organisation, lending service providers, bureau and
                  verification agencies and other collection service providers
                  may also collect and Use Your User Information through the
                  Platform as per the terms of this Policy and the privacy
                  policies of such third-party service providers.
                </p>
                <p>
                  Where possible, We indicate which fields are mandatory and
                  which fields are optional to be filled on the Platform. You
                  always have the option to not provide information by choosing
                  not to submit particular information or feature on the
                  Platform.
                </p>
                <p>
                  Your User Inputs are posted on and transmitted to others at
                  Your own risk. Although We limit access to certain pages, You
                  may set certain privacy settings for such information through
                  Your account profile. Please be aware that no security/ data
                  protection measures are perfect or impenetrable. Additionally,
                  We cannot control the actions of other Users of Our Platform
                  with whom You may choose to share Your User contributions.
                  Therefore, We cannot and do not guarantee that Your User
                  inputs will not be viewed by unauthorized persons. We may
                  display this information on the Platform, share it with
                  businesses, and further distribute it to a wider audience
                  through third party sites and services. You should be careful
                  about revealing any sensitive details about Yourself in such
                  posting.
                </p>
                <p>
                  By using this Platform, You consent to the collection,
                  storage, and use of the User Information that You provide for
                  any of the services that We offer, and You consent to Our
                  collection of any changes or updates that You may provide to
                  the User Information. The Company collects only such User
                  Information that the Company believes to be relevant for the
                  purpose of identification and verification and is required to
                  understand You or Your interests or provide services that You
                  may require or request. We shall not be liable, for any reason
                  whatsoever, for the authenticity of any User information
                  provided by You to Us. You hereby represent, warrant and
                  confirm that the User Information provided by You is and shall
                  continue to be valid, true and accurate.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  SOURCES OF INFORMATION COLLECTION
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>We may collect User Information in the following ways:</p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      directly or indirectly from You when You provide it to Us;
                      and/or
                    </span>
                  </li>
                  <li>
                    <span>as You navigate through Our Platform; and/or</span>{' '}
                  </li>
                  <li>
                    <span>the public domain; and/or</span>
                  </li>
                  <li>
                    <span>
                      through independent third-party sources like Our
                      Financiers, merchants, financial organisations, credit
                      bureaus, our lending service providers, etc; and/or
                    </span>
                  </li>
                </ul>
                <p>
                  We collect User Information from You when You share Your
                  Personal and/or Sensitive Personal Information with Us, when
                  You apply for Our products or services directly with Us or
                  through Our third party partners as per their applicable
                  privacy policies, when You register with Us, when You provide
                  Your credit information to Us, when You enter a competition or
                  marketing survey, when You give Us information to collect
                  information through cookies, from publicly available sources
                  in accordance with applicable laws, or when You access, Use
                  and/or browse Our Platform. We may also obtain User
                  Information from independent third parties, as governed by
                  their respective privacy policies, including Our business
                  partners, merchants, marketplaces, re-sellers, banks, payment
                  partners, technology providers, and financial institutions,
                  fraud prevention agencies, independent service providers and
                  Our group entities, affiliates, associated Financiers etc. We
                  may further obtain User Information from social platforms and
                  networks.
                </p>
                <p>
                  when You give Us permission to do so either directly or
                  through a cookie, and We may Use, collect, process or disclose
                  this information in accordance with this Policy.
                </p>
                <p>
                  You provide Your consent for collection of Your information
                  from credit bureaus including but not limited to
                  Experian/CIBIL/ CRIF/other agencies, and for checking,
                  collecting retrieving and downloading Your information from
                  the Central KYC registry for Your cKYC ID linked with Your
                  PAN.{' '}
                </p>
                <p>
                  User Information may be collected automatically from the
                  computer or devices (including mobile devices and information
                  contained therein) You use to access the Platform, even if You
                  use the Platforms without registering or logging in and the
                  same shall include inter alia:{' '}
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      Your server’s IP address from where the Platform is being
                      accessed, the type of browser (Internet Explorer, Firefox,
                      Opera, Google Chrome etc.), the operating system of Your
                      system and the website You last visited before visiting to
                      Platform, and Your computer, Internet connection and
                      mobile device, other browsing information (connection,
                      speed, connection type etc.), device type, device’s unique
                      device identifier, mobile network information and the
                      device’s telephone number;
                    </span>
                  </li>
                  <li>
                    <span>
                      Details of Your use of Our Platform, including location
                      data, traffic data, logs and other communication data and
                      the resources that You use and access on or through Our
                      Platforms;{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      We may also collect real-time information about the
                      location of Your device, as permitted by You;
                    </span>
                  </li>
                  <li>
                    <span>
                      Other information relating to Your activity on the
                      Platforms, such as Your search queries, comments, domain
                      names, search results selected, number of clicks, pages
                      viewed and the order of those pages, how long You visited
                      Our Platforms, the date and time You used the Platforms,
                      error logs, and other similar information; and
                    </span>
                  </li>
                  <li>
                    <span>
                      In case of mobile application users, the online or offline
                      status of Your application.{' '}
                    </span>
                  </li>
                </ul>
                <p>
                  We access Your phone resources or metadata only in compliance
                  with applicable law. We only collect the User Information from
                  the sources above in accordance with applicable law. Other
                  tools and Cookies.
                </p>
                <p>
                  We, and third parties with whom We partner, shall use pixel
                  tags, cookies, web beacons, mobile device IDs, “flash cookies”
                  and similar files or technologies to collect and store
                  information in respect to Your use of and third party websites
                  and the Platforms.
                </p>
                <p>
                  When You visit the Platform, one or more cookies will be sent
                  to the computer/smartphone or other devices being Used by You
                  and We and third parties that We partner with may collect the
                  cookies. The cookies are either Used to improve the quality of
                  the Platform or for storing Your preferences as a
                  User,improving search results and tracking User trends. You
                  have the option of disabling cookies via Your browser
                  preferences. However, if You disable cookies on Your browser,
                  some parts of the Platform may not function effectively.
                </p>
                <p>
                  Information collected by cookies is anonymous information and
                  is maintained distinctly and is not linked to the User
                  Information You submit to Us.{' '}
                </p>
                <p>
                  You may encounter cookies or other similar devices on certain
                  pages of the Platform that are placed by third parties. We do
                  not control the Use of cookies by third parties and shall not
                  be liable for any reason whatsoever for these third party
                  cookies. We advise You to check their privacy policies for
                  information about third parties’ cookies and other practices.
                  We do not control the practices of such third parties/
                  partners and their privacy policies/ cookies policies which
                  govern their interactions with You.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  USE AND DISCLOSURE OF INFORMATION COLLECTED
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>Usages</p>
                <p>
                  You explicitly provide Your consent for Use of Your User
                  Information collected for the following purposes:
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>To verify and authenticate Your identity;</span>
                  </li>
                  <li>
                    <span>
                      To verify, authenticate, authorize Your Use of Our
                      products or services, including for any risk management or
                      portfolio management purposes;
                    </span>
                  </li>
                  <li>
                    <span>To facilitate Your usage of Platform;</span>
                  </li>
                  <li>
                    <span>To do internal risk assessments and analysis;</span>
                  </li>
                  <li>
                    <span>
                      To protect Our business and ensure compliance with law;
                    </span>
                  </li>
                  <li>
                    <span>
                      To facilitate Your access to and use of Our products and
                      services;
                    </span>
                  </li>
                  <li>
                    <span>
                      To connect You with our Financiers / merchants/ other
                      third parties/lending service providers;
                    </span>
                  </li>
                  <li>
                    <span>
                      To send You surveys and marketing communications that We
                      believe may be of interest to You including offers of any
                      third parties;
                    </span>
                  </li>
                  <li>
                    <span>To carry out creditworthiness checks;</span>
                  </li>
                  <li>
                    <span>
                      To analyse, conduct internal reviews, research, surveys
                      and understand Our Users, improve the content and features
                      of Our Platform and make special offers;
                    </span>
                  </li>
                  <li>
                    <span>To process and complete Your transactions</span>
                  </li>
                  <li>
                    <span>
                      To process and respond to Your queries/ requests;
                    </span>
                  </li>
                  <li>
                    <span>To inform You regarding changes in Platform;</span>
                  </li>
                  <li>
                    <span>
                      To diagnose technical problems, provide support and help
                      You in addressing troubleshoot problems;
                    </span>
                  </li>
                  <li>
                    <span>
                      To send and received communications, show advertisements,
                      notifications; p) To prepare reports, review, etc.;
                    </span>
                  </li>
                  <li>
                    <span>
                      To contact You regarding third party services, offers, to
                      understand Your preferences, requirements;
                    </span>
                  </li>
                  <li>
                    <span>
                      To permit You to participate in interactive features
                      offered through Our Platform;
                    </span>
                  </li>
                  <li>
                    <span>
                      To improve the content and protecting the integrity of the
                      Platform;
                    </span>
                  </li>
                  <li>
                    <span>
                      To respond to judicial process and provide information to
                      law enforcement agencies or in connection with an
                      investigation on matters related to public safety, as
                      permitted by law;
                    </span>
                  </li>
                  <li>
                    <span>
                      To detect and protect any error, hacking, fraud,
                      commercial and identity checks;
                    </span>
                  </li>
                  <li>
                    <span>
                      To otherwise manage Our relationship with You; and
                    </span>
                  </li>
                  <li>
                    <span>
                      Any other purpose as required in connection with the
                      Platform.
                    </span>
                  </li>
                </ul>
                <p>
                  You agree and acknowledge that collection of Your User
                  Information is necessary for the purposes above and the
                  collection of Your User Information is in furtherance of a
                  lawful purpose.
                </p>
                <p>Disclosures</p>
                <p>
                  You explicitly confirm and provide Your consent for disclosure
                  of Your User Information for various purposes including inter
                  alia:
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      For legal purposes, requirements, to comply with
                      applicable laws or when required by law enforcement,
                      government officials, fraud detection agencies, financial
                      institutions or other third parties and when We are
                      compelled to do so under applicable laws (such as via a
                      subpoena, court order, regulatory query or similar
                      procedure);
                    </span>
                  </li>
                  <li>
                    <span>
                      To Our affiliates, subsidiaries, group companies, related
                      parties and other assigns to allow them to provide support
                      services and technical services to Us and to receive some
                      of these services from them and to provide support
                      services and technical services to them; and to offer
                      their products and services to You including offering
                      combined products and services;
                    </span>
                  </li>
                  <li>
                    <span>
                      To Our partners, Financiers, merchants, contractors,
                      service providers and other third parties and persons who
                      are bound by contractual obligations to keep personal
                      information confidential and use it only for the purposes
                      for which We disclose it to them which are generally for
                      jointly creating and offer products and services or to
                      assist Us with Our business operations;{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      To Our associated entities, service providers, associated
                      Financiers and assigns for the purposes of using or
                      otherwise handling the KYC information received from the
                      Central KYC Registry;
                    </span>
                  </li>
                  <li>
                    <span>
                      To companies that We plan to merge with or entities that
                      We may acquire or be acquired by subject to the provisions
                      of applicable laws, in which situation We will require
                      that the new combined entity or the acquired/ acquiring
                      entity follows this Policy with respect to Your User
                      Information;{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      Any person for maintaining security, operating Platforms,
                      to enquire or protect from any fraud, hacking;
                    </span>
                  </li>
                  <li>
                    <span>
                      To third parties to enable them to market and offer their
                      products or services to You. We may also share Your User
                      Information with such third parties to assist them in
                      understanding Your interests, habits, and Usage patterns
                      for certain programs, content, services, advertisements,
                      promotions and such third parties may market and offer
                      their products and services to You based on this;{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      {' '}
                      For any other purpose in connection with the aforesaid
                    </span>
                  </li>
                  <li>
                    <span>
                      For enforcing or applying Our terms of use and other
                      agreements, including for billing and collection purposes;
                    </span>
                  </li>
                  <li>
                    <span>
                      {' '}
                      If the disclosure, in Our opinion, is necessary or
                      appropriate to protect Our rights, property, or safety,
                      customers or others. This will include exchanging
                      information with other companies and organizations for the
                      purposes of fraud protection and credit risk reduction;{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      To grow Our business/ Platform and/ or help Us serve You
                      and other customers better; and
                    </span>
                  </li>
                  <li>
                    <span>
                      Other than as prescribed under this Policy, we will not
                      disclose any User Information to any external organization
                      unless
                    </span>
                    <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                      <li>
                        <span>
                          we have obtained the consent of the User; or
                        </span>
                      </li>
                      <li>
                        <span>
                          we are required by law to provide such information.
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Your User Information which is collected by Us for a
                  particular purpose shall be Used for that purpose only and
                  shall not be Used for any other purpose without Your consent.
                  We may store Your User Information to the extent required for
                  Us to carry out Our operations, as provided herein, and for as
                  long as the same is required for the fulfilment of purposes
                  for which We collected it. The retention of User Information
                  by the Company is determined by considering compliance with
                  legal (contractual, statutory, or regulatory requirements),
                  accounting and compliance reporting requirements. We only
                  store Your User Information in servers located in India. We do
                  not store any biometric data.
                </p>
                <p>
                  However, when We disclose Your User Information to certain
                  third-party service providers, such third parties have their
                  own privacy policies in respect to the information We are
                  required to provide to them. For these third-party service
                  providers, We recommend that you read their privacy policies
                  to understand the manner in which Your User Information will
                  be handled by them. In particular, remember that certain
                  third-party service providers may be located in or have
                  facilities that are located in a different jurisdiction. So,
                  if You elect to proceed with a transaction that involves the
                  services of a third-party service provider, then Your
                  information may become subject to the laws of the
                  jurisdiction(s) in which such service provider is or its
                  facilities are located. Once You leave the Platform or are
                  redirected to a third-party website or application, You are no
                  longer governed by this Policy or the Platform’s Terms and
                  Conditions and in such circumstances the privacy policy of
                  such other website will apply. We will not be liable for any
                  acts or omissions of the third-party service provider.
                </p>
                <p>
                  Once disclosed as per the above, Your User Information will be
                  governed by the applicable third party’s privacy policy and
                  not by this Policy.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">MARKETING</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  In relation to the products and services provided by Us or by
                  third parties with whom We share Your User Information as per
                  this Policy, You may receive marketing communications from Us
                  or such other third parties, by messages, or email or through
                  other digital modes, on the Platform or such other platforms
                  including social media platforms, or on such third party
                  platforms websites and apps including social media platforms,
                  if You have provided Us Your User Information. We may market
                  Our own products or services including the products and
                  services offered by Our group companies, affiliates,
                  subsidiaries and the product and services being offered by
                  third parties Using such communication. The provision of such
                  marketing activities shall be in accordance with applicable
                  laws. For the marketing activities done by Us, We execute
                  contracts with Our merchants, service providers, banks or
                  other third parties. You are entitled to opt out from
                  receiving marketing communication from Us by writing to Us at{' '}
                  <a href="mailto:info@solfin.co.in">info@solfin.co.in</a> .
                  When You opt-out from receiving marketing communication from
                  Us, this does not, in any manner whatsoever, impact the
                  marketing communication You receive from other third parties
                  with whom We share Your User Information and we do not
                  guarantee or warrant that such third-parties will desist from
                  contacting You. You will be required to comply with the
                  procedures set out by such-third parties to opt out for any
                  marketing communication from them. We may also Use marketing
                  and communications in order to improve and customize the
                  content of Our ads, promotions and advertising that may be of
                  interest to You.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">THIRD PARTY LINKS</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  The Platform may contain links to other site, which may not
                  necessarily be under Our control. These third parties may
                  collect, store or otherwise process Your User Information. We
                  do not endorse or make any representations about third-party
                  Websites or services, and We are not responsible for any form
                  of transmission, whatsoever, received by You from any
                  third-party platform. Our Privacy Policy does not cover the
                  information You choose to provide to these third parties or
                  that is collected by these third parties. Under no
                  circumstances shall We be deemed to control or guarantee the
                  accuracy, integrity, or quality of the information, data,
                  text, software, sound, photographs, graphics, videos, cookies,
                  messages or other materials available on such platforms. We
                  strongly encourage You to read such third parties’ privacy
                  policies.
                </p>
                <p>
                  If You decide to access any of the third-party sites linked to
                  the Website, You do so entirely at Your own risk. Any links to
                  any of Our third-party partners is the responsibility of the
                  linking party, and We will not be responsible for notification
                  of any change in name or location of any information on the
                  Website.
                </p>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  REPRESENTATIONS AND WARRANTIES
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>You hereby represent to Us that:</p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      The User Information You provide to Us from time to time
                      is and shall be authentic, correct, current and updated
                      and You have all the rights, permissions and consents as
                      may be required to provide such information to Us; and
                    </span>
                  </li>
                  <li>
                    <span>
                      Your provision of the User Information to Us, and Our
                      consequent storage, collection, Usage, transfer, access or
                      processing of the same shall not be in violation of any
                      third-party agreement, laws, charter documents, judgments,
                      orders and decrees.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">DATA PROTECTION</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  To prevent any form of unlawful interception or misuse of User
                  Information, We use reasonable physical, electronic, and
                  managerial procedures to safeguard and secure the User
                  Information collected. We use reasonable secure and
                  technologically appropriate measures, in compliance with the
                  Information Technology Act, 2000 and the rules related thereto
                  to protect You against loss or misuse of Your User Information
                  including internal reviews of data collection, storage and
                  processing practices and other reasonable security measures
                  which are equivalent to security measures that Company uses to
                  protect its own confidential information.
                </p>
                <p>
                  However, no method of transmission over the internet or via
                  mobile device, or method of electronic storage, is 100% (one
                  hundred percent) secure. Therefore, while We strive to use
                  commercially acceptable means to protect Your personal
                  information, We cannot guarantee its absolute security. You,
                  hereby acknowledge and accept that providing personal and
                  commercial information is at Your own risk and that the
                  Company will not be held liable for any losses or damages
                  incurred.
                </p>
                <p>
                  Notwithstanding anything contained herein, We shall not be
                  liable or responsible for disclosure of your information due
                  to errors in transmission, unauthorized third-party access, or
                  other causes beyond Our control or forced measure or hacking.
                </p>
                <p>
                  You should not share Your user name, password, or other
                  security information relating to Your account with anyone. If
                  We receive instructions using Your user name and password, We
                  will consider that You have authorized the instructions. You
                  agree and confirm that Your User Information may be
                  transferred, processed and stored. You hereby confirm that You
                  have been made aware of the security measures undertaken by Us
                  and You expressly consent to Us storing, handling, using Your
                  User Information.
                </p>
                <p>
                  Further, You agree that You shall not engage in any activity
                  of data scraping or data crawling on the Platform and usage of
                  web crawling or web scrapping software for accessing the
                  Platform would be considered to be an unauthorised access.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  INTELLECTUAL PROPERTY RIGHTS
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  All content on Our Platform, including graphics, text, icons,
                  interfaces, audio clips, logos, images, reviews, comments and
                  software is the property of the Company and/or its content
                  suppliers and is protected by Indian and international
                  copyright laws and other applicable intellectual property
                  laws. Any use, including the reproduction, modification,
                  distribution, transmission, republication, display or
                  performance, of the content on this Platform can only be made
                  with the express written permission of the Company. All other
                  trademarks, brands and copyrights other than those belonging
                  to the Company, belong to their respective owners and are
                  their property. You shall not modify the paper or digital
                  copies of any materials You have printed off or downloaded in
                  any way on or from the Platform, and You must not use any
                  illustrations, photographs, video or audio sequences or any
                  graphics separately from any accompanying text. You must not
                  use any part of the content on Our Platform for commercial
                  purposes without obtaining a written licence to do so from Us
                  or Our licensors. If You print off, copy or download any part
                  of Our Platform in breach of the terms hereof, Your right to
                  use Our Platform will cease immediately and You shall, at our
                  option, return or destroy any copies of the materials you have
                  made.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">YOUR RIGHTS</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  We seek to provide reasonable assistance to cater to requests
                  from individuals regarding the processing of their User
                  Information and the right to amend their User Information and
                  withdraw permission to the processing of their User
                  Information. In accordance with applicable laws and as per Our
                  policies, more specifically provided below, You can exercise
                  the following rights:
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      Review, Correct or Rectify Your User Information: You can
                      ask Us to have inaccurate User Information We process
                      about You, reviewed, fixed or changed in the time frame as
                      specified in the applicable law.
                    </span>
                  </li>
                  <li>
                    <span>
                      Right to withdraw Your consent: You may withdraw a consent
                      to processing that You have given Us and prevent further
                      processing of Your User Information or restricting
                      disclosure of Your User Information to third parties. You
                      may also withhold Your consent prior to the collection and
                      processing of Your User Information. In such a scenario,
                      the Company may delete Your information (personal or
                      otherwise) or de-identify it so that it is anonymous and
                      not attributable to You.
                    </span>
                  </li>
                  <li>
                    <span>
                      Delete or forget Your User Information: You may request
                      that the User Information collected by Us, is deleted or
                      forgotten by Us.
                    </span>
                  </li>
                </ul>
                <p>
                  If You would like to exercise any of the rights above in
                  Clause (a), (b) or (c) You may request by submitting a request
                  with the subject “Data Deletion” at{' '}
                  <a href="mailto:info@solfin.co.in">info@solfin.co.in</a>.
                  These rights are limited in some situations, such as where We
                  are legally or otherwise bound to process or retain Your User
                  Information. Please note that this may also affect Our ability
                  to process or enable Your User Information and may therefore
                  lead to the discontinuation of those services or products for
                  which this User Information was being Used for, at Our sole
                  discretion.
                </p>
                <p>
                  Following an account deletion request/deletion of User
                  Information request, We delete Your User Information (as
                  specified in the Request Form), unless such User Information
                  must be retained due to legal or regulatory requirements. If
                  You request that We delete Your User Information or Your
                  account, we will work hard to honor all requests in a timely
                  manner.{' '}
                </p>
                <p>
                  Upon Your request, Your User Information will be deleted only
                  from Our Platform. We do not undertake any responsibility to
                  ensure deletion of the same by any third party to whom the
                  User Information has been disclosed in accordance with this
                  Policy, including but not limited to Our affiliates,
                  associates, lenders/financing partners, other third parties
                  etc. Your User Information, to the extent disclosed to such
                  third parties, will continue to be processed and governed by
                  the provisions of their respective privacy policies. To
                  clarify, exercising the rights above will only result in
                  deletion, withdrawal of consent etc., from our Platform and
                  not from any third parties. You will continue to be governed
                  by the privacy policies of third parties to the extent
                  applicable to You.
                </p>
                <p>
                  Please note that the withdrawal of Your consent does not in
                  any manner affect the lawfulness of processing of User
                  Information which was carried out based on such a consent
                  before its withdrawal.
                </p>
                <p>
                  If You have availed any credit facilities from Us, We shall
                  have the right to continue processing Your User Information as
                  per Our privacy policy, terms of use, and other contractual
                  arrangements between You and Us and withdrawing or revoking
                  consent on the Platform, does not affect processing or Use of
                  Your User Information by Us.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  COOKIES AND TRACKING TECHNOLOGIES
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  Our website may use cookies and similar tracking technologies
                  to enhance Your browsing experience and collect information
                  about how You use Our site. You can manage Your cookie
                  preferences through Your browser settings.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">YOUR CONSENT</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  By submitting Your personal information, You consent to the
                  use of that information by us as detailed in this policy. You
                  hereby expressly and irrevocably authorize the Company / its
                  partners / affiliates to collect, store, share, obtain and
                  authenticate any aspect of Your personal information / KYC
                  either directly or through any of the authorized agencies and
                  disclose such information to our agents /contractors / service
                  providers and to also use such information in the manner that
                  may be required by the Company/ its partners / affiliates
                  including for the purposes of its business and for such time
                  period as we may deem fit ,within the scope of this privacy
                  policy.
                </p>
                <p>
                  In this regard, You expressly and irrevocably authorize the
                  Company/ its partners / affiliates to collect, use, verify and
                  authenticate your personal identity information / KYC in any
                  manner as may be deemed fit.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  GOVERNING LAW OR DISPUTE RESOLUTION
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  This Policy shall be governed by and construed in accordance
                  with the Indian laws The courts at Gurgaon shall have
                  exclusive jurisdiction in relation to any disputes arising out
                  of or in connection with this Policy.
                </p>
                <p>
                  If any dispute arises between the Company and the User in
                  connection with or arising out of the validity,
                  interpretation, implementation or alleged breach of any
                  provision of the Policy, such dispute shall be referred to and
                  finally resolved by arbitration in accordance with the Indian
                  Arbitration and Conciliation Act, 1996 for the time being in
                  force, which rules are deemed to be incorporated by reference
                  in this clause. There shall be one (1) arbitrator and the seat
                  of the arbitration shall be Gurgaon, India.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  DISCLAIMER, FORCE MAJURE AND INDEMNITY
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  WITHOUT LIMITING THE FOREGOING, EVERYTHING ON THIS SITE, THE
                  CONTENT, SERVICES AND MATERIALS IN THE SITE ARE PROVIDED "AS
                  IS" AND ON AN "AS AVAILABLE" BASIS WITHOUT REPRESENTATIONS OR
                  WARRANTIES OF ANY KIND EITHER EXPRESS, IMPLIED OR STATUTORY.
                  WE DO NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR ENDORSEMENTS
                  REGARDING THE ACCURACY, RELIABILITY, USEFULNESS OR
                  COMPLETENESS OF THE SERVICES, CONTENT OR MATERIALS IN THE SITE
                  OR ANY SITE LINKED TO IT. TO THE MAXIMUM EXTENT PERMISSIBLE
                  PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES,
                  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE, NON INFRINGEMENT, DESIGN, ACCURACY,
                  CAPABILITY, SUFFICIENCY, SUITABILITY, CAPACITY, COMPLETENESS,
                  AVAILABILITY, COMPATIBILITY OR ARISING FROM COURSE OF DEALING
                  OR COURSE OF PERFORMANCE. WE DO NOT WARRANT THAT THE SITE OR
                  THE SERVICES, CONTENT, MATERIALS OR FUNCTIONS CONTAINED IN THE
                  SITE WILL BE CONTINUOUSLY AVAILABLE, UNINTERRUPTED OR
                  ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE,
                  SERVICES, CONTENT, MATERIALS OR THE SERVERS THAT MAKE THE SITE
                  OR SUCH SERVICES, CONTENT AND MATERIALS AVAILABLE ARE FREE OF
                  VIRUSES OR OTHER HARMFUL COMPONENTS OR ARE ACCURATE OR
                  COMPLETE. WE DO NOT WARRANT OR MAKE ANY REPRESENTATIONS
                  REGARDING THE USE OR THE RESULTS OF THE USE OF THE SERVICES,
                  CONTENT, MATERIALS, FUNCTIONS OR PRODUCTS AVAILABLE THROUGH
                  THE SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY,
                  OR OTHERWISE. you ASSUME THE ENTIRE COST OF ALL NECESSARY
                  SERVICING, REPAIR, OR CORRECTION. APPLICABLE LAW MAY NOT ALLOW
                  THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION
                  MAY NOT APPLY TO YOU.
                </p>
                <p>
                  Neither the Company and its affiliates, nor their or their
                  affiliates' officers, employees or agents shall be liable to
                  You or anyone else for any loss, damage or expense arising out
                  of any access to or use of Our Platform or any site linked to
                  it, including, without limitation, any loss of revenues or
                  profits, loss of business or loss of data, indirect,
                  incidental or consequential loss.
                </p>
                <p>
                  You agree to indemnify, save, and hold the Company, its
                  affiliates, contractors, employees, officers, directors,
                  agents, third party associates, licensors, and partners
                  harmless from any and all claims, demands, losses, damages,
                  and liabilities, costs and expenses, including without
                  limitation legal fees and expenses, arising out of or related
                  to Your use or misuse of the services or of the Website, any
                  violation by You of the Terms of this Privacy Policy, or any
                  breach of the representations, warranties, and covenants made
                  by You herein or Your infringement of any intellectual
                  property or other right of any person or entity, or as a
                  result of any material posted/ transmitted by You on the
                  Website including ones that are threatening, libelous,
                  obscene, harassing or offensive.
                </p>
                <p>
                  The Company reserves the right, at Your expense, to assume the
                  exclusive defense and control of any matter for which You are
                  required to indemnify the Company, including rights to settle,
                  and You agree to cooperate with the Company’s defense and
                  settlement of these claims. The Company will use reasonable
                  efforts to notify You of any claim, action, or proceeding
                  brought by a third party that is subject to the foregoing
                  indemnification upon becoming aware of it.
                </p>
                <p>
                  Notwithstanding anything contained in the policy, The Company
                  won’t be responsible for any loss or damage to You in case of
                  any Force Majeure Event, which is unforeseeable or not within
                  the control of the Company. A Force Majeure Event shall mean
                  any event that is beyond the reasonable control of the Company
                  and shall include, without limitation, sabotage, fire, flood,
                  explosion, acts of God, civil commotion, strikes or industrial
                  action of any kind, riots, insurrection, war, acts of
                  government, computer hacking, unauthorized access to computer,
                  computer system or computer network, computer crashes, breach
                  of security and encryption (provided beyond reasonable control
                  of the Company), power or electricity failure or
                  unavailability of adequate power or electricity.
                </p>
                <p>
                  You agree that no transmission on the internet can be said to
                  be fully secured, hence, the Company cannot ensure the
                  security of all the information provided by You and You do it
                  on Your own accord. The Company may entrust a third person
                  with the management affairs of personal information for
                  successful service operation and contract performance to
                  following entities, and entities and will manage the trustee
                  to protect Personal Information from theft, leak, loss,
                  falsification, harm in accordance with applicable laws and
                  regulations.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">CONTACT US</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  If You have any questions, concerns, or requests regarding
                  this Privacy Policy or the processing of Your personal
                  information, please contact us at: <strong>Phone:</strong>{' '}
                  <a href="tel:+919599446777">+91-9599446777</a>{' '}
                  <strong>Email:</strong>{' '}
                  <a href="mailto:info@dhanvikas.com">info@dhanvikas.com</a>
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">
                  CHANGES TO THIS PRIVACY POLICY
                </h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  We may update this Privacy Policy from time to time. Any
                  changes will be effective upon posting the revised Privacy
                  Policy on Our Platform. We encourage you to review this
                  Privacy Policy periodically to stay informed about how We
                  collect, use, and protect your personal information.
                </p>
                <p>
                  By using Our website or services after any changes to this
                  Privacy Policy, You acknowledge and agree to the revised
                  terms.
                </p>
              </div>
            </div>
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">DISCLAIMER</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  We make no warranties or representations about the accuracy or
                  completeness of Our Platform. You understand and expressly
                  agree that to the extent permitted under applicable laws, in
                  no event will the Company or any of its affiliates or group
                  companies or any of their respective officers, employees,
                  directors, shareholders, agents, or licensors be liable to You
                  or anyone else under any theory of liability (whether in
                  contract, tort, statutory, or otherwise) for any direct,
                  indirect, incidental, special, consequential or exemplary
                  damages, including but not limited to, damages for loss of
                  revenues, profits, goodwill, Use, data or other intangible
                  losses (even if such parties Were advised of, knew of or
                  should have known of the possibility of such damages),
                  resulting from: Your Use of the Platform or any parts thereof;
                  (ii) unauthorized access to or alteration of Your
                  transmissions or data; (iii) any other matter relating to the
                  services; including, without limitation, damages for loss of
                  Use, data or profits, arising out of or in any way connected
                  with availing of the services.
                </p>
                <p>
                  We have endeavoured to ensure that all the information
                  provided by Us and/or by third party service providers on the
                  Platform is correct, We neither warrant nor makes any
                  representations regarding the quality, accuracy or
                  completeness of any data or information displayed on the
                  Platform. We make no warranty, express or implied, concerning
                  the Platform and/or its contents and disclaim all warranties
                  of fitness for a particular purpose and warranties of
                  merchantability in respect of information displayed and
                  communicated through or on the Platform, including any
                  liability, responsibility or any other claim, whatsoever, in
                  respect of any loss, whether direct or consequential, to You
                  or any other person, arising out of or from the Use of any
                  such information as is displayed or communicated through or on
                  the Platform.
                </p>
                <p>
                  Notwithstanding anything to the contrary herein, You hereby
                  irrevocably waive any right or remedy to seek and/or obtain
                  injunctive or other equitable relief or any order with respect
                  to, and/or to enjoin or restrain or otherwise impair in any
                  manner, the production, distribution, exhibition or other
                  exploitation of the Company or any of its affiliate or group
                  company related project, or the Use, publication or
                  dissemination of any advertising in connection with such
                  project.
                </p>
                <p>
                  Your sole and exclusive remedy for any dispute with Us shall
                  be to discontinue the Use of the Platform and the services.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default XsPrivacyPolicy;
