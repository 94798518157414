const VideoPlayer = ({
  src,
  poster,
  className = '',
  videoId = 'BgVideo',
  autoPlay = true,
  muted = true,
  loop = true,
  playsInline = true,
  preload = 'auto',
  ...rest
}) => {
  return (
    <video
      className={className}
      id={videoId}
      poster={poster}
      preload="metadata"
      autoPlay={autoPlay}
      muted={muted}
      loop={loop}
      playsInline={playsInline}
      {...rest}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default VideoPlayer;
