import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const StackedBarChart = () => {
  const data = [
    {month: 'Apr 2019', Wind: 3.7, Solar: 4.2, Other: 2.0},
    {month: 'May 2019', Wind: 6.6, Solar: 4.3, Other: 1.5},
    {month: 'Jun 2019', Wind: 8.8, Solar: 3.9, Other: 1.2},
    {month: 'Jul 2019', Wind: 11.3, Solar: 3.4, Other: 1.4},
    {month: 'Aug 2019', Wind: 9.7, Solar: 3.6, Other: 1.6},
    {month: 'Sep 2019', Wind: 5.5, Solar: 3.6, Other: 1.9},
    {month: 'Oct 2019', Wind: 2.1, Solar: 3.9, Other: 2.8},
    {month: 'Nov 2019', Wind: 2.7, Solar: 3.9, Other: 2.8},
    {month: 'Dec 2019', Wind: 4.0, Solar: 3.9, Other: 2.6},
    {month: 'Jan 2020', Wind: 3.3, Solar: 4.6, Other: 1.8},
    {month: 'Feb 2020', Wind: 3.5, Solar: 5.1, Other: 1.4},
    {month: 'Apr 2021', Wind: 3.7, Solar: 6.1, Other: 1.4},
    {month: 'May 2021', Wind: 7.1, Solar: 6.0, Other: 2.4},
    {month: 'Jun 2021', Wind: 9.7, Solar: 6.0, Other: 2.1},
    {month: 'Jul 2021', Wind: 11.4, Solar: 5.2, Other: 2.4},
    {month: 'Aug 2021', Wind: 9.3, Solar: 5.4, Other: 2.1},
    {month: 'Sep 2021', Wind: 7.0, Solar: 5.4, Other: 2.1},
    {month: 'Oct 2021', Wind: 3.0, Solar: 6.2, Other: 2.0},
    {month: 'Nov 2021', Wind: 3.3, Solar: 5.0, Other: 2.9},
    {month: 'Dec 2021', Wind: 3.6, Solar: 5.9, Other: 3.3},
    {month: 'Jan 2022', Wind: 3.4, Solar: 6.6, Other: 3.1},
    {month: 'Feb 2022', Wind: 3.1, Solar: 7.3, Other: 2.9},
    {month: 'Mar 2022', Wind: 4.0, Solar: 8.3, Other: 3.3},
    {month: 'Apr 2022', Wind: 4.1, Solar: 8.3, Other: 2.7},
    {month: 'May 2022', Wind: 10.2, Solar: 8.8, Other: 1.9},
    {month: 'Jun 2022', Wind: 9.7, Solar: 8.3, Other: 1.5},
    {month: 'Jul 2022', Wind: 10.3, Solar: 7.0, Other: 1.9},
    {month: 'Aug 2022', Wind: 8.7, Solar: 7.4, Other: 2.1},
    {month: 'Sep 2022', Wind: 6.7, Solar: 8.2, Other: 2.1},
    {month: 'Oct 2022', Wind: 3.4, Solar: 8.7, Other: 2.1},
    {month: 'Nov 2022', Wind: 2.5, Solar: 8.0, Other: 2.9},
    {month: 'Dec 2022', Wind: 4.0, Solar: 8.2, Other: 3.4},
    {month: 'Jan 2023', Wind: 5.0, Solar: 9.3, Other: 3.4},
    {month: 'Feb 2023', Wind: 3.1, Solar: 9.6, Other: 2.9},
    {month: 'Mar 2023', Wind: 4.1, Solar: 10.2, Other: 2.8},
    {month: 'Apr 2023', Wind: 4.2, Solar: 10.3, Other: 2.1},
  ];

  const formatLabel = value => {
    return value.toFixed(1);
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{top: 20, right: 30, left: 20, bottom: 5}}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="month" />
        {/* <YAxis /> */}
        <Tooltip />
        <Legend />
        <Bar
          dataKey="Wind"
          stackId="a"
          fill="#004953"
          label={{position: 'top', formatter: formatLabel}}
        />
        <Bar
          dataKey="Solar"
          stackId="a"
          fill="#98C55B"
          label={{position: 'top', formatter: formatLabel}}
        />
        <Bar
          dataKey="Other"
          stackId="a"
          fill="#FFC01E"
          label={{position: 'top', formatter: formatLabel}}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
