import React from 'react';

const Termsandconditions = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12 mb-4">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    <span className="text-theme-green">Terms</span> and{' '}
                    <span className="text-theme-green">Conditions</span>
                  </h2>
                </div>
                <div className="mt-xl-4 mt-3">
                  <p>
                    XSFinance and Investment Private Limited (the “XSFinance”)
                    is a Company registered under the Companies Act 2013 and
                    engaged in the business of providing an end-to-end platform
                    to the Customers to deliver best in class solar projects
                    through a network of trusted EPC partners and in-house
                    financing/leasing arm.
                  </p>
                  <p>
                    Dhanvikas Fiscal Services Private Limited (subsidiary of
                    XSFinance and Investment Private Limited) is a Non-Banking
                    Financial Company not accepting public deposits (hereinafter
                    referred to as “Dhanvikas”). XSFinance is a Lending Service
                    Provider to Dhanvikas.
                  </p>
                  <p>
                    XSFinance and Dhanvikas together hereinafter referred to as
                    “Solfin”, “Company”, “We”, “Us” or “Our”.
                  </p>
                  <p>
                    XSFinance and Dhanvikas operates under the brand name
                    “Solfin”. Solfin inter-alia operates through a website i.e.
                    https://www.solfin.co.in, mobile applications and Google
                    assistant bot (the “Platform”). These terms and conditions
                    (“Terms”) shall govern the use or access to the Platform and
                    Services (as defined below). These Terms constitute a
                    binding and enforceable legal contract between the Company
                    and the User(s) of the Platform (“You”, “Your” or “User”).
                  </p>
                </div>
              </div>
              {/* <!-- /col-12 --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">GENERAL</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin aims to deliver a unified platform wherein Customers
                    desirous of implementing sustainable energy, solar roof top
                    and green projects can find EPC players, financing options
                    and benefits accruing to customers in a single place..
                    Further, Solfin is a platform facilitating the loan
                    transactions between the borrowers and Dhanvikas. All loan
                    applications are approved and sanctioned by Dhanvikas and
                    communicated upfront during Loan application.
                  </p>
                  <p>
                    The Platform provides access to an online platform bringing
                    together consumers, Dhanvikas, data partners and other
                    partners willing to abide by the Terms & Conditions herein.
                  </p>
                  <p>
                    In the event You wish to avail any of the Products displayed
                    on the Platform you will be required to provide certain
                    information and our representatives may contact You through
                    phone or email to understand Your requirements.
                  </p>
                  <p>
                    Solfin provides various services, including but not limited
                    to (“Services”):
                    <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                      <li>
                        <span>
                          Facilitating purchase of Solar Panels, Solar Projects;
                        </span>
                      </li>
                      <li>
                        <span>
                          Facilitating installation of Solar Panel/ Project
                          through verified EPC partners;
                        </span>
                      </li>
                      <li>
                        <span>
                          Assisting its Users to obtain finance from Dhanvikas
                          for purchase or installation of Solar Panels/
                          Projects;
                        </span>
                      </li>
                      <li>
                        <span>
                          In the event You have registered Your phone number on
                          the ‘Do Not Disturb’ registers with Your network
                          provider, You shall ensure to take all steps to enable
                          the Company’s representative’s to contact You via
                          phone to provide details about different financial
                          products and You shall ensure that such calls received
                          by You are pursuant to You providing Us with
                          information and You shall not register a compliant
                          with the relevant authorities for the same.
                        </span>
                      </li>
                    </ul>
                  </p>
                  <p>
                    For the provision of the above mentioned Services, Solfin
                    will be using and sharing the information of the User with
                    third parties, as may be required for the purpose mentioned
                    above and Solfin shall also run multiple credit checks to be
                    able to get the best available offers to suit the needs of
                    the Users. The collection, storing, use and transfer of
                    information shared by the User shall be governed in
                    accordance with the Privacy Policy available on the
                    Platform.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">ELIGIBILITY</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    The User of this Platform unequivocally declares and agrees
                    that the User is a natural/ legal person who has attained
                    the age of at least 21 years and is legally allowed to enter
                    into a contract in India. The User is allowed to utilize the
                    Services in accordance with the terms and conditions
                    detailed hereinafter. By registering on the Platform, the
                    User hereby undertakes to agree and abide by the Terms &
                    Conditions detailed herein. If the User violates any of
                    these Terms & Conditions, or otherwise violates an agreement
                    entered into through the medium of the Platform, Solfin may
                    terminate the User’s membership, delete his/her profile and
                    any content or information posted online by the User on the
                    Platform and / or prohibit the User from using or accessing
                    the Platform at any time in its sole discretion, with or
                    without notice, including without limitation if Solfin
                    believes that User is under the age of 21 years.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    ACCEPTANCE OF TERMS AND CONDITIONS
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    As a User of this Platform, you have agreed to the Terms &
                    Conditions provided hereunder or anywhere else on this
                    Platform including but not limited to disclaimers on this
                    Platform. You are advised to read and understand the said
                    Terms & Conditions and in case You do not wish to agree to
                    these Terms & Conditions, please refrain from using the
                    Platform.
                  </p>
                  <p>
                    Solfin reserves the right, at its sole discretion, to
                    change, modify, add or remove portions of these Terms and
                    Conditions, at any time. Any changes made to these Terms and
                    Conditions will be communicated to the email address of the
                    User maintained in our records, within a reasonable time
                    from the date of modification. Solfin does not take
                    responsibility in case the email address provided by the
                    User is either incorrect or does not belong to the user. The
                    User’s continued use of the Platform following the
                    notification of modification of these Terms and Conditions
                    will be construed to mean the acceptance by the User of the
                    modified Terms and Conditions.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">PRIVACY</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin collects certain information from You in order to
                    provide the Services. Solfin’s use of Your personal
                    information is governed in accordance with the Privacy
                    Policy.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">LICENSE AND ACCESS</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin grants You a limited license to access and use the
                    Platform for availing the Services, but not to download any
                    material from it (other than page caching) or modify it, or
                    any portion of it, except with express written consent of
                    Solfin and / or its affiliates, as may be applicable. Any
                    unauthorized access to the Platform or any networks, servers
                    or computer systems connected to Platform and any attempt to
                    modify, adapt, translate or reverse engineer any part of the
                    Platform or re-format or frame any portion of the pages of
                    the Platform, save to the extent expressly permitted by
                    these Terms & Conditions, is not permitted. This license is
                    non-transferable and does not permit any resale or
                    commercial use of this Platform or its contents; any
                    downloading or copying of account information for the
                    benefit of anyone other than Your use; or any use of data
                    mining, robots, or similar data gathering and extraction
                    tools. The Platform or any portion of the Platform
                    (including but not limited to any copyrighted material,
                    trademarks, or other proprietary information) may not be
                    reproduced, duplicated, copied, sold, resold, visited,
                    distributed or otherwise exploited for any commercial
                    purpose without express written consent of Solfin and / or
                    its affiliates, as may be applicable. Any unauthorized use
                    of the Platform shall terminate the permission or revoke the
                    license granted by Solfin.
                  </p>
                  <p>
                    You are permitted to use content delivered to You through
                    the Service only on the Service. You may not copy,
                    reproduce, distribute, or create derivative works from this
                    content. Further, You agree not to reverse engineer or
                    reverse compile any of the Service technology, including but
                    not limited to, any Java applets associated with the
                    Service.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    MONITORING OF THE PLATFORM AND YOUR ACCOUNT
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin has the right and liberty to monitor the content of
                    the Platform at all times which shall include information
                    provided in Your account. The monitoring of the Platform /
                    Platform is important to determine the veracity of the
                    information provided by You and that every User remains in
                    consonance with the Terms & Conditions provided herein.
                    Subject to the Terms & Conditions mentioned herein, Solfin
                    shall also have the liberty to remove any objectionable
                    content which is in contravention of the Terms & Conditions
                    herein or share such information with any governmental
                    authority as per procedures laid down by the law for the
                    time being in force in India.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">SERVICE SUSPENSION</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin may stop providing the Services and may terminate use
                    of it at any time without giving notice of termination to
                    You. Unless Solfin informs the User otherwise, upon any
                    termination,
                  </p>
                  <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                    <li>
                      <span>
                        the rights and licenses granted to You in these terms
                        will end.
                      </span>
                    </li>
                    <li>
                      <span>
                        User must stop using the Platform forthwith. Solfin
                        reserves the right to suspend or cease providing any
                        Service and shall have no liability or responsibility to
                        the User in any manner whatsoever if it chooses to do
                        so.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">SERVICES</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    The Platform allows You work out savings from using Our
                    Solar Calculator, find EPC partners in Your area and offer
                    financing options to You. Onboarding of EPC Players, DSA’s
                    and Premium Partners are also enabled though the website.
                  </p>
                  <p>
                    The Platform is a medium of bringing all relevant players to
                    the green, renewable, sustainable finance eco system
                    together.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">ON-BOARDING</h6>
                </div>
                <div className="para-text mt-3">
                  <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                    <li>
                      <span>
                        To avail the Services, You would be required to create a
                        profile/sign-up on the Website (“Profile”) using your
                        email ID and phone number, among other details. You
                        warrant that all information furnished in connection
                        with Your Profile is and shall remain accurate and true
                        in all respects. You further agree and undertake to
                        promptly update Your details on the Website in the event
                        of any change or modification of such details.
                      </span>
                    </li>
                    <li>
                      <span>
                        You are solely responsible for maintaining the security
                        and confidentiality of Your username and password and
                        agree to immediately notify the Company in writing of
                        any disclosure or unauthorized use of Your Profile or
                        any other breach of security with respect to Your
                        Profile.
                      </span>
                    </li>
                    <li>
                      <span>
                        You expressly agree to be liable and accountable for all
                        activities that take place through Your Profile in
                        furtherance of the use of the Website or the Service
                        provided or otherwise. The Company expressly excludes
                        any liability for any unauthorised access to Your
                        Profile.
                      </span>
                    </li>
                    <li>
                      <span>
                        You expressly agree to be liable and accountable for all
                        activities that take place through Your Profile in
                        furtherance of the use of the Website or the Service
                        provided or otherwise. The Company expressly excludes
                        any liability for any unauthorised access to Your
                        Profile.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    THIRD PARTY SERVICES
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <ul>
                    <li>
                      <span>
                        The Services may include services, content, documents,
                        and information owned by, licensed to, or otherwise made
                        available by a third party (“Third Party Services”) or
                        contain links to Third Party Services. You understand
                        that Third Party Services are the responsibility of the
                        third party that created or provided the services and
                        acknowledge that use of such Third-Party Services is
                        solely at Your own risk.
                      </span>
                    </li>
                    <li>
                      <span>
                        Solfin makes no representations and hereby expressly
                        excludes all warranties and liabilities arising out of
                        or pertaining to such Third-Party Services, including
                        the accuracy or completeness. Further, all intellectual
                        property rights in and to Third Party Services are the
                        property of the respective third parties.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    LIMITATION OF LIABILITY
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin does not hold any liability for any occurrence
                    arising from Your usage of Our Services resulting in any
                    financial, material or human damage. You understand and
                    agree that Solfin shall not be liable to You for any direct,
                    indirect, incidental, special, consequential or exemplary
                    damages, including without limitation damages for loss of
                    profits, goodwill, use, data or other intangible losses
                    (even if Company had been advised of the possibility of such
                    damages), resulting from or relating to the Websites or
                    Mobile application, whether based on warranty, contract,
                    tort, or any other legal theory.
                  </p>
                  <p>
                    Notwithstanding anything to the contrary contained herein or
                    elsewhere, Solfin’s total liability for any User’s claim
                    which may arise out of availing our services through
                    browsing Our Platform shall be limited up to the fees paid
                    by such User, if any, at the time of availing the Services
                    giving rise to such claim.
                  </p>
                  <p>
                    All claims/complaints arising from and in connection with
                    the use of Our Services shall be promptly submitted or
                    reported to Solfin and or/ its Partners within thirty (30)
                    days of the consumption of such Services. Any claim or
                    complaint that is submitted / reported after the expiry of
                    such 30 days may be rejected, and the claimant will forfeit
                    the right to claim any damage, cost or compensation.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    TERM AND TERMINATION
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                    <li>
                      <span>
                        These Terms shall remain in effect unless terminated in
                        accordance with the terms hereunder.
                      </span>
                    </li>
                    <li>
                      <span>
                        Solfin may terminate Your access to or use of the
                        Services, or any portion thereof, immediately and at any
                        point, at its sole discretion, if the user violates or
                        breaches any of its obligations, responsibilities, or
                        covenants under these Terms.
                      </span>
                    </li>
                    <li>
                      <span>
                        Upon termination these Terms shall terminate, except for
                        those clauses that expressly or are intended to survive
                        termination or expiry.
                      </span>
                    </li>
                    <li>
                      <span>
                        Notwithstanding anything to the contrary contained in
                        the Terms, upon termination of Your access to or use of
                        the Services, all amounts or outstanding monies due by
                        You in relation to Your use of or access to the Services
                        shall become immediately payable.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    DISCLAIMERS AND WARRANTIES
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                    <li>
                      <span>The use of the Services is at your sole risk.</span>
                    </li>
                    <li>
                      <span>
                        To the extent permitted by applicable law, the Services
                        are provided on an “as is” and “as available” basis.
                        Solfin does not warrant that operation of the Services
                        will be uninterrupted or error free or that the
                        functions contained in the Services will meet your
                        requirements.
                      </span>
                    </li>
                    <li>
                      <span>
                        To the fullest extent permissible under applicable law,
                        Solfin expressly disclaims all warranties of any kind,
                        express or implied, arising out of the Services,
                        including warranties of merchantability, fitness for a
                        particular purpose, satisfactory quality, accuracy,
                        title and non- infringement, compatibility,
                        applicability, usability, appropriateness, and any
                        warranty that may arise out of course of performance,
                        course of dealing, or usage of trade.
                      </span>
                    </li>
                    <li>
                      <span>
                        You hereby accept full responsibility for any
                        consequences that may arise from Your use of the
                        Services, and expressly agree and acknowledge that
                        Solfin shall have absolutely no liability with respect
                        to the same.
                      </span>
                    </li>
                    <li>
                      <span>
                        To the fullest extent permissible by law, Solfin, its
                        affiliates, and its related parties each disclaim all
                        liability to You for any loss or damage arising out of
                        or due to:
                        <ul>
                          <li>
                            <span>
                              Your use of or inability to use, or availability
                              or unavailability of the Services, including any
                              Third Party Services;
                            </span>
                          </li>
                          <li>
                            <span>
                              the occurrence or existence of any defect,
                              interruption, or delays in the operation or
                              transmission of information to, from, or through
                              the Services, communications failure, theft,
                              destruction or unauthorised access to Solfin’s
                              records, programs, services, server, or other
                              infrastructure relating to the Services; or
                            </span>
                          </li>
                          <li>
                            <span>
                              the failure of the Services to remain operational
                              for any period of time.
                            </span>
                          </li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span>
                        Notwithstanding anything to the contrary contained
                        herein, neither Solfin nor any of its affiliates or
                        related parties shall have any liability to You or any
                        third party for any indirect, incidental, special or
                        consequential damages or any loss of revenue or profits
                        arising under, directly or indirectly, or relating, in
                        any manner whatsoever, to these Terms or the Services.
                        To the maximum extent permitted by law, You agree to
                        waive, release, discharge, and hold harmless Solfin, its
                        affiliated and subsidiary companies, its parent
                        companies, and each of their directors, officers,
                        employees, and agents, from any and all claims, losses,
                        damages, liabilities, expenses and causes of action
                        arising out of the Services.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">INDEMNITY</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    You shall indemnify, defend at Solfin’s option, and hold
                    Solfin, its parent companies, subsidiaries, affiliates, and
                    their officers, associates successors, assigns, licensors,
                    employees, directors, agents, and representatives, harmless
                    from and against any claim, demand, lawsuits, judicial
                    proceeding, losses, liabilities, damages and costs
                    (including, without limitation, from all damages,
                    liabilities, settlements, costs and attorneys’ fees) due to
                    or arising out of your use of the Website or the Services
                    provided, including any violation of these Terms or any
                    infringement by you of any third party right or on account
                    of any third party who may use your account with Solfin.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">CONSENT TO USE DATA</h6>
                </div>
                <div className="para-text mt-3">
                  <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                    <li>
                      <span>
                        You agree that Solfin and any third-party service
                        providers it engages, may, in accordance with its
                        privacy policy, collect and use your information and
                        technical data and related information.
                      </span>
                    </li>
                    <li>
                      <span>
                        Solfin may use information and data pertaining to Your
                        use of the Services for analytics, trends
                        identification, and purposes of statistics to further
                        enhance the effectiveness and efficiency of the Website
                        and transfer the same to its group companies and service
                        providers in furtherance of Your access to these
                        Services. You provide Your consent to such use and
                        sharing of Your information.
                      </span>
                    </li>
                    <li>
                      <span>
                        Subject to applicable laws, Solfin may be directed by
                        law enforcement agencies or the government and related
                        bodies to disclose data in relation to users in
                        connection with criminal proceedings. You understand and
                        agree that in such instances, Solfin shall have the
                        right to share such data with relevant agencies or
                        bodies.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">FEES/CHARGES</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin reserves the right to charge convenience fee for the
                    Services and non-payment may result in denial of Services
                    and/or action under applicable law.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">MODIFICATION</h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    Solfin reserves the right at any time to add, modify or
                    discontinue, temporarily or permanently, the Services (or
                    any part thereof) with or without cause. Solfin shall not be
                    liable for any such addition, modification, suspension or
                    discontinuation of the Services.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              {/* <!-- /row --> */}

              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    JURISDICTION, GOVERNING LAWS, AND DISPUTE RESOLUTION
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <p>
                    These Terms shall be governed by and construed and enforced
                    in accordance with the laws of India. Subject to other
                    provisions in this Clause, courts in Haryana and / or Delhi
                    shall have exclusive jurisdiction over all issues arising
                    out of these Terms or the use of the Services.
                  </p>
                  <p>
                    Any controversies, conflicts, disputes, or differences
                    arising out of these Terms shall be resolved by arbitration
                    in Haryana and / or Delhi in accordance with the Arbitration
                    and Conciliation Act, 1996 for the time being in force,
                    which is deemed to be incorporated by reference in this
                    Clause. The tribunal shall consist of 1 (one) arbitrator
                    appointed by Solfin. The language of the arbitration shall
                    be English.
                  </p>
                  <p>
                    The parties to the arbitration shall keep the arbitration
                    confidential and not disclose to any person, other than on a
                    need to basis or to legal advisors, unless required to do so
                    by law. The decision of the arbitrator shall be final and
                    binding on all the Parties hereto.
                  </p>
                  <p>
                    Each party to the arbitration shall bear its own costs with
                    respect to any dispute.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-3">
                <div className="solfin-heading">
                  <h6 className="fw-bold heading-color">
                    MISCELLANEOUS PROVISIONS
                  </h6>
                </div>
                <div className="para-text mt-3">
                  <ul className="solfin-list list-lower-alpha list-unstyled ps-lg-4">
                    <li>
                      <span>
                        Modification – Solfin reserves the right at any time to
                        modify these Terms and to add new or additional terms or
                        conditions on use of the Services. Such modifications
                        and additional terms and conditions will be communicated
                        to You and, unless expressly rejected (in which these
                        Terms shall terminate), will be effective immediately
                        and will be incorporated into these Terms. In the event
                        You refuse to accept such changes, these Terms will
                        terminate.
                      </span>
                    </li>
                    <li>
                      <span>
                        Severability - If any provision of these Terms is
                        determined by any court or other competent authority to
                        be unlawful or unenforceable, the other provisions of
                        these Terms will continue in effect. If any unlawful or
                        unenforceable provision would be lawful or enforceable
                        if part of it were deleted, that part will be deemed to
                        be deleted, and the rest of the provision will continue
                        in effect (unless that would contradict the clear
                        intention of the clause, in which case the entirety of
                        the relevant provision will be deemed to be deleted).
                      </span>
                    </li>
                    <li>
                      <span>
                        Assignment - You shall not license, sell, transfer or
                        assign your rights, obligations, or covenants under
                        these Terms in any manner without Solfin’s prior written
                        consent. Solfin may grant or withhold this consent in
                        its sole discretion and subject to any conditions it
                        deems appropriate. Solfin may assign its rights to any
                        of its affiliates, subsidiaries, or parent companies, or
                        to any successor in interest of any business associated
                        with the Services without any prior notice to You.
                      </span>
                    </li>
                    <li>
                      <span>
                        Notices - All notices, requests, demands, and
                        determinations for Solfin under these Terms (other than
                        routine operational communications) shall be sent to{' '}
                        <a href="mailto:info@solfin.co.in">info@solfin.co.in</a>
                      </span>
                    </li>
                    <li>
                      <span>
                        Third Party Rights - No third party shall have any
                        rights to enforce any terms contained herein.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default Termsandconditions;
