import React, {useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation, useQuery} from 'react-query';
import * as querydata from '../../dataService/DataService';
import CustomerType from '../../components/CustomerType';
import StateSelect from '../../components/StateSelect';
import CitySelect from '../../components/CitySelect';
import GoogleMap from '../../../components/GoogleMap';
import {Tooltip} from 'react-tooltip';
import {toast} from 'react-toastify';

//client Validation
export const customerRegisterSchema = Yup.object({
  customerTypeId: Yup.number().required('Please select registrant type'),
  customerName: Yup.string().required('Please enter full name'),
  companyName: Yup.string(),
  // companyName: Yup.string().required("Please enter company name"),
  // companyName: Yup.string().test({
  //   name: "conditional-validation",
  //   test: function (value) {
  //     const customerTypeId = this.parent.customerTypeId;
  //     if (customerTypeId !== 1) {
  //       // Check if the value is not empty when the condition is met
  //       if (!value) {
  //         return this.createError({
  //           path: "companyName",
  //           message: "Please enter your company name",
  //         });
  //       }
  //     }
  //     return true;
  //   },
  // }),

  email: Yup.string().email().required('Please enter your email'),
  phone: Yup.string()
    .required('Mobile number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number'),
  pinCode: Yup.string()
    .required('PinCode is required')
    .length(6, 'Pincode must be exactly 6 digits')
    .matches(/^\d+$/, 'Pincode must only contain digits'),
  cityId: Yup.number().required('Please select city'),
  address1: Yup.string().required('Please enter your address'),
  //pan: Yup.string().required("Please enter your pan card number"),
  stateId: Yup.number()
    .notOneOf([-1], 'Please select state')
    .required('Please select state'),
  consumerNumber: Yup.string().required(
    'Please Enter your Electricity consumer number'
  ),
});

const Register = () => {
  const [geocords, setgeocords] = useState('');
  const [selectedState, setSelectedState] = useState('-1');
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [otpEditable, setOtpEditable] = useState(true);
  const [smsOtp, setSmsOtp] = useState('');
  const [isSmsOtpVerified, setIsSmsOtpVerified] = useState(false);
  const [smsOtpEditable, setSmsOtpEditable] = useState(true);

  const [showOtpDiv, setShowOtpDiv] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [otp, setOtp] = useState('');
  ///const [selectedCity, setSelectedCity] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [selectedCustomerTypeCode, setSelectedCustomerTypeCode] = useState('');
  //fetching query parameters for dsa
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encryptedDsaId = searchParams.get('t');
  //clear all fields
  const closeerrormessage = () => window.location.reload();

  //initialize Navigate method
  const navigate = useNavigate();

  // variable for file upload
  const [file, setfile] = useState('');
  const customerValues = JSON.parse(sessionStorage.getItem('customerValues'));
  // values defined for formik
  const initialValues = {
    customerTypeId: '',
    customerName: customerValues ? customerValues.customerName : '',
    companyName: '',
    email: customerValues ? customerValues.email : '',
    phone: customerValues ? customerValues.phone : '',
    pinCode: customerValues ? customerValues.pinCode : '',
    cityId: customerValues ? customerValues.cityId : '',
    address1: '',
    pan: '',
    isFinanceRequired: '1',
    stateId: customerValues ? customerValues.stateId : selectedState,
    googleCoordinates: '',
    consumerNumber: '',
    salutation: customerValues ? customerValues.salutation : '',
  };
  //set file and file Name
  // const handlefilechange = (e) => {
  //   setfile(e.target.files[0]);
  //   //console.log(e.target.files[0].name);
  //   // document.getElementById("filetextid").value = e.target.files[0].name;
  //   if(e.target.files[0]){
  //     document.getElementById("filetextid").value = e.target.files[0].name;
  //     error.file="";}else{
  //       document.getElementById("filetextid").value="";
  //       error.file="Please Upload Document";
  //     }
  // };
  // const handlefilechange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

  //   if (selectedFile) {
  //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //     // Check if the file format is allowed
  //     if (allowedFormats.includes(fileExtension)) {
  //       setfile(selectedFile);
  //       document.getElementById("filetextid").value = selectedFile.name;
  //       error.file = "";
  //     } else {
  //       setfile(null);
  //       document.getElementById("filetextid").value = "";
  //       error.file =
  //         "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
  //     }
  //   } else {
  //     setfile(null);
  //     document.getElementById("filetextid").value = "";
  //     error.file = "Please Upload Document";
  //   }
  // };
  const handlefilechange = e => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'png']; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      const fileSize = selectedFile.size;

      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        setfile(null);
        document.getElementById('filetextid').value = '';
        error.file =
          'Invalid file format. Allowed formats: ' + allowedFormats.join(', ');
      } else if (fileSize > maxFileSizeInBytes) {
        // Check if the file size exceeds the limit
        setfile(null);
        document.getElementById('filetextid').value = '';
        error.file = 'File size exceeds the limit of 2 MB.';
      } else {
        // The file format and size are allowed, so set it in the state
        setfile(selectedFile);
        document.getElementById('filetextid').value = selectedFile.name;
        error.file = '';
      }
    } else {
      setfile(null);
      document.getElementById('filetextid').value = '';
      error.file = 'Please Upload Document';
    }
  };

  //build Model and fill data in formik model
  const {values, handleBlur, handleChange, handleSubmit, errors, touched} =
    useFormik({
      initialValues: initialValues,
      validationSchema: customerRegisterSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        formData.append('customerTypeId', values.customerTypeId);
        formData.append(
          'customerName',
          values.salutation + ' ' + values.customerName
        );
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('googleCoordinates', geocords);
        formData.append('pinCode', values.pinCode);
        formData.append('cityId', values.cityId);
        formData.append('address1', values.address1);
        formData.append('pan', values.pan);
        formData.append('isFinanceRequired', values.isFinanceRequired);
        formData.append('billImage', file);
        formData.append('consumerNumber', values.consumerNumber);
        formData.append('encryptedDsaId', encryptedDsaId);
        //company name
        if (
          !['INDIVIDUAL', 'HUF', 'OTHERS'].includes(selectedCustomerTypeCode)
        ) {
          formData.append('companyName', values.companyName);
        }
        console.log('formData');
        console.log(formData);
        updateStatusMutation.mutate(formData);
        action.resetForm();
      },
    });
  const [error, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!errors.customerTypeId && !touched.customerTypeId) {
      if (!values.customerTypeId || values.customerTypeId === -1) {
        newErrors.customerTypeId = 'Please select registrant type';
        toast.error('Please select registrant type');
        document.getElementById('RegistantsType').focus();
      }
    } else {
      if (!values.customerTypeId || values.customerTypeId === -1) {
        toast.error('Please select registrant type');
        touched.customerTypeId = 'Please select registrant type';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('RegistantsType').focus();
      }
    }
    if (!errors.customerName && !touched.customerName) {
      if (!values.customerName) {
        newErrors.customerName = 'Please enter full name';
        toast.error('Please enter full name');
        document.getElementById('FullName').focus();
      }
    } else {
      if (!values.customerName) {
        toast.error('Please enter full name');
        touched.customerName = 'Please enter full name';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('FullName').focus();
      }
    }
    //company name
    if (
      values.customerTypeId &&
      !['INDIVIDUAL', 'HUF', 'OTHERS'].includes(selectedCustomerTypeCode)
    ) {
      if (!errors.companyName && !touched.companyName) {
        if (!values.companyName) {
          newErrors.companyName = 'Please enter your company name';
          toast.error('Please enter your company name');
          document.getElementById('CompanyName').focus();
        }
      } else {
        if (!values.companyName) {
          toast.error('Please enter your company name');
          touched.companyName = 'Please enter your company name';
          newErrors.companyName = 'Please enter your company name';
          document.getElementById('CompanyName').focus();
        }
      }
    }

    if (!errors.consumerNumber && !touched.consumerNumber) {
      if (!values.consumerNumber) {
        newErrors.consumerNumber =
          'Please Enter your Electricity consumer number';
        toast.error('Please Enter your Electricity consumer number');
        document.getElementById('elec_consumer_number').focus();
      }
    } else {
      if (!values.consumerNumber) {
        toast.error('Please Enter your Electricity consumer number');
        touched.consumerNumber =
          'Please Enter your Electricity consumer number';
        newErrors.customerNames =
          'Please Enter your Electricity consumer number';
        document.getElementById('elec_consumer_number').focus();
      }
    }

    if (!errors.email && !touched.email) {
      if (!values.email) {
        newErrors.email = 'Please enter your email';
        toast.error('Please enter your email');
        document.getElementById('Email').focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        newErrors.email = 'Invalid email format';
        toast.error('Invalid email format');
        document.getElementById('Email').focus();
      }
    } else {
      if (!values.email) {
        toast.error('Please enter your email');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Email').focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        toast.error('Invalid email format');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Email').focus();
      }
      touched.email = 'Please enter your email';
    }

    if (!errors.address1 && !touched.address1) {
      if (!values.address1) {
        newErrors.address1 = 'Please enter your address';
        toast.error('Please enter your address');
        document.getElementById('Address').focus();
      }
    } else {
      if (!values.address1) {
        toast.error('Please enter your address');
        touched.address1 = 'Please enter your address';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Address').focus();
      }
    }

    if (!errors.phone && !touched.phone) {
      if (values.phone.length === 0) {
        newErrors.phone = 'Mobile number is required';
        toast.error('Mobile number is required');
      } else if (!/^\d{10}$/.test(values.phone)) {
        newErrors.phone = 'Invalid mobile number';
        toast.error('Invalid mobile number');
        document.getElementById('Phone').focus();
      }
    } else {
      if (values.phone.length === 0) {
        toast.error('Mobile number is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Phone').focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        toast.error('Invalid mobile number');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Phone').focus();
      }
      touched.phone = 'Mobile number is required';
    }

    if (!errors.pinCode && !touched.pinCode) {
      if (!values.pinCode) {
        newErrors.pinCode = 'PinCode is required';
        toast.error('PinCode is required');
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        newErrors.pinCode = 'Pincode must only contain digits';
        toast.error('Pincode must only contain digits');
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length !== 6) {
        newErrors.pinCode = 'Pincode must be exactly 6 digits';
        toast.error('Pincode must be exactly 6 digits');
        document.getElementById('Pincode').focus();
      }
    } else {
      if (!values.pinCode) {
        toast.error('PinCode is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        toast.error('Pincode must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length !== 6) {
        toast.error('Pincode must be exactly 6 digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      }
      touched.pinCode = 'PinCode is required';
    }

    if (!errors.stateId && !touched.stateId) {
      if (values.stateId === -1) {
        newErrors.stateId = 'Please select State';
        toast.error('Please select State');
        document.getElementById('State').focus();
      }
    } else {
      if (values.stateId === -1) {
        toast.error('Please select States');
        touched.stateId = 'Please select States';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('State').focus();
      }
    }
    // if (!values.pan || values.pan.length==0) {
    //   newErrors.pan = 'Please Upload Pan File';
    //   toast.error("Please Upload Pan File");
    // }

    if (values.isFinanceRequired !== '0') {
      if (!errors.pan && !touched.pan) {
        if (!values.pan) {
          newErrors.pan = 'Please enter your pan card number';
          toast.error('Please enter your pan card number');
          document.getElementById('PANNumber').focus();
        }
      } else {
        if (!values.pan) {
          toast.error('Please enter your pan card number');
          touched.pan = 'Please enter your pan card number';
          newErrors.customerNames = 'Please enter full name';
          document.getElementById('PANNumber').focus();
        }
      }
    }

    if (!file || file.length === 0) {
      newErrors.file = 'Please Upload Electricity Bill';
      toast.error('Please Upload Electricity Bill');
      document.getElementById('filetextid').focus();
    }
    if (!errors.cityId && !touched.cityId) {
      if (!values.cityId) {
        newErrors.cityId = 'Please select City';
        toast.error('Please select City');
        document.getElementById('City').focus();
      }
    } else {
      if (!values.cityId) {
        toast.error('Please select City');
        touched.cityId = 'Please select City';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('City').focus();

        // newErrors.city_Id = 'Please select City';
      }
    }
    return newErrors;
  };
  const sendOTP = event => {
    event.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      // Submit form data or perform further actions here

      // setShowOtpDiv(true);
      //hide form logic was here before
      const sendOtpData = {
        email: values.email,
        phone: values.phone,
      };
      sendOtp.mutate(sendOtpData);
    } else {
      setErrors(newErrors);
    }

    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   const phoneRegex = /^[0-9]{10}$/;

    //   if (!values.customerTypeId || !values.customerName || !values.email || !values.phone || !values.pinCode || !values.cityId || !values.address1 || !values.pan || !values.consumerNumber || !values.isFinanceRequired) {
    //     toast.error("Please fill all the required fields");
    //     return;
    //   }
    //   if (!values.email || !emailRegex.test(values.email)) {
    //     toast.error("Please fill a valid email");
    //     return;
    //   }

    //  else if (!values.phone || !phoneRegex.test(values.phone)) {
    //     toast.error("Please fill a valid 10-digit phone number");
    //     return;
    //   }
    // else{

    //   setEditable(false);
    //   setShowOtpDiv(true);
    //   const sendOtpData = {
    //     email: values.email,
    //     phone: values.phone,
    //   };
    //   sendOtp.mutate(sendOtpData);
    // }
  };

  const cokiesubmit = event => {
    event.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      // Submit form data or perform further actions here
      handleSubmit(event);
    } else {
      setErrors(newErrors);
    }
  };

  const sendOtp = useMutation(querydata.sendOtpRequest, {
    onError: error => {
      toast.error(error.message);
      setEditable(true);
    },
    onSuccess: data => {
      setShowOtpDiv(true);
      setEditable(false);
      const hideform = document.getElementsByClassName('hideform');
      // Loop through the elements and hide each one
      for (let i = 0; i < hideform.length; i++) {
        hideform[i].style.display = 'none';
      }
      toast.success('OTP Sent. Please Check your Email and Phone');
      // console.log("Succesful",{data});
    },
  });

  const handleOtpChange = event => {
    const emailOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;
    if (otpRegex.test(emailOTP)) {
      setOtp(emailOTP);
      if (emailOTP.length === 6) {
        verifyEmailOtp.mutate(emailOTP);
      }
    }
  };

  const smsOtpChange = event => {
    const smsOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;

    if (otpRegex.test(smsOTP)) {
      setSmsOtp(smsOTP);
      if (smsOTP.length === 6) {
        verifySmsOtp.mutate(smsOTP);
      }
    }
  };
  const verifySmsOtp = useMutation(querydata.verifySmsOTP, {
    onError: error => {
      toast.error('Incorrect SMS OTP');
    },
    onSuccess: data => {
      toast.success('SMS OTP Verified Successfully');
      setIsSmsOtpVerified(true);
      setSmsOtpEditable(false);
    },
  });

  const verifyEmailOtp = useMutation(querydata.verifyEmailOTP, {
    onError: error => {
      toast.error('Incorrect Email OTP');
    },
    onSuccess: data => {
      toast.success('Email OTP Verified Successfully');
      setIsOtpVerified(true);
      setOtpEditable(false);
      // console.log("Succesful",{data});
    },
  });

  const handleEdit = () => {
    setEditable(true); // Enable form editing when the edit button is clicked.
    setShowOtpDiv(false); // Hide OTP div on edit button click
    setOtp('');
    setSmsOtp('');
    setIsOtpVerified(false);
    setIsSmsOtpVerified(false);
    setOtpEditable(true);
    setSmsOtpEditable(true);
    const hideform = document.getElementsByClassName('hideform');
    // Loop through the elements and hide each one
    for (let i = 0; i < hideform.length; i++) {
      hideform[i].style.display = 'block';
    }
  };

  //form post
  const updateStatusMutation = useMutation(querydata.insertCustomer, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess: data => {
      setIsLoading(false);

      if (data.isExistingCustomer) {
        navigate('/cust-registered', {
          state: {isFinanceRequired: values.isFinanceRequired},
        });
      } else {
        navigate('/cust-registration-complete', {
          state: {isFinanceRequired: values.isFinanceRequired},
        });
      }
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  // console.log("selectedCustomerTypeCode");
  // console.log(selectedCustomerTypeCode);
  // console.log("values");
  // console.log(values);
  return (
    <>
      <section>
        {/* <form className="m-5" onSubmit={handleSubmit}> */}
        <div className="saving-calculator header-space pt-1">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="saving-header mb-2 d-flex align-items-center justify-content-between">
                  <h5 className="fw-bold">Get me Solar </h5>
                </div>
              </div>
              <div className="col-lg-8 pe-xl-5 ">
                <div className="row hideform">
                  <div className="col-md-6">
                    <div className="form-group pb-2 mb-3">
                      <label htmlFor="RegistantsType" className="mb-2">
                        Registrant Type
                      </label>
                      <CustomerType
                        parameter={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={!editable}
                        setSelectedCustomerTypeCode={
                          setSelectedCustomerTypeCode
                        }
                      />
                      {errors.customerTypeId && touched.customerTypeId ? (
                        <p className="form-error text-danger 2">
                          {errors.customerTypeId}
                        </p>
                      ) : error.customerTypeId ? (
                        <p className="form-error text-danger 1">
                          {error.customerTypeId}
                        </p>
                      ) : null}
                    </div>
                    {/* <!-- /form-group --> */}
                  </div>
                </div>
                {/* <!-- /row --> */}

                <div className="registration-form">
                  <form id="customerform">
                    <div className="saving-calculate-form registration-form hideform">
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="heading mb-4">
                        <span className="fw-semibold saving-title">
                          Personal Details
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <label
                              htmlFor="FullName"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">Full Name</span>
                              <div className="d-flex user-name-with-salutation">
                                <select
                                  id="Salutation"
                                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                  name="salutation"
                                  value={values.salutation}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!editable}
                                >
                                  <option value="">Select</option>
                                  <option value="Mr.">Mr.</option>
                                  <option value="Ms.">Ms.</option>
                                  <option value="Mrs.">Mrs.</option>
                                </select>
                                <input
                                  type="text"
                                  id="FullName"
                                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                  placeholder="Minimum four characters"
                                  name="customerName"
                                  value={values.customerName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!editable}
                                />
                              </div>
                            </label>
                            {errors.customerName && touched.customerName ? (
                              <p className="form-error text-danger">
                                {errors.customerName}
                              </p>
                            ) : error.customerName ? (
                              <p className="form-error text-danger">
                                {error.customerName}
                              </p>
                            ) : null}
                            {/* {errors.customerName && touched.customerName ? (
                            <p className="form-error text-danger">
                              {errors.customerName}
                            </p>
                          ) : null} */}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <label
                              htmlFor="Email"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">Email</span>
                              <input
                                type="email"
                                id="Email"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Email Address"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              />
                            </label>
                            {errors.email && touched.email ? (
                              <p className="form-error text-danger">
                                {errors.email}
                              </p>
                            ) : error.email ? (
                              <p className="form-error text-danger">
                                {error.email}
                              </p>
                            ) : null}
                            {/* {errors.email && touched.email ? (
                            <p className="form-error text-danger">
                              {errors.email}
                            </p>
                          ) : null} */}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <label
                              htmlFor="Phone"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">Phone</span>
                              <input
                                type="tel"
                                id="Phone"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Phone Number"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              />
                            </label>
                            {errors.phone && touched.phone ? (
                              <p className="form-error text-danger">
                                {errors.phone}
                              </p>
                            ) : error.phone ? (
                              <p className="form-error text-danger">
                                {error.phone}
                              </p>
                            ) : null}
                            {/* {errors.phone && touched.phone ? (
                            <p className="form-error text-danger">
                              {errors.phone}
                            </p>
                          ) : null} */}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>

                        {values.customerTypeId &&
                        !['INDIVIDUAL', 'HUF', 'OTHERS'].includes(
                          selectedCustomerTypeCode
                        ) ? (
                          <div className="col-xl-4 col-md-6">
                            <div className="form-group mb-4">
                              <label
                                htmlFor="CompanyName"
                                className="border rounded-4 w-100 position-relative"
                              >
                                <span className="fw-bold">
                                  Organisation Name
                                </span>

                                <input
                                  type="text"
                                  id="CompanyName"
                                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                  placeholder="Enter Name"
                                  name="companyName"
                                  value={values.companyName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!editable}
                                />
                              </label>
                              {errors.companyName && touched.companyName ? (
                                <p className="form-error text-danger">
                                  {errors.companyName}
                                </p>
                              ) : error.companyName ? (
                                <p className="form-error text-danger">
                                  {error.companyName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className="heading mb-4 pt-lg-1">
                        <span className="fw-semibold saving-title">
                          Location Details
                        </span>
                      </div>
                      <div className="row">
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <label
                              htmlFor="Pincode"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">Pincode</span>
                              <input
                                type="text"
                                id="Pincode"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Area Pincode"
                                name="pinCode"
                                value={values.pinCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              />
                            </label>
                            {errors.pinCode && touched.pinCode ? (
                              <p className="form-error text-danger">
                                {errors.pinCode}
                              </p>
                            ) : error.pinCode ? (
                              <p className="form-error text-danger">
                                {error.pinCode}
                              </p>
                            ) : null}
                            {/* {errors.pinCode && touched.pinCode ? (
                            <p className="form-error text-danger">
                              {errors.pinCode}
                            </p>
                          ) : null} */}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <StateSelect
                              values={values}
                              setSelectedState={setSelectedState}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              disabled={!editable}
                            />
                            {errors.stateId && touched.stateId ? (
                              <p className="form-error text-danger">
                                {errors.stateId}
                              </p>
                            ) : error.stateId ? (
                              <p className="form-error text-danger">
                                {error.stateId}
                              </p>
                            ) : null}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                        <div className="col-xl-4 col-md-6">
                          <div className="form-group mb-4">
                            <CitySelect
                              parameter={values}
                              selectedState={selectedState}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              disabled={!editable}
                              isregister={true}
                              customerValues={customerValues}
                            />
                            {errors.cityId && touched.cityId ? (
                              <p className="form-error text-danger">
                                {errors.cityId}
                              </p>
                            ) : error.city_Id ? (
                              <p className="form-error text-danger">
                                {error.city_Id}
                              </p>
                            ) : !errors.cityId &&
                              error.city_Id &&
                              touched.cityId ? (
                              <p className="form-error text-danger">
                                Please select a cities
                              </p>
                            ) : null}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                        <div className="col-lg-12">
                          <div className="form-group mb-4">
                            <label
                              htmlFor="Address"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">Address</span>
                              <input
                                id="Address"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Type Your Address"
                                name="address1"
                                value={values.address1}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              />
                            </label>
                            {errors.address1 && touched.address1 ? (
                              <p className="form-error text-danger">
                                {errors.address1}
                              </p>
                            ) : error.address1 ? (
                              <p className="form-error text-danger">
                                {error.address1}
                              </p>
                            ) : null}
                            {/* {errors.address1 && touched.address1 ? (
                            <p className="form-error text-danger">
                              {errors.address1}
                            </p>
                          ) : null} */}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-12 --> */}
                        <div className="col-12">
                          <div className="mark-location-map fw-semibold mt-lg-1">
                            <span>Mark Location on Map</span>{' '}
                            <a
                              className="info icon-info-sign"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Click on Satellite button on the top right corner of the Map and check mark Labels to view names of places"
                            >
                              <i className="fa fa-circle-info"></i>
                            </a>
                            <Tooltip id="my-tooltip" />
                            <div
                              className="map-location mt-3"
                              id="contentContainer"
                            >
                              <GoogleMap
                                pinCode={values.pinCode}
                                address1={values.address1}
                                values={values}
                                setgeocords={setgeocords}
                                disabled={!editable}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- /row --> */}
                    </div>
                    {/* <!-- /saving-calculate-form --> */}
                    <div className="saving-calculate-form mt-3 hideform">
                      <div className="row">
                        <div className="form-group mt-3 ps-xl-4">
                          <div className="financing-required-form checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                            <div className="financing-required medium-title">
                              <span className="fw-bold">
                                Financing required?
                              </span>
                            </div>
                            <label
                              htmlFor="Yes"
                              className="checkboxes-view p-0"
                            >
                              <div className="check-now position-relative d-flex align-items-center">
                                <input
                                  type="radio"
                                  defaultChecked
                                  id="Yes"
                                  className="position-absolute top-0"
                                  name="isFinanceRequired"
                                  value={1}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!editable}
                                />
                                <div className="now-check rounded-pill position-relative"></div>
                                <span>Yes</span>
                              </div>
                              {/* <!-- /check-now --> */}
                            </label>
                            <label htmlFor="No" className="checkboxes-view p-0">
                              <div className="check-now position-relative d-flex align-items-center">
                                <input
                                  type="radio"
                                  id="No"
                                  className="position-absolute top-0"
                                  name="isFinanceRequired"
                                  value={0}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={!editable}
                                />
                                <div className="now-check rounded-pill position-relative"></div>
                                <span>No</span>
                              </div>
                              {/* <!-- /check-now --> */}
                            </label>
                          </div>
                          {/* <!-- /checkbox-labels --> */}
                        </div>
                      </div>
                      <div className="row">
                        {values.isFinanceRequired === '1' ? (
                          <>
                            <div className="col-xl-6">
                              <div className="form-group mb-4">
                                <label
                                  htmlFor="PANNumber"
                                  className="border rounded-4 w-100 position-relative"
                                >
                                  <span className="fw-bold">PAN Number</span>
                                  <input
                                    id="PANNumber"
                                    className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                    placeholder="Type PAN Number"
                                    name="pan"
                                    value={values.pan}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={!editable}
                                  />
                                </label>
                                {errors.pan && touched.pan ? (
                                  <p className="form-error text-danger">
                                    {errors.pan}
                                  </p>
                                ) : error.pan ? (
                                  <p className="form-error text-danger">
                                    {error.pan}
                                  </p>
                                ) : null}
                                {/* {errors.pan && touched.pan ? (
                            <p className="form-error text-danger">
                              {errors.pan}
                            </p>
                          ) : null} */}
                              </div>
                              {/* <!-- /form-group --> */}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        {/* <!-- /col-lg-4 col-md-6 --> */}

                        <div className="col-xl-6">
                          <div className="form-group mb-4">
                            <div className="border rounded-4 w-100 position-relative label-form">
                              <span className="fw-bold">
                                Upload Latest Electricity Bill
                              </span>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="filename"
                                  id="filetextid"
                                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                  placeholder="Upload Bill"
                                  readOnly
                                />
                                <span className="input-group-btn unit-label upload-docs position-absolute">
                                  <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                                    <input
                                      type="file"
                                      // onChange="this.form.filename.value = this.files.length ? this.files[0].name : ''" />
                                      name="file"
                                      onChange={handlefilechange}
                                      disabled={!editable}
                                    />
                                    Upload
                                  </div>
                                </span>
                              </div>
                            </div>
                            {errors.file && touched.file ? (
                              <p className="form-error text-danger">
                                {errors.file}
                              </p>
                            ) : error.file ? (
                              <p className="form-error text-danger">
                                {error.file}
                              </p>
                            ) : null}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}

                        <div className="col-xl-6">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="elec_consumer_number"
                              className="border rounded-4 w-100 position-relative"
                            >
                              <span className="fw-bold">
                                Electricity Consumer Number
                              </span>
                              <input
                                type="text"
                                id="elec_consumer_number"
                                name="consumerNumber"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="Type Electricity Consumer Number"
                                value={values.consumerNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!editable}
                              />
                            </label>
                            {errors.consumerNumber && touched.consumerNumber ? (
                              <p className="form-error text-danger">
                                {errors.consumerNumber}
                              </p>
                            ) : error.consumerNumber ? (
                              <p className="form-error text-danger">
                                {error.consumerNumber}
                              </p>
                            ) : null}
                            {/* {errors.consumerNumber && touched.consumerNumber ? (
                            <p className="form-error text-danger">
                              {errors.consumerNumber}
                            </p>
                          ) : null} */}
                          </div>
                          {/* <!-- /form-group --> */}
                        </div>

                        <div className="col-lg-12 mb-3">
                          {/* <!-- /form-group --> */}
                          <div className="check-conditions mb-0">
                            <label
                              htmlFor="AllowTerms"
                              className="checkboxes-view p-0 position-relative d-flex"
                            >
                              <input
                                onChange={() => setIsChecked(prev => !prev)}
                                type="checkbox"
                                name=""
                                className="position-absolute top-0"
                                id="AllowTerms"
                              />
                              <div className="now-check position-relative m-0"></div>
                              <p>
                                <span>
                                  By clicking here, I state that I have read and
                                  understood the{' '}
                                </span>
                                <a
                                  className="fw-bold text-dark text-decoration-underline"
                                  href="/Termsandconditions"
                                  target="_blank"
                                >
                                  Terms and Conditions
                                </a>
                                <span>, </span>
                                <a
                                  className="fw-bold text-dark text-decoration-underline"
                                  href="/Privacypolicy"
                                  target="_blank"
                                >
                                  Privacy Policy
                                </a>
                                <span> and </span>
                                <a
                                  className="fw-bold text-dark text-decoration-underline"
                                  href="/CookiePolicy"
                                  target="_blank"
                                >
                                  Cookie Policy
                                </a>
                              </p>
                            </label>
                          </div>
                        </div>
                        {/* <!-- /col-lg-4 col-md-6 --> */}
                      </div>
                      {/* <!-- /row --> */}
                    </div>
                    {/* <!-- /saving-calculate-form --> */}
                    <div className="col-12">
                      {showOtpDiv ? (
                        <div className="provided-email-otp">
                          <div className="otp-label">
                            <p>
                              OTPs have been sent to your email{' '}
                              <b>{values.email} </b>and phone{' '}
                              <b>{values.phone}</b>
                            </p>
                            {/* <p>Please enter the OTP shared on your Email provided in the form above</p> */}
                          </div>
                          <div className="row gx-5">
                            <div className="col-md-6">
                              <div className="send-otp-field">
                                <div className="form-group w-100">
                                  <label
                                    htmlFor="EnterOTP"
                                    className="border rounded-4 w-100 position-relative"
                                  >
                                    <span className="fw-bold">
                                      Enter Email OTP
                                    </span>
                                    <input
                                      type="text"
                                      id="EnterOTP"
                                      className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                      placeholder="Enter 6 digit OTP"
                                      value={otp}
                                      onChange={handleOtpChange}
                                      disabled={!otpEditable}
                                    />
                                  </label>
                                </div>
                                {/* <!-- /form-group --> */}
                                <div className="resend-otp mt-2">
                                  <button
                                    className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                    onClick={sendOTP}
                                  >
                                    Resend OTP
                                  </button>
                                </div>
                              </div>
                              {/* <!-- /send otp field --> */}
                            </div>
                            {/* <!-- /col-md-6 --> */}
                            <div className="col-md-6">
                              <div className="send-otp-field">
                                <div className="form-group w-100">
                                  <label
                                    htmlFor="EnterOTP"
                                    className="border rounded-4 w-100 position-relative"
                                  >
                                    <span className="fw-bold">
                                      Enter SMS OTP
                                    </span>
                                    <input
                                      type="text"
                                      id="EnterOTP"
                                      className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                      placeholder="Enter 6 digit OTP"
                                      value={smsOtp}
                                      onChange={smsOtpChange}
                                      disabled={!smsOtpEditable}
                                    />
                                  </label>
                                </div>
                                <div className="resend-otp mt-2">
                                  <button
                                    className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                    onClick={sendOTP}
                                  >
                                    Resend OTP
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /row --> */}
                        </div>
                      ) : (
                        ''
                      )}
                      {(isOtpVerified && isSmsOtpVerified) ||
                      (customerValues &&
                        customerValues.email === values.email &&
                        customerValues.phone === values.phone) ? (
                        <div className="col-12">
                          <div className="solfin-action d-flex mt-4 mb-4">
                            {updateStatusMutation.isLoading ? (
                              <button
                                className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn1"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden" role="status">
                                  Loading...
                                </span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                value="Register"
                                className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                onClick={cokiesubmit}
                                disabled={!isChecked}
                              >
                                {' '}
                                Register{' '}
                              </button>
                            )}

                            {/* <input
                              type="reset"
                              value="cancel"
                              onClickCapture={closeerrormessage}
                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                            /> */}
                          </div>
                        </div>
                      ) : isOtpVerified && isSmsOtpVerified ? (
                        <div className="col-12">
                          <div className="solfin-action mt-4 mb-4">
                            {updateStatusMutation.isLoading ? (
                              <button
                                className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn1"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden" role="status">
                                  Loading...
                                </span>
                              </button>
                            ) : (
                              <button
                                type="submit"
                                value="Register"
                                className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                onClick={handleSubmit}
                              >
                                {' '}
                                Register{' '}
                              </button>
                            )}
                            {/* <input
                              type="reset"
                              value="cancel"
                              onClickCapture={closeerrormessage}
                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                            /> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <br />
                      {/* <!-- /provided-email-otp --> */}
                      {customerValues &&
                      (customerValues.email !== values.email ||
                        customerValues.phone !== values.phone) ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="solfin-action mb-4">
                            {editable &&
                            customerValues &&
                            (customerValues.email !== values.email ||
                              customerValues.phone !== values.phone) ? (
                              <>
                                {sendOtp.isLoading ? (
                                  <button
                                    className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn2"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span
                                      className="visually-hidden"
                                      role="status"
                                    >
                                      Loading...
                                    </span>
                                  </button>
                                ) : (
                                  <input
                                    disabled={!isChecked}
                                    type="submit"
                                    value="Submit"
                                    id="submitButton"
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                    onClick={sendOTP}
                                  />
                                )}
                              </>
                            ) : !editable ? (
                              <>
                                <p>
                                  If you haven't received the OTPs, please check
                                  your email/phone number. In order to change
                                  them, click on Back and re-submit the form
                                </p>
                                <button
                                  type="button"
                                  onClick={handleEdit}
                                  className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                >
                                  Back
                                </button>
                              </>
                            ) : (
                              ''
                            )}
                            {/* <input
                          type="reset"
                          value="cancel"
                          onClickCapture={closeerrormessage}
                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                        /> */}
                          </div>
                        </div>
                      ) : !customerValues ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="solfin-action mb-4">
                            {editable ? (
                              <>
                                {sendOtp.isLoading ? (
                                  <button
                                    className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn2"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span
                                      className="visually-hidden"
                                      role="status"
                                    >
                                      Loading...
                                    </span>
                                  </button>
                                ) : (
                                  <input
                                    disabled={!isChecked}
                                    type="submit"
                                    value="Submit"
                                    id="submitButton"
                                    className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                    onClick={sendOTP}
                                  />
                                )}
                              </>
                            ) : !editable ? (
                              <>
                                {sendOtp.isLoading ? (
                                  <button
                                    className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn2"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      aria-hidden="true"
                                    ></span>
                                    <span
                                      className="visually-hidden"
                                      role="status"
                                    >
                                      Loading...
                                    </span>
                                  </button>
                                ) : (
                                  <>
                                    {isOtpVerified && isSmsOtpVerified ? (
                                      ''
                                    ) : (
                                      <>
                                        <p>
                                          If you haven't received the OTPs,
                                          please check your email/phone number.
                                          In order to change them, click on Back
                                          and re-submit the form
                                        </p>

                                        <button
                                          type="button"
                                          onClick={handleEdit}
                                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                        >
                                          Back
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                            {/* <input
                          type="reset"
                          value="cancel"
                          onClickCapture={closeerrormessage}
                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                        /> */}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      {/* <div className="solfin-query-call d-flex align-items-center">
                        <span>Any questions? Call us now.</span>
                        <span className="ps-3">
                          <a href="tel:1234567890">
                            <img
                              src="images/call-us.svg"
                              alt="Call Us"
                              title="Call Us"
                            />
                          </a>
                        </span>
                      </div> */}
                    </div>
                    {/* <!-- /col-12 --> */}
                  </form>
                </div>
                {/* <!-- /registration-form --> */}
              </div>
              {/* <!-- /col-lg-8 --> */}
              <div className="col-lg-4">
                <div className="right-side-news register-side-news rounded-4 position-relative overflow-hidden ms-auto">
                  <div className="text-ad-news pb-3">
                    <h5 className="fw-bold mb-4">
                      Go solar with <br />
                      Solfin
                    </h5>

                    <p>
                      Clean energy isn't just good for the planet, its good for
                      consumer's monthly bills and for the economy at large
                    </p>
                    <div className="solfin-action mt-lg-4 mt-3 d-none">
                      <a href="/" className="text-decoration-none">
                        <span className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn">
                          Learn More
                        </span>{' '}
                        <img
                          className="ms-3"
                          src="/images/long-next-arrow.svg"
                          alt="Learn More"
                          title="Learn More"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="bottom-image mt-4 ps-4">
                    <img
                      className="w-100"
                      src="/images/rooftop-area.png"
                      alt="Rooftop Area"
                      title="Rooftop Area"
                    />
                  </div>
                </div>
                {/* <!-- /right-side-news --> */}
              </div>
              {/* <!-- /col-lg-4 --> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
        {/* </form> */}
      </section>
    </>
  );
};

export default Register;
