export const calculations = (
  principal,
  interestRate,
  loanTenure,
  mainCostRate,
  units,
  percentageUnitsDepreciate,
  rate,
  percentageOnWhichItIncrease,
  isIndustry
) => {
  //fixed variables
  const totalYears = 25;
  const taxOnWhichDepreciate = 25.17 / 100;
  //for maintenance array
  const amount = 200000;
  const percent = 75;
  const year = [8, 17];

  //Calculate Emi
  const monthlyInterestRate = interestRate / 12 / 100;
  const totalMonths = loanTenure * 12;
  const emi =
    (principal *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, totalMonths)) /
    (Math.pow(1 + monthlyInterestRate, totalMonths) - 1);

  const interestArray = [];
  let outStandingBalance = principal;
  const principalDeductedArray = [];
  const emiArray = [];

  for (let i = 0; i < totalMonths; i++) {
    const interest = outStandingBalance * monthlyInterestRate;
    const pricipalDeducted = emi - interest;
    principalDeductedArray.push(pricipalDeducted);
    interestArray.push(interest);
    emiArray.push(emi);
    const principalPaid = emi - interest;
    outStandingBalance -= principalPaid;
  }

  //Maintanance Array

  const maintenanceCostArray = [];
  for (let i = 0; i < totalYears * 12; i++) {
    maintenanceCostArray.push((principal * (mainCostRate / 100)) / 12);
  }

  //Replacement Array
  const replacementArray = [];
  for (let i = 0; i < totalYears * 12; i++) {
    replacementArray.push(0);
  }
  for (let i = 0; i < year.length; i++) {
    const yearValue = year[i];
    for (let j = 0; j < 12; j++) {
      replacementArray[(yearValue - 1) * 12 + j] =
        (amount * (percent / 100)) / 12;
    }
  }

  //Gross
  const gross = [];
  for (let i = 0; i < totalYears * 12; i++) {
    gross.push(
      (maintenanceCostArray[i] || 0) + (emiArray[i] || 0) + replacementArray[i]
    );
  }

  const finalTableEmiArray = [];
  for (let i = 0; i < totalYears * 12; i += 12) {
    let sum = 0;
    for (let j = i; j < i + 12; j++) {
      sum += gross[j];
    }
    finalTableEmiArray.push(sum);
  }

  const cumEmi = [];
  let sum = 0;
  for (let i = 0; i < finalTableEmiArray.length; i++) {
    sum += finalTableEmiArray[i];
    cumEmi.push(Number(sum.toFixed(2)));
  }

  //Savings

  //Calculate Units Generated
  const unitsGeneratedArray = [];
  for (let i = 0; i < totalYears * 12; i++) {
    if (i % 12 === 0 && i !== 0) {
      units -= (units * percentageUnitsDepreciate) / 100;
    }
    unitsGeneratedArray.push(units.toFixed(2));
  }

  //Rate Array
  const rateArray = [];
  for (let i = 0; i < totalYears * 12; i++) {
    if (i % 12 === 0 && i !== 0) {
      rate += (rate * percentageOnWhichItIncrease) / 100;
    }
    rateArray.push(Number(rate).toFixed(2));
  }

  //Electriciy Bill Rebate
  const electBillRebateArray = [];
  let rebateSum = 0;
  for (let i = 0; i < totalYears * 12; i++) {
    rebateSum = unitsGeneratedArray[i] * rateArray[i];
    electBillRebateArray.push(Number(rebateSum.toFixed(2)));
  }

  //Calculate Saving
  const savingsArray = [];
  let savSum = 0;
  for (let i = 0; i < electBillRebateArray.length; i++) {
    savSum += electBillRebateArray[i];
    if ((i + 1) % 12 === 0) {
      savingsArray.push(savSum);
      savSum = 0;
    }
  }

  //CumSaving Array
  const cumSavingsArray = [];
  let cumSum = 0;
  for (let i = 0; i < savingsArray.length; i++) {
    cumSum += savingsArray[i];
    cumSavingsArray.push(Number(cumSum.toFixed(2)));
  }

  //Net Cum Cashflow
  //cashflow   = elect_bill_rebate -gross

  const cashFlowArray = [];
  const cashFlowValue = 0;
  for (let i = 0; i < totalYears * 12; i++) {
    cashFlowArray.push(electBillRebateArray[i] - gross[i]);
  }

  //DepTax
  const depTaxArray = [];

  const taxBenifitsOnInterest = [];
  const overAllArray = [];
  const taxLossOnBill = []; // it should be in minus
  let value = principal / 30;

  for (let i = 0; i < 300; i++) {
    depTaxArray.push(value * taxOnWhichDepreciate);
    if ((i + 1) % 12 === 0) {
      value *= 0.6;
    }
    taxBenifitsOnInterest.push((interestArray[i] || 0) * taxOnWhichDepreciate);
    taxLossOnBill.push(-electBillRebateArray[i] * taxOnWhichDepreciate);
    overAllArray.push(
      cashFlowArray[i] +
        taxBenifitsOnInterest[i] +
        depTaxArray[i] +
        taxLossOnBill[i]
    );
  }

  //cumulative Overall Array
  const cumOverallArray = [];

  let cumulativeValue = 0;

  for (let i = 0; i < overAllArray.length; i++) {
    cumulativeValue += overAllArray[i];
    cumOverallArray.push(cumulativeValue);
  }

  //net cum cash flow
  let netCumCashflow = [];
  let netSum = 0;
  for (let i = 0; i < cumOverallArray.length; i++) {
    netSum += cumOverallArray[i];
    if ((i + 1) % 12 === 0) {
      netCumCashflow.push(Number(cumOverallArray[i].toFixed(2)));
      netSum = 0;
    }
  }

  //if isIndustry is false so we have to find its yearly value of cashFlowArray
  const yearlyCumCashFlow = [];
  let yearlyCumSum = 0;
  for (let i = 0; i < totalYears * 12; i++) {
    yearlyCumSum += cashFlowArray[i];
    if ((i + 1) % 12 === 0) {
      yearlyCumCashFlow.push(yearlyCumSum);
      yearlyCumSum = 0;
    }
  }

  const yearlyCumCashFlow1 = [];
  let yearlyCumCashFlowSum = 0;
  for (let i = 0; i < totalYears; i++) {
    yearlyCumCashFlowSum += yearlyCumCashFlow[i];
    yearlyCumCashFlow1.push(yearlyCumCashFlowSum);
  }

  if (!isIndustry) {
    netCumCashflow = yearlyCumCashFlow1;
  }

  return [cumEmi, cumSavingsArray, netCumCashflow];
};

//calculations(5941358, 13, 5, 1.6, 16975.31, 1, 8.1, 3.5);
