import React from 'react';
import {useQuery} from 'react-query';
import * as querydata from '../dataService/DataService';

const DocumentYear = props => {
  return (
    <>
      <select
        id={props.valueId}
        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
        name={props.valueName}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        disabled={props.disabled}
      >
        <option value="" defaultValue="Select Document">
          Select Document Year
        </option>
        {props.yearList.map(year => {
          return (
            <option key={year} value={year}>
              {year}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default DocumentYear;
