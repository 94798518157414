import React from 'react';
import {useQuery, useQueryClient} from 'react-query';
import * as querydata from '../dataService/DataService';

const StateSelect = props => {
  //const queryClient = useQueryClient()

  // queryClient.invalidateQueries({ queryKey: ['getFilteredCities'] })
  //const getAllStates = useQuery("StatesSelects", querydata.getAllStates);
  //const getAllStates = useQuery("StatesSelects", [])
  // console.log("props.isDropdownDisabled")
  // console.log(props.isDropdownDisabled)
  props.setSelectedStates(props.values && props.values.stateId);
  // console.log(getFilteredCities.data)
  // if (getAllStates.isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (getAllStates.error) {
  //   return <div>Error: {getAllStates.error.message}</div>;
  // }

  // if (!getAllStates.data) {
  //   return <div>No data fetched.</div>;
  // }
  const handleChange = event => {
    // setSelectedValue(event.target.value);
    // // Call your custom function here or perform any other logic
    // customFunction(event.target.value);
  };
  return (
    <>
      <label
        htmlFor="State"
        className="border rounded-4 w-100 position-relative"
      >
        <span className="fw-bold">State</span>
        {props.isDropdownDisabled ? (
          <select
            id="matrixstateId"
            disabled
            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
            name="stateIds"
            value={props.values && props.values.stateIds}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
          >
            <option value="-1" defaultValue="Select State">
              Select State
            </option>
            {/* {getAllStates.data.data &&
            getAllStates.data.data.map((stateoption) => {
              return (
                <option
                  key={stateoption.state_id}
                  value={stateoption.state_id}
                >
                  {stateoption.state}
                </option>
              );
            })} */}
          </select>
        ) : (
          <select
            id="matrixstateId"
            className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
            name="stateIds"
            value={props.values && props.values.stateIds}
            //onChange={props.handleChange}
            onChange={props.setdropdown}
            // onChange={(e)=>{props.handleChange;
            //   props.setdropdown;
            // }}
            onBlur={props.handleBlur}
            disabled={props.disabled}
          >
            <option value="-1" defaultValue="Select State">
              Select State
            </option>
            {/* {getAllStates.data.data &&
            getAllStates.data.data.map((stateoption) => {
              return (
                <option
                  key={stateoption.state_id}
                  value={stateoption.state_id}
                >
                  {stateoption.state}
                </option>
              );
            })} */}
          </select>
        )}
      </label>
      {props.errors.stateId && props.touched.stateId ? (
        <p className="form-error text-danger">{props.errors.stateId}</p>
      ) : null}
    </>
  );
};

export default StateSelect;
