import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation, useQuery} from 'react-query';
import * as queryData from '../../dataService/DataService';
import {toast} from 'react-toastify';
import {Link, useNavigate} from 'react-router-dom';
import StateSelect from '../../components/StateSelect';
import CitySelect from '../../components/CitySelect';
import AreaServed from '../../components/AreaServedRegion';
import * as querydata from '../../dataService/DataService';
import DsaPartnerList from './DsaPartnerList';

//client Validation
export const dsaRegisterSchema = Yup.object({
  dsaName: Yup.string().required('Please enter full name'),
  email: Yup.string().email().required('Please enter your email'),
  phone: Yup.string()
    .required('Mobile number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number'),
  //pinCode: Yup.number().required("Please enter your pincode"),
  pinCode: Yup.string()
    .required('PinCode is required')
    .length(6, 'Pincode must be exactly 6 digits')
    .matches(/^\d+$/, 'Pincode must only contain digits'),
  cityId: Yup.number().required('Please select city'),
  address: Yup.string().required('Please enter your address'),
  profile: Yup.string().required('Please provide your profile description'),
  activityType: Yup.array().min(1, 'Select at least one Activity Type'),
  experienceId: Yup.number().required('Please select your Experience'),
});

const Dsa = () => {
  //duplicate area Served compenent
  const [dropdowns, setDropdowns] = useState([]);
  const [editable, setEditable] = useState(true);
  const [showOtpDiv, setShowOtpDiv] = useState(false);
  const [otp, setOtp] = useState('');
  ///const [selectedCity, setSelectedCity] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpEditable, setOtpEditable] = useState(true);
  const [smsOtp, setSmsOtp] = useState('');
  const [isSmsOtpVerified, setIsSmsOtpVerified] = useState(false);
  const [smsOtpEditable, setSmsOtpEditable] = useState(true);
  const [selectedStatesArray, setSelectedStatesArray] = useState([]);
  const [selectedAreaServedStates, setSelectedAreaServedStates] = useState([]);
  const areaServedStatesVariable = [];
  const getAllStatesAreaserved = useQuery(
    'StatesSelect',
    querydata.getAllStates
  );

  const filteredareadata =
    getAllStatesAreaserved &&
    getAllStatesAreaserved.data &&
    getAllStatesAreaserved.data.data &&
    getAllStatesAreaserved.data.data.filter(
      data => !selectedStatesArray.map(Number).includes(data.state_id)
    );
  console.log('filtereddata');
  console.log(filteredareadata);
  console.log('selectedStatesArray');
  console.log(selectedStatesArray);

  const handleAddDropdown = () => {
    const newDropdownKey = Date.now().toString();
    setDropdowns(prevDropdowns => [
      ...prevDropdowns,
      {
        key: newDropdownKey,
        options: filteredareadata,
        onSelectionChange: value =>
          handleSelectionChange(newDropdownKey, value),
        onDelete: () => handleDeleteDropdown(newDropdownKey),
      },
    ]);
  };

  const handleSelectionChange = (dropdownKey, value) => {
    // //console.log(`Dropdown with key ${dropdownKey} selected value: ${value}`);
    // Perform desired logic for selection change
  };

  const handleDeleteDropdown = dropdownKey => {
    // //console.log(`Delete dropdown with key ${dropdownKey}`);
    setDropdowns(prevDropdowns =>
      prevDropdowns.filter(dropdown => dropdown.key !== dropdownKey)
    );
  };

  const [selectedState, setSelectedState] = useState('-1');
  //initialize Navigate method
  const navigate = useNavigate();
  //served area
  //const [areaServedState, setAreaServedState] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const handleSelect = (selected) => {
  //   const updatedSelectedOptions = selected.filter(
  //     (option) => option !== "selectAll"
  //   );
  //   setSelectedOptions(updatedSelectedOptions);
  // };

  //const [removedOptions, setRemovedOptions] = useState([]);
  //const onSelectOptions = (selectedList, selectedItem) => {
  // //console.log(selectedItem,selectedList)
  // setSelectedOptions([...selectedOptions, selectedItem]);
  //};
  //const onRemoveOptions = (selectedList, removedItem) => {
  //  setRemovedOptions([...removedOptions, removedItem]);
  //};

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked);
  };

  // values defined for formik
  const initialValues = {
    dsaName: '',
    email: '',
    phone: '',
    pinCode: '',
    cityId: '',
    address: '',
    documentId: '1',
    profile: '',
    areaServed: [],
    stateId: selectedState,
    activityType: [],
    experienceId: '',
    checkboxes: [],
  };
  const handleSelectedStateChange = selectedStates => {
    setSelectedStatesArray(prevSelectedStatesArray => [
      ...prevSelectedStatesArray,
      selectedStates,
    ]);
  };
  // variable for file upload
  const [file, setfile] = useState('');

  // const handlefilechange = (e) => {
  // const selectedFile = e.target.files[0];
  // const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats

  // if (selectedFile) {
  //   const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //   // Check if the file format is allowed
  //   if (allowedFormats.includes(fileExtension)) {
  //     setfile(selectedFile);
  //     document.getElementById("filetextid").value = selectedFile.name;
  //     error.file = "";
  //   } else {
  //     setfile(null);
  //     document.getElementById("filetextid").value = "";
  //     error.file =
  //       "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
  //   }
  // } else {
  //   setfile(null);
  //   document.getElementById("filetextid").value = "";
  //   error.file = "Please Upload Document";
  // }
  // };
  const handlefilechange = e => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['pdf', 'jpg', 'jpeg', 'png']; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      const fileSize = selectedFile.size;

      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        setfile(null);
        document.getElementById('filetextid').value = '';
        error.file =
          'Invalid file format. Allowed formats: ' + allowedFormats.join(', ');
        return;
      }

      // Check if the file size exceeds the limit
      if (fileSize > maxFileSizeInBytes) {
        setfile(null);
        document.getElementById('filetextid').value = '';
        error.file = 'File size exceeds the limit of 2 MB.';
        return;
      }

      // The file format and size are allowed, so set it in the state
      setfile(selectedFile);
      document.getElementById('filetextid').value = selectedFile.name;
      error.file = '';
    } else {
      setfile(null);
      document.getElementById('filetextid').value = '';
      error.file = 'Please Upload Document';
    }
  };

  //for validating area served
  const areaServedDropdowns = document.querySelectorAll('.areaservedstates');
  const openAreaServedStates = [];
  console.log('areaServedDropdowns states');
  areaServedDropdowns.forEach(data => openAreaServedStates.push(data.value));
  const isOpenState =
    openAreaServedStates.length > selectedOptions.length ? true : false;

  console.log('isOpenState');
  console.log(isOpenState);
  const [error, setErrors] = useState({});
  const newErrors = {};
  const validateForm = () => {
    if (!errors.dsaName && !touched.dsaName) {
      if (!values.dsaName) {
        newErrors.dsaName = 'Please enter company name';
        toast.error('Please enter company name');
        document.getElementById('CompanyName').focus();
      }
    } else {
      if (!values.dsaName) {
        toast.error('Please enter company name');
        touched.epcName = 'Please enter company name';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('CompanyName').focus();
      }
    }

    if (!errors.email && !touched.email) {
      if (!values.email) {
        newErrors.email = 'Please enter your email';
        toast.error('Please enter your email');
        document.getElementById('Email').focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        newErrors.email = 'Invalid email format';
        toast.error('Invalid email format');
        document.getElementById('Email').focus();
      }
    } else {
      if (!values.email) {
        toast.error('Please enter your email');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Email').focus();
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        toast.error('Invalid email format');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Email').focus();
      }
      touched.email = 'Please enter your email';
    }

    if (!errors.documentId && !touched.documentId) {
      if (!values.documentId || values.documentId == -1) {
        newErrors.documentId = 'Please select Registration Type';
        toast.error('Please select Registration Type');
        document.getElementById('Documenttype').focus();
      }
    } else {
      if (!values.documentId || values.documentId == -1) {
        toast.error('Please select Registration Type');
        touched.documentId = 'Please select Registration Type';
        newErrors.documentId = 'Please enter full name';
        document.getElementById('registerationTypeId').focus();
      }
    }

    if (!errors.address && !touched.address) {
      if (!values.address) {
        newErrors.address = 'Please enter your address';
        toast.error('Please enter your address');
        document.getElementById('Address').focus();
      }
    } else {
      if (!values.address) {
        toast.error('Please enter your address');
        touched.address = 'Please enter your address';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Address').focus();
      }
    }

    if (!errors.phone && !touched.phone) {
      if (values.phone.length === 0) {
        newErrors.phone = 'Mobile number is required';
        toast.error('Mobile number is required');
        document.getElementById('Phone').focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        newErrors.phone = 'Invalid mobile number';
        toast.error('Invalid mobile number');
        document.getElementById('Phone').focus();
      }
    } else {
      if (values.phone.length === 0) {
        toast.error('Mobile number is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Phone').focus();
      } else if (!/^\d{10}$/.test(values.phone)) {
        toast.error('Invalid mobile number');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Phone').focus();
      }
      touched.phone = 'Mobile number is required';
    }

    if (!errors.pinCode && !touched.pinCode) {
      if (!values.pinCode) {
        newErrors.pinCode = 'PinCode is required';
        toast.error('PinCode is required');
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        newErrors.pinCode = 'Pincode must only contain digits';
        toast.error('Pincode must only contain digits');
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length != 6) {
        newErrors.pinCode = 'Pincode must be exactly 6 digits';
        toast.error('Pincode must be exactly 6 digits');
        document.getElementById('Pincode').focus();
      }
    } else {
      if (!values.pinCode) {
        toast.error('PinCode is required');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (!/^\d+$/.test(values.pinCode)) {
        toast.error('Pincode must only contain digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      } else if (values.pinCode.length != 6) {
        toast.error('Pincode must be exactly 6 digits');
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('Pincode').focus();
      }
      touched.pinCode = 'PinCode is required';
    }

    if (!errors.stateId && !touched.stateId) {
      if (values.stateId == -1) {
        newErrors.state_Id = 'Please select State';
        toast.error('Please select State');
        document.getElementById('State').focus();
      }
    } else {
      if (values.stateId == -1) {
        toast.error('Please select State');
        touched.stateId = 'Please select State';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('State').focus();
      }
    }

    if (!errors.cityId && !touched.cityId) {
      if (!values.cityId) {
        newErrors.city_Id = 'Please select City';
        toast.error('Please select City');
        document.getElementById('City').focus();
      }
    } else {
      if (!values.cityId) {
        toast.error('Please select City');
        touched.cityId = 'Please select City';
        newErrors.customerNames = 'Please enter full name';
        document.getElementById('City').focus();
        // newErrors.city_Id = 'Please select City';
      }
    }
    if (!errors.profile && !touched.profile) {
      if (!values.profile) {
        newErrors.profile = 'Please enter your Profile';
        toast.error('Please enter your Profile');
        document.getElementById('Profile').focus();
      }
    } else {
      if (!values.profile) {
        toast.error('Please enter your profile');
        touched.profile = 'Please enter your address';
        newErrors.profile = 'Please enter full name';
        document.getElementById('Profile').focus();
      }
    }
    if (isOpenState) {
      newErrors.areaServed = 'Please Select Area Served';
      toast.error('Please select Area Served');
      document.getElementById('States').focus();
    }
    if (values.activityType.length === 0) {
      if (values.activityType.length === 0) {
        newErrors.activityType = 'Select at least one Activity Type';
        toast.error('Select at least one Activity Type');
        document.getElementById('Profile').focus();
      }
    } else {
      if (values.activityType === 0) {
        toast.error('SSelect at least one Activity Type');
        touched.activityType = 'Select at least one Activity Type';
        newErrors.activityType = 'Select at least one Activity Type';
        document.getElementById('Profile').focus();
      }
    }

    if (!errors.experienceId && !touched.experienceId) {
      if (!values.experienceId) {
        newErrors.experienceId = 'Please Select your Experience';
        toast.error('Please select City');
        document.getElementById('Profile').focus();
      }
    } else {
      if (!values.experienceId) {
        toast.error('Please Select your Experience');
        touched.experienceId = 'Please Select your Experience';
        newErrors.experienceId = 'Please Select your Experience';
        document.getElementById('Profile').focus();
        // newErrors.city_Id = 'Please select City';
      }
    }

    if (!selectedOptions || selectedOptions.length == 0) {
      newErrors.areaServed = 'Please enter your Served Area and Regions';
      toast.error('Please enter your Served Area and Regions');
      document.getElementById('Profile').focus();
    }
    // if (!file || file.length == 0) {
    //   newErrors.file = "Please Upload Document";
    //   toast.error("Please Upload Document");
    //   document.getElementById("filetextid").focus();
    // }

    return newErrors;
  };

  const {values, handleBlur, handleChange, handleSubmit, errors, touched} =
    useFormik({
      initialValues: initialValues,
      validationSchema: dsaRegisterSchema,
      onSubmit: (values, action) => {
        const formData = new FormData();
        formData.append('dsaName', values.dsaName);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('pinCode', values.pinCode);
        formData.append('cityId', values.cityId);
        formData.append('address', values.address);
        formData.append('documentImage', file);
        formData.append('profile', values.profile);
        formData.append('documentId', values.documentId);
        formData.append('activitiesIds', values.activityType);
        formData.append('experienceId', values.experienceId);
        formData.append('areaServed', JSON.stringify(selectedOptions));
        // //console.log(formData);
        console.log('formData');
        console.log(formData);
        insertDsaMutation.mutate(formData);
        // action.resetForm();
      },
    });
  //console.log(":selectedOptions")
  //console.log(selectedOptions)
  const sendOTP = event => {
    event.preventDefault();
    //console.log("error.activityType")
    //console.log(errors.activityType ? errors.activityType : "")
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      // Submit form data or perform further actions here
      setErrors(newErrors);
      const sendOtpData = {
        email: values.email,
        phone: values.phone,
      };
      sendOtp.mutate(sendOtpData);
    } else {
      setErrors(newErrors);
    }
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phoneRegex = /^[0-9]{10}$/;
    // if (
    //   !values.dsaName ||
    //   !values.email ||
    //   !values.phone ||
    //   !values.pinCode ||
    //   !values.cityId ||
    //   !values.address ||
    //   !values.profile ||
    //   !values.experienceId
    // ) {
    //   toast.error("Please fill all the required fields");
    //   return;
    // }
    // if (!values.email || !emailRegex.test(values.email)) {
    //   toast.error("Please fill a valid email");
    //   return;
    // } else if (!values.phone || !phoneRegex.test(values.phone)) {
    //   toast.error("Please fill a valid 10-digit phone number");
    //   return;
    // } else {
    //   const sendOtpData = {
    //     email: values.email,
    //     phone: values.phone,
    //   };
    //   sendOtp.mutate(sendOtpData);
    // }
  };
  const sendOtp = useMutation(querydata.sendDsaOtpRequest, {
    onError: error => {
      toast.error(error.message);
      // //console.log(error.message);
      // //console.log(error);
      setEditable(true);
      setShowOtpDiv(false);
    },
    onSuccess: data => {
      toast.success('OTP Sent. Please Check your Email and Phone');
      const hideform = document.getElementsByClassName('hideform');
      // Loop through the elements and hide each one
      for (let i = 0; i < hideform.length; i++) {
        hideform[i].style.display = 'none';
      }
      setEditable(false);
      setShowOtpDiv(true);
    },
  });

  const handleOtpChange = event => {
    const emailOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;
    if (otpRegex.test(emailOTP)) {
      setOtp(emailOTP);
      if (emailOTP.length === 6) {
        verifyEmailOtp.mutate(emailOTP);
      }
    }
  };

  const smsOtpChange = event => {
    const smsOTP = event.target.value;
    const otpRegex = /^[0-9]{0,6}$/;

    if (otpRegex.test(smsOTP)) {
      setSmsOtp(smsOTP);
      if (smsOTP.length === 6) {
        verifySmsOtp.mutate(smsOTP);
      }
    }
  };
  const verifySmsOtp = useMutation(querydata.verifySmsOTP, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess: data => {
      toast.success('SMS OTP Verified Successfully');
      setIsSmsOtpVerified(true);
      setSmsOtpEditable(false);
    },
  });

  const verifyEmailOtp = useMutation(querydata.verifyEmailOTP, {
    onError: error => {
      toast.error(error.message);
    },
    onSuccess: data => {
      toast.success('Email OTP Verified Successfully');
      setIsOtpVerified(true);
      setOtpEditable(false);
      // //console.log("Succesful",{data});
    },
  });

  const handleEdit = () => {
    setEditable(true); // Enable form editing when the edit button is clicked.
    setShowOtpDiv(false); // Hide OTP div on edit button click
    setOtp('');
    setSmsOtp('');
    setIsOtpVerified(false);
    setIsSmsOtpVerified(false);
    setOtpEditable(true);
    setSmsOtpEditable(true);
    const hideform = document.getElementsByClassName('hideform');
    for (let i = 0; i < hideform.length; i++) {
      hideform[i].style.display = 'block';
    }
  };

  const getDocumentType = useQuery(
    'documentType',
    queryData.getDSADocumentType
  );

  const getAllDsaExperiences = useQuery(
    'Experiences',
    queryData.getAllDsaExperiences
  );

  const getAllDsaActivities = useQuery(
    'ActivityType',
    queryData.getAllDsaActivities
  );

  //post request to submit data
  const insertDsaMutation = useMutation(queryData.insertDSA, {
    onError: error => {
      toast.error(error.message);
      // //console.log(error.message);
    },
    onSuccess: data => {
      navigate('/RegistrationComplete');
    },
  });

  if (getDocumentType.isLoading) {
    return <div>Loading...</div>;
  }

  if (getDocumentType.error) {
    return <div>Error:</div>;
  }

  if (!getDocumentType.data) {
    return <div>No data fetched. </div>;
  }
  console.log('selectedOptions');
  console.log(selectedOptions);

  console.log('values');
  console.log(values);
  return (
    <>
      <section>
        <div className="saving-calculator header-space pt-1">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="mob-pe-1 mob-ps-1">
                  <div className="page-title title-border pb-4">
                    <h2 className="fw-bold heading-color">
                      Local Finance{' '}
                      <span className="text-theme-green">Partners</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="solfin-partners mob-pe-1 mob-ps-1 mt-lg-4">
                  <div className="partners-tab pb-4">
                    <ul className="nav nav-pills p-0" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="Enrollment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#Enrollment-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="Enrollment-tab-pane"
                          aria-selected="true"
                        >
                          Enrollment
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="PartnersList-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#PartnersList-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="PartnersList-tab-pane"
                          aria-selected="false"
                        >
                          Partners List
                        </button>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- /partners-tab --> */}
                  <div className="partner-tab-data pt-2 mt-lg-4">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="Enrollment-tab-pane"
                        role="tabpanel"
                        aria-labelledby="Enrollment-tab"
                        tabIndex="0"
                      >
                        <div className="registration-form">
                          <form name="dsa-form">
                            <div className="saving-calculate-form registration-form hideform">
                              {/* <!-- /col-lg-4 col-md-6 --> */}
                              <div className="heading mb-xl-4 mb-3">
                                <span className="fw-semibold saving-title">
                                  Basic Details
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="CompanyName"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Name</span>
                                      <input
                                        type="text"
                                        id="CompanyName"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Enter Your Name"
                                        name="dsaName"
                                        value={values.dsaName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.dsaName && touched.dsaName ? (
                                      <p className="form-error text-danger">
                                        {errors.dsaName}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Email"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Email</span>
                                      <input
                                        type="email"
                                        id="Email"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Email Address"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.email && touched.email ? (
                                      <p className="form-error text-danger">
                                        {errors.email}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Phone"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Phone</span>
                                      <input
                                        type="tel"
                                        id="Phone"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Phone Number"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.phone && touched.phone ? (
                                      <p className="form-error text-danger">
                                        {errors.phone}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <label
                                      htmlFor="Pincode"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Pincode</span>
                                      <input
                                        type="text"
                                        id="Pincode"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Area Pincode"
                                        name="pinCode"
                                        value={values.pinCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.pinCode && touched.pinCode ? (
                                      <p className="form-error text-danger">
                                        {errors.pinCode}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <StateSelect
                                      values={values}
                                      setSelectedState={setSelectedState}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      disabled={!editable}
                                    />

                                    {/* <label
                                      htmlFor="State"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">State</span>
                                      <select
                                        id="State"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        name="stateId"
                                        value={values.stateId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        <option
                                          value="-1"
                                          defaultValue="Select State"
                                        >
                                          Select State
                                        </option>
                                        {getAllStates.data.data &&
                                          getAllStates.data.data.map(
                                            (stateoption) => {
                                              return (
                                                <option
                                                  key={stateoption.state_id}
                                                  value={stateoption.state_id}
                                                >
                                                  {stateoption.state}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </label>
                                    {errors.stateId && touched.stateId ? (
                                      <p className="form-error text-danger">
                                        {errors.stateId}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-xl-4 col-md-6">
                                  <div className="form-group mb-4">
                                    <CitySelect
                                      parameter={values}
                                      selectedState={selectedState}
                                      handleBlur={handleBlur}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      disabled={!editable}
                                    />

                                    {/* <label
                                      htmlFor="City"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">City</span>
                                      <select
                                        id="State"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        name="cityId"
                                        value={values.cityId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      >
                                        {getFilteredCities.data.data &&
                                          getFilteredCities.data.data.map(
                                            (cityoption) => {
                                              return (
                                                <option
                                                  key={cityoption.city_id}
                                                  value={cityoption.city_id}
                                                >
                                                  {cityoption.city}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </label>
                                    {errors.cityId && touched.cityId ? (
                                      <p className="form-error text-danger">
                                        {errors.cityId}
                                      </p>
                                    ) : null} */}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <label
                                      htmlFor="Address"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Address</span>
                                      <input
                                        id="Address"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        placeholder="Type Your Address"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      />
                                    </label>
                                    {errors.address && touched.address ? (
                                      <p className="form-error text-danger">
                                        {errors.address}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-12 --> */}
                              </div>
                              {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /saving-calculate-form --> */}
                            <div className="saving-calculate-form mt-4 hideform">
                              <div className="heading mb-4">
                                <span className="fw-semibold saving-title">
                                  Company Regn Details{' '}
                                  <i className="text-muted fw-normal">
                                    (Optional)
                                  </i>
                                </span>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="Documenttype"
                                      className="border rounded-4 w-100 position-relative"
                                    >
                                      <span className="fw-bold">Document</span>
                                      <select
                                        id="Documenttype"
                                        className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                        name="documentId"
                                        value={values.documentId}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!editable}
                                      >
                                        {getDocumentType.data.data &&
                                          getDocumentType.data.data.map(
                                            documentOption => {
                                              return (
                                                <option
                                                  key={
                                                    documentOption.document_id
                                                  }
                                                  value={
                                                    documentOption.document_id
                                                  }
                                                >
                                                  {documentOption.document_name}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </label>
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                                <div className="col-md-6">
                                  <div className="form-group mb-4">
                                    <div className="border rounded-4 w-100 position-relative label-form">
                                      <span className="fw-bold">
                                        Document File
                                      </span>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          id="filetextid"
                                          name="filename"
                                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                          placeholder="No file selected"
                                          readOnly
                                        />
                                        <span className="input-group-btn unit-label upload-docs position-absolute">
                                          <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                                            <input
                                              type="file"
                                              name="file"
                                              onChange={handlefilechange}
                                              disabled={!editable}
                                            />
                                            Upload
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                    {errors.file && touched.file ? (
                                      <p className="form-error text-danger">
                                        {errors.file}
                                      </p>
                                    ) : error.file ? (
                                      <p className="form-error text-danger">
                                        {error.file}
                                      </p>
                                    ) : null}
                                  </div>
                                  {/* <!-- /form-group --> */}
                                </div>
                                {/* <!-- /col-lg-4 col-md-6 --> */}
                              </div>
                              {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /saving-calculate-form --> */}

                            <div className="saving-calculate-form about-section mt-1 hideform">
                              <div className="heading mb-4">
                                <span className="fw-semibold saving-title">
                                  About Me
                                </span>
                              </div>
                              <div className="area-served">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="form-group mb-1">
                                      <div className="checkbox-labels square-check border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                                        <div className="location-title w-100 mb-0">
                                          <span
                                            className="fw-bold"
                                            id="activity-type"
                                          >
                                            Type of Activity
                                          </span>
                                        </div>

                                        {/* Static type of activities */}

                                        {/* <label className="checkboxes-view p-0">
                                          <div className="check-now position-relative d-flex align-items-center">
                                           safas
                                            <input
                                              type="checkbox"
                                              name="checkboxes"
                                              value="1"
                                              checked={values.checkboxes.includes(
                                                "1"
                                              )}
                                              onChange={handleChange}
                                            />
                                            <div className="now-check rounded-1 position-relative"></div>
                                            <span>Loan Origination</span>
                                          </div>
                                        </label> */}

                                        {getAllDsaActivities &&
                                          getAllDsaActivities?.data &&
                                          getAllDsaActivities?.data?.data &&
                                          getAllDsaActivities?.data?.data.map(
                                            activity => (
                                              <>
                                                <label className="checkboxes-view p-0">
                                                  <div className="check-now position-relative d-flex align-items-center">
                                                    <input
                                                      id="Activitytype"
                                                      type="checkbox"
                                                      name="activityType"
                                                      className="position-absolute top-0"
                                                      value={
                                                        activity.activity_id
                                                      }
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      disabled={!editable}
                                                      checked={values.activityType.includes(
                                                        String(
                                                          activity.activity_id
                                                        )
                                                      )}
                                                    />
                                                    <div className="now-check rounded-1 position-relative"></div>
                                                    <span>
                                                      {activity.activity}
                                                    </span>
                                                  </div>
                                                </label>
                                              </>
                                            )
                                          )}

                                        {/* </label> */}
                                      </div>
                                      {errors.activityType ||
                                      touched.activityType ? (
                                        <p className="form-error text-danger">
                                          {errors.activityType}
                                        </p>
                                      ) : null}
                                      {/* <!-- /checkbox-labels --> */}
                                    </div>
                                    {/* <!-- /form-group --> */}
                                  </div>
                                  <div className="col-lg-12 mt-3">
                                    <div className="form-group mb-4">
                                      <div className="checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                                        <div className="location-title w-100 mb-0">
                                          <span className="fw-bold">
                                            Experience
                                          </span>
                                        </div>

                                        {getAllDsaExperiences.data &&
                                          getAllDsaExperiences.data.data.map(
                                            experiences => {
                                              return (
                                                <>
                                                  <label className="checkboxes-view p-0">
                                                    <div className="check-now position-relative d-flex align-items-center">
                                                      <input
                                                        type="radio"
                                                        name="experienceId"
                                                        className="position-absolute top-0"
                                                        key={
                                                          experiences.experience_id
                                                        }
                                                        value={
                                                          experiences.experience_id
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={!editable}
                                                      />
                                                      <div className="now-check rounded-pill position-relative"></div>
                                                      <span>
                                                        {experiences.experience}
                                                      </span>
                                                    </div>
                                                  </label>
                                                </>
                                              );
                                            }
                                          )}

                                        {/* </label> */}
                                      </div>
                                      {errors.experienceId ||
                                      touched.experienceId ? (
                                        <p className="form-error text-danger">
                                          {errors.experienceId}
                                        </p>
                                      ) : null}
                                      {/* <!-- /checkbox-labels --> * */}
                                    </div>
                                    {/* <!-- /form-group -->  */}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group mt-1">
                                      <label
                                        htmlFor="Profile"
                                        className="border rounded-4 w-100 position-relative"
                                      >
                                        <span className="fw-bold">Profile</span>
                                        <textarea
                                          name="profile"
                                          className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                          id="Profile"
                                          cols="30"
                                          rows="4"
                                          value={values.profile}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          disabled={!editable}
                                          maxLength="300"
                                          placeholder="Please provide a short description upto 300 characters"
                                        ></textarea>
                                      </label>
                                      {errors.profile && touched.profile ? (
                                        <p className="form-error text-danger">
                                          {errors.profile}
                                        </p>
                                      ) : null}
                                    </div>

                                    {/* <!-- /form-group --> */}
                                  </div>
                                  {/* <!-- /col-lg-4 col-md-6 --> */}
                                </div>
                                {/* <!-- /row --> */}
                              </div>
                              {/* <!-- /area-served --> */}
                            </div>

                            <div className="saving-calculate-form mt-4 hideform">
                              <div className="heading mb-4">
                                <span className="fw-semibold saving-title">
                                  Areas Served
                                </span>
                              </div>
                              <div className="area-served">
                                {dropdowns.map(dropdown => (
                                  <AreaServed
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                    key={dropdown.key}
                                    onSelectionChange={
                                      dropdown.onSelectionChange
                                    }
                                    onDelete={dropdown.onDelete}
                                    disabled={!editable}
                                    getAllStatesAreaserved={dropdown.options}
                                    selectedStatesArray={selectedStatesArray}
                                    areaServedStatesVariable={
                                      areaServedStatesVariable
                                    }
                                    selectedAreaServedStates={
                                      selectedAreaServedStates
                                    }
                                    setSelectedAreaServedStates={
                                      setSelectedAreaServedStates
                                    }
                                    onSelectedStateChange={
                                      handleSelectedStateChange
                                    }
                                    error={error}
                                  />
                                ))}

                                {errors.areaServed && touched.areaServed ? (
                                  <p className="form-error text-danger">
                                    {errors.areaServed}
                                  </p>
                                ) : null}

                                {/* <!-- /row --> */}
                                <div className="addmore-area">
                                  <a
                                    disabled={!editable}
                                    onClick={handleAddDropdown}
                                    className="btn btn-outline-success text-uppercase ps-5 pe-5"
                                  >
                                    <span>+</span> ADD
                                  </a>
                                </div>
                              </div>
                              {errors.areaServed && touched.areaServed ? (
                                <p className="form-error text-danger">
                                  {errors.areaServed}
                                </p>
                              ) : null}

                              {/* <!-- /area-served --> */}
                            </div>
                            {/* <!-- /saving-calculate-form --> */}
                            <div className="bottom-actions">
                              <div className="check-conditions hideform">
                                <label
                                  htmlFor="AllowTerms"
                                  className="checkboxes-view p-0 position-relative d-flex"
                                >
                                  <input
                                    type="checkbox"
                                    name=""
                                    className="position-absolute top-0"
                                    id="AllowTerms"
                                    onChange={handleCheckboxChange}
                                  />
                                  <div className="now-check position-relative mt-0"></div>
                                  <p
                                    className="mb-0"
                                    style={{'line-height': '25px'}}
                                  >
                                    By clicking here, I state that I have read
                                    and understood the{' '}
                                    <Link
                                      to="/Termsandconditions"
                                      className="fw-bold text-dark text-decoration-underline"
                                      target="_blank"
                                    >
                                      Terms and Conditions
                                    </Link>
                                    <span>, </span>{' '}
                                    <Link
                                      to="/PrivacyPolicy"
                                      class="fw-bold text-dark text-decoration-underline"
                                      target="_blank"
                                    >
                                      Privacy Policy
                                    </Link>
                                    <span> and </span>{' '}
                                    <Link
                                      to="/CookiePolicy"
                                      class="fw-bold text-dark text-decoration-underline"
                                      target="_blank"
                                    >
                                      Cookie Policy
                                    </Link>
                                  </p>
                                </label>
                              </div>

                              {showOtpDiv ? (
                                <div className="provided-email-otp">
                                  <div className="otp-label">
                                    <p>
                                      OTPs have been sent to your email{' '}
                                      <b>{values.email} </b>and phone{' '}
                                      <b>{values.phone}</b>
                                    </p>
                                  </div>
                                  <div className="row gx-5">
                                    <div className="col-md-6">
                                      <div className="send-otp-field">
                                        <div className="form-group w-100">
                                          <label
                                            htmlFor="EnterOTP"
                                            className="border rounded-4 w-100 position-relative"
                                          >
                                            <span className="fw-bold">
                                              Enter Email OTP
                                            </span>
                                            <input
                                              type="text"
                                              id="EnterOTP"
                                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                              placeholder="Enter 6 digit OTP"
                                              value={otp}
                                              onChange={handleOtpChange}
                                              disabled={!otpEditable}
                                            />
                                          </label>
                                        </div>
                                        {/* <!-- /form-group --> */}
                                        <div className="resend-otp mt-2">
                                          <button
                                            className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                            onClick={sendOTP}
                                          >
                                            Resend OTP
                                          </button>
                                        </div>
                                      </div>
                                      {/* <!-- /send otp field --> */}
                                    </div>
                                    {/* <!-- /col-md-6 --> */}
                                    <div className="col-md-6">
                                      <div className="send-otp-field">
                                        <div className="form-group w-100">
                                          <label
                                            htmlFor="EnterOTP"
                                            className="border rounded-4 w-100 position-relative"
                                          >
                                            <span className="fw-bold">
                                              Enter SMS OTP
                                            </span>
                                            <input
                                              type="text"
                                              id="EnterOTP"
                                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                              placeholder="Enter 6 digit OTP"
                                              value={smsOtp}
                                              onChange={smsOtpChange}
                                              disabled={!smsOtpEditable}
                                            />
                                          </label>
                                        </div>
                                        <div className="resend-otp mt-2">
                                          <button
                                            className="text-dark text-decoration-none fw-semibold text-nowrap border-0 bg-transparent"
                                            onClick={sendOTP}
                                          >
                                            Resend OTP
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    {isOtpVerified && isSmsOtpVerified && (
                                      <div className="col-12">
                                        <div className="solfin-action mt-4 mb-4 d-flex">
                                          {insertDsaMutation.isLoading ? (
                                            <button
                                              className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn1"
                                              type="button"
                                              disabled
                                            >
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                aria-hidden="true"
                                              ></span>
                                              <span
                                                className="visually-hidden"
                                                role="status"
                                              >
                                                Loading...
                                              </span>
                                            </button>
                                          ) : (
                                            <button
                                              type="submit"
                                              value="Register"
                                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                              onClick={handleSubmit}
                                            >
                                              {' '}
                                              Register{' '}
                                            </button>
                                          )}

                                          {/* <input
                                          type="reset"
                                          value="cancel"
                                          onClickCapture={closeerrormessage}
                                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                                        /> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {/* <!-- /row --> */}
                                </div>
                              ) : (
                                ''
                              )}

                              <div className="solfin-action mt-4 mb-4">
                                {editable ? (
                                  <>
                                    {sendOtp.isLoading ? (
                                      <button
                                        className="opacity-100 btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn spinner-btn2"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          aria-hidden="true"
                                        ></span>
                                        <span
                                          className="visually-hidden"
                                          role="status"
                                        >
                                          Loading...
                                        </span>
                                      </button>
                                    ) : (
                                      <input
                                        disabled={!isChecked}
                                        type="submit"
                                        value="Submit"
                                        id="submitButton"
                                        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                        onClick={sendOTP}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isOtpVerified && isSmsOtpVerified ? (
                                      ''
                                    ) : (
                                      <>
                                        <p>
                                          If you haven't received the OTPs,
                                          please check your email/phone number.
                                          In order to change them, click on Back
                                          and re-submit the form
                                        </p>
                                        <button
                                          type="button"
                                          onClick={handleEdit}
                                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                        >
                                          Back
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                                {/* <input
                          type="reset"
                          value="cancel"
                          onClickCapture={closeerrormessage}
                          className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                        /> */}
                              </div>

                              {/* {showOtpDiv && (
                                <div className="provided-email-otp">
                                  <div className="otp-label">
                                    <p>
                                      Please enter the OTP shared on your Email
                                      provided in the form above
                                    </p>
                                  </div>
                                  <div className="row gx-5">
                                    <div className="col-md-6">
                                      <div className="send-otp-field">
                                        <div className="form-group w-100">
                                          <label
                                            for="EnterOTP"
                                            className="border rounded-4 w-100 position-relative"
                                          >
                                            <span className="fw-bold">
                                              Enter Email OTP
                                            </span>
                                            <input
                                              type="text"
                                              id="EnterOTP"
                                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                              placeholder="Enter 6 digit OTP"
                                              value={otp}
                                              onChange={handleOtpChange}
                                              disabled={!otpEditable}
                                            />
                                          </label>
                                        </div>

                                        <div className="resend-otp mt-2">
                                          <a
                                            className="text-dark text-decoration-none fw-semibold text-nowrap"
                                            onClick={sendOTP}
                                          >
                                            Resend OTP
                                          </a>
                                        </div>
                                      </div>

                                    </div>

                                    <div className="col-md-6">
                                      <div className="send-otp-field">
                                        <div className="form-group w-100">
                                          <label
                                            for="EnterOTP"
                                            className="border rounded-4 w-100 position-relative"
                                          >
                                            <span className="fw-bold">
                                              Enter SMS OTP
                                            </span>
                                            <input
                                              type="text"
                                              id="EnterOTP"
                                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                              placeholder="Enter 6 digit OTP"
                                              value={smsOtp}
                                              onChange={smsOtpChange}
                                              disabled={!smsOtpEditable}
                                            />
                                          </label>
                                        </div>
                                        <div className="resend-otp mt-2">
                                          <a
                                            className="text-dark text-decoration-none fw-semibold text-nowrap"
                                            onClick={sendOTP}
                                          >
                                            Resend OTP
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    {isOtpVerified && isSmsOtpVerified && (
                                      <div className="col-12">
                                        <div className="solfin-action mt-4 mb-4">
                                          <button
                                            type="submit"
                                            value="Register"
                                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                                            onClick={handleSubmit}
                                          >
                                            {" "}
                                            Register{" "}
                                          </button>
                                          <input
                                            type="reset"
                                            value="cancel"
                                            onClickCapture={closeerrormessage}
                                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn gray-border"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                </div>
                              )} */}
                              {/* <!-- /provided-email-otp --> */}
                            </div>
                            {/* </div> */}
                          </form>
                        </div>
                      </div>
                      {/* <!-- /first-tab --> */}
                      <div
                        className="tab-pane fade"
                        id="PartnersList-tab-pane"
                        role="tabpanel"
                        aria-labelledby="PartnersList-tab"
                        tabIndex="0"
                      >
                        <DsaPartnerList />

                        {/* <!-- /list-of-partners --> */}
                      </div>
                      {/* <!-- /second-tab --> */}
                    </div>
                    {/* <!-- /tab-content --> */}
                  </div>
                  {/* <!-- /partner-data --> */}
                </div>
                {/* <!-- /registration-form --> */}
                <div className="registration-form d-none">
                  <div className="saving-calculate-form registration-form">
                    {/* <!-- /col-lg-4 col-md-6 --> */}
                    <div className="heading mb-4 pt-lg-4">
                      <span className="fw-semibold saving-title">
                        Contact Person Personal Details
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="FullName"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Full Name</span>
                            <input
                              type="text"
                              id="FullName"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Minimum four charaters"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="Designation"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Designation</span>
                            <input
                              type="text"
                              id="Designation"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Developer e.g."
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Phone"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Phone</span>
                            <input
                              type="tel"
                              id="Phone"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Phone Number"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                  <div className="saving-calculate-form top-devider mt-lg-4 mt-3 pt-lg-5 pt-4">
                    <div className="heading mb-4">
                      <span className="fw-semibold saving-title">
                        Company Details
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="CompanyName"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Company Name</span>
                            <input
                              type="tel"
                              id="CompanyName"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="xyz Pvt. Ltd."
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="CINNumber"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">CIN Number</span>
                            <input
                              type="tel"
                              id="CINNumber"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="L17110MH1973PLC019786"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="Email"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Email</span>
                            <input
                              type="email"
                              id="Email"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Email Address"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Phone"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Phone</span>
                            <input
                              type="tel"
                              id="Phone"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Phone Number"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-8 col-md-8">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Address"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Address</span>
                            <input
                              id="Address"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Type Your Address"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="State"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">State</span>
                            <select
                              id="State"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                            >
                              <option value="1" selected="Select State">
                                Select State
                              </option>
                              <option value="2">Haryana</option>
                              <option value="Pubjab">Punjab</option>
                              <option value="3">Uttar Pardesh</option>
                              <option value="4">Delhi</option>
                            </select>
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="City"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">City</span>
                            <select
                              id="State"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                            >
                              <option value="1" selected="Select City">
                                Select City
                              </option>
                              <option value="2">Panchkula</option>
                              <option value="3">Jind</option>
                              <option value="4">Rewari</option>
                              <option value="5">Jhajjar</option>
                            </select>
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Pincode"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Pincode</span>
                            <input
                              type="text"
                              id="Pincode"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                              placeholder="Area Pincode"
                            />
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-12">
                        <div className="mark-location-map fw-semibold mt-lg-4">
                          <span>Mark Location on Map</span>
                          <div className="map-location mt-3">
                            <img
                              src="/images/mark-map-location.png"
                              alt="Mark Location On Map"
                              title="Mark Location On Map"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                  <div className="saving-calculate-form mt-lg-5 mt-4">
                    <div className="heading mb-4">
                      <span className="fw-semibold saving-title">
                        Address Proof
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <label
                            htmlFor="Documenttype"
                            className="border rounded-4 w-100 position-relative"
                          >
                            <span className="fw-bold">Document type</span>
                            <select
                              id="Documenttype"
                              className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                            >
                              <option value="1" selected="Document type">
                                Document type
                              </option>
                              <option value="2">Aadhar Card</option>
                              <option value="3">PAN Card</option>
                              <option value="4">Voter Card</option>
                              <option value="5">Driving Licence</option>
                            </select>
                          </label>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <div className="border rounded-4 w-100 position-relative label-form">
                            <span className="fw-bold">Document</span>
                            <div className="form-group">
                              <input
                                type="text"
                                name="filename"
                                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                                placeholder="No file selected"
                                readOnly=""
                              />
                              <span className="input-group-btn unit-label upload-docs position-absolute">
                                <div className="btn btn-primary btn-header green-btn custom-file-uploader">
                                  <input
                                    type="file"
                                    name="file"
                                    onChange="this.form.filename.value = this.files.length ? this.files[0].name : ''"
                                  />
                                  Upload
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-lg-12 mb-lg-5 mb-4">
                        <div className="form-group mt-3 ps-lg-4">
                          <div className="checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                            <div className="location-title medium-title">
                              <span className="fw-bold">
                                Financing required?
                              </span>
                            </div>
                            <label
                              htmlFor="Yes"
                              className="checkboxes-view p-0"
                            >
                              <div className="check-now position-relative d-flex align-items-center">
                                <input
                                  type="radio"
                                  defaultChecked
                                  id="Yes"
                                  name="Financing Required"
                                  className="position-absolute top-0"
                                />
                                <div className="now-check rounded-pill position-relative"></div>
                                <span>Yes</span>
                              </div>
                              {/* <!-- /check-now --> */}
                            </label>
                            <label htmlFor="No" className="checkboxes-view p-0">
                              <div className="check-now position-relative d-flex align-items-center">
                                <input
                                  type="radio"
                                  id="No"
                                  name="Financing Required"
                                  className="position-absolute top-0"
                                />
                                <div className="now-check rounded-pill position-relative"></div>
                                <span>No</span>
                              </div>
                              {/* <!-- /check-now --> */}
                            </label>
                          </div>
                          {/* <!-- /checkbox-labels --> */}
                        </div>
                        {/* <!-- /form-group --> */}
                      </div>
                      {/* <!-- /col-lg-4 col-md-6 --> */}
                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="solfin-action mt-4 mb-4">
                            <input
                              type="submit"
                              value="register"
                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn text-white"
                            />
                            <input
                              type="reset"
                              value="cancel"
                              className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase ms-1 light-btn calculate-btn"
                            />
                          </div>
                          <div className="solfin-query-call d-flex align-items-center">
                            <span>Any questions? Call us now.</span>
                            <span className="ps-3">
                              <a href="tel:9599446877">
                                <img
                                  src="/images/call-us.svg"
                                  alt="Call Us"
                                  title="Call Us"
                                />
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <!-- /col-12 --> */}
                    </div>
                    {/* <!-- /row --> */}
                  </div>
                  {/* <!-- /saving-calculate-form --> */}
                </div>
                {/* <!-- /registration-form --> */}
              </div>
              {/* <!-- /col-lg-8 --> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default Dsa;
