import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const Chart = props => {
  return (
    <>
      <div className="cashflow-graph rounded-4 border pt-4 pb-5 ps-lg-5 ps-4 pe-lg-5 pe-4">
        <div className="cashflow-title mb-2">
          <h6 className="fw-bold">Cashflow graph across 25 yrs</h6>
        </div>
        <div className="cashflow-graph text-center">
          <ResponsiveContainer height={400}>
            <BarChart
              data={props.chartData}
              height={300}
              margin={{
                top: 50,
                right: 120,
                left: 120,
                bottom: 5,
              }}
              // barCategoryGap={0}
            >
              <Legend />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="year"
                label={{
                  value: 'Years',
                  position: 'insideBottomRight',
                  offset: -10,
                  style: {fontWeight: 'bold', fill: '#333'},
                }}
              />
              <YAxis
                label={{
                  value: 'Rs',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -10,
                  style: {fontWeight: 'bold', fill: '#333'},
                }}
              />
              <Bar dataKey="Cum_EMI" name="Cum Emi" fill="#98c55b" />
              <Bar
                dataKey="Cum_Net_CashFlow"
                name="Cum Net CashFlow"
                fill="#004953"
              />
              <Bar dataKey="Cum_Savings" name="Cum Saving" fill="#ffc01e" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default Chart;
