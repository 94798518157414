import {useMutation, useQuery} from 'react-query';
import * as querydata from '../../dataService/DataService';
import {useEffect, useState} from 'react';

const WantFinance = () => {
  const locationType = useQuery('LocationType', querydata.locationType);
  const [selectedLocationTypeId, setSelectedLocationTypeId] = useState(3);
  const [documentsRequired, setDocumentsRequired] = useState(
    querydata.getFinanceDocumentRequiredForLocationType(selectedLocationTypeId)
  );
  const {mutate: updateDocs} = useMutation(
    // Pass the mutation function and not its execution result
    () =>
      querydata.getFinanceDocumentRequiredForLocationType(
        selectedLocationTypeId
      ),
    {
      onError: error => {
        console.error(error.message);
      },
      onSuccess: data => {
        if (data && data.data) {
          setDocumentsRequired(data.data);
        }
      },
    }
  );
  useEffect(() => {
    if (updateDocs) {
      updateDocs();
      setDocumentsRequired(
        querydata.getFinanceDocumentRequiredForLocationType(
          selectedLocationTypeId
        )
      );
    }
  }, [selectedLocationTypeId]);

  const handleLocationChange = e => {
    setSelectedLocationTypeId(e.target.value);
  };

  return (
    <>
      <section>
        <div className="saving-calculator header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-6 align-items-center">
                <div className="coming-soon-page text-center">
                  <img
                    src="images/coming-soon.svg"
                    alt="Solfin"
                    title="Coming Soon"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row doc-required-list-finance">
                  <ul style={{listStyleType: 'disc'}}>
                    <li>
                      <div className="doc-required-list-finance rounded-4 ">
                        <div className="row product-name d-flex align-items-center justify-content-center">
                          <div className="col-lg-12 mb-10">
                            <h6 className="fw-bold">
                              Please keep below documents ready
                              <span className="text-theme-green">
                                {' '}
                                to make process easier
                              </span>
                            </h6>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-2">
                              <div className="checkbox-labels border-0 p-0 rounded-4 w-100 d-flex align-items-center">
                                {locationType.data &&
                                  locationType.data.data.map(locationType => {
                                    return (
                                      <>
                                        <label className="checkboxes-view p-0">
                                          <div className="check-now position-relative d-flex align-items-center">
                                            <input
                                              type="radio"
                                              name="locationTypeId"
                                              className="position-absolute top-0"
                                              key={
                                                locationType.location_type_id
                                              }
                                              value={
                                                locationType.location_type_id
                                              }
                                              checked={
                                                locationType.location_type_id ==
                                                selectedLocationTypeId
                                              }
                                              onClick={handleLocationChange}
                                            />
                                            <div className="now-check rounded-pill position-relative"></div>
                                            <span>
                                              {locationType.location_type}
                                            </span>
                                          </div>
                                        </label>
                                      </>
                                    );
                                  })}

                                {/* {errors.locationTypeId && touched.locationTypeId ? (
                      <p className="form-error text-danger">{errors.locationTypeId}</p>
                    ) : null} */}
                              </div>

                              {/* <!-- /checkbox-labels --> */}
                            </div>
                            {/* <!-- /form-group --> */}
                          </div>
                        </div>
                        {documentsRequired && documentsRequired.length > 0 ? (
                          <>
                            {documentsRequired.map((doc, index) => {
                              return (
                                <>
                                  <div className="uses">
                                    <strong>
                                      {index + 1}) {doc.document_name}
                                    </strong>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className="uses">
                              <strong>No document required.</strong>
                            </div>
                          </>
                        )}
                        <div className="loan-action text-center">
                          <a
                            href="https://finance.solfin.co.in/onboard/#/login"
                            className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn calculate-btn"
                          >
                            Apply
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
            </div>
            {/* <!-- /container --> */}
          </div>
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default WantFinance;
