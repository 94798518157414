import StateSelect from '../../../components/StateSelect';
import CitySelect from '../../../components/CitySelect';
import React, {useEffect, useState, useRef} from 'react';

const BasicDetails = ({
  values,
  handleChange,
  setSelectedState,
  selectedState,
  registrationTypeId,
}) => {
  const [errors, setErrors] = useState({});
  return (
    <>
      <div className="heading mb-xl-4 mb-3">
        <span className="fw-semibold saving-title">Basic Details</span>
      </div>
      <div className="row">
        {registrationTypeId == '4' ? (
          <div className="col-xl-4 col-md-6">
            <div className="form-group mb-4">
              <label
                htmlFor="CompanyName"
                className="border rounded-4 w-100 position-relative"
              >
                <span className="fw-bold">Name</span>
                <input
                  type="text"
                  id="CompanyName"
                  className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                  placeholder="Enter Company Name"
                  name="epcName"
                  value={values.epcName}
                  onChange={handleChange}
                />
              </label>
              {errors.epcName ? (
                <p className="form-error text-danger">{errors.epcName}</p>
              ) : null}
              {/* {error.epcName  ? (
                                      <p className="form-error text-danger">
                                        {error.epcName}
                                      </p>
                                    ) : null} */}
            </div>
            {/* <!-- /form-group --> */}
          </div>
        ) : (
          ''
        )}
        <div className="col-xl-4 col-md-6">
          <div className="form-group mb-4">
            <label
              htmlFor="Phone"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold">Phone</span>
              <input
                type="tel"
                id="Phone"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                placeholder="Phone Number"
                name="phone"
                onChange={handleChange}
                value={values.phone}
              />
            </label>
            {errors.phone ? (
              <p className="form-error text-danger">{errors.phone}</p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="form-group mb-4">
            <label
              htmlFor="Pincode"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold">Pincode</span>
              <input
                type="text"
                id="Pincode"
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                placeholder="Area Pincode"
                name="pinCode"
                value={values.pinCode}
                onChange={handleChange}
              />
            </label>
            {errors.pinCode ? (
              <p className="form-error text-danger">{errors.pinCode}</p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="form-group mb-4">
            <label
              htmlFor="expectedLimitInLac"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold">Expected Limit(In Lac)</span>
              <input
                type="number"
                id="expectedLimitInLac"
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                placeholder="Expected Limit"
                name="expectedLimitInLac"
                onChange={handleChange}
                value={values.expectedLimitInLac}
              />
            </label>
            {errors.expectedLimitInLac ? (
              <p className="form-error text-danger">
                {errors.expectedLimitInLac}
              </p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        {/* <!-- /col-lg-4 col-md-6 --> */}
        <div className="col-xl-4 col-md-6">
          <div className="form-group mb-4">
            <StateSelect
              values={values}
              setSelectedState={setSelectedState}
              errors={errors}
              handleChange={handleChange}
            />
            {errors.stateId ? (
              <p className="form-error text-danger">{errors.stateId}</p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        {/* <!-- /col-lg-4 col-md-6 --> */}
        <div className="col-xl-4 col-md-6">
          <div className="form-group mb-4">
            <CitySelect
              parameter={values}
              selectedState={selectedState}
              selectedCity={values.cityId}
              handleChange={handleChange}
            />
            {errors.cityId ? (
              <p className="form-error text-danger">{errors.cityId}</p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        {/* <!-- /col-lg-4 col-md-6 --> */}
        <div
          className={
            registrationTypeId == '4'
              ? 'col-xl-8 col-md-6'
              : 'col-xl-4 col-md-6'
          }
        >
          <div className="form-group mb-4">
            <label
              htmlFor="Address"
              className="border rounded-4 w-100 position-relative"
            >
              <span className="fw-bold">Address</span>
              <input
                id="Address"
                className="form-control border-0 ps-0 bg-transparent pt-1 pb-1"
                placeholder="Type Your Address"
                name="address"
                onChange={handleChange}
                value={values.address}
              />
            </label>

            {errors.address ? (
              <p className="form-error text-danger">{errors.address}</p>
            ) : null}
          </div>
          {/* <!-- /form-group --> */}
        </div>
        {/* <!-- /col-lg-12 --> */}
        <div className="heading mb-xl-4 mb-3">
          <span className="fw-semibold saving-title">
            <b>Note:</b> Solfin, its NBFC partner- Dhanvikas Fiscal Services
            Private Limited and its third party service providers, will use the
            email id and mobile number given by customer on this screen for all
            future communications.
          </span>
        </div>
      </div>
    </>
  );
};
export default BasicDetails;
