import React from 'react';
import {Link} from 'react-router-dom';
const GrievanceRedressalPolicy = () => {
  return (
    <>
      <section>
        <div className="about-solfin header-space">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="solfin-heading">
                  <h2 className="fw-bold heading-color">
                    <span className="text-theme-green">
                      Grievance Redressal Policy{' '}
                    </span>{' '}
                    <span className="text-theme-green">
                      Dhanvikas Fiscal Services Private Limited
                    </span>
                  </h2>
                </div>
                <div className="mt-xl-4 mt-3">
                  <p>Version 1-2024</p>
                  <div className="solfin-heading">
                    <h5 className="fw-bold heading-color">Introduction</h5>
                  </div>
                  <p>
                    Objectives of this Grievance Redressal Policy is set a
                    framework for customer grievance redressal at Dhanvikas
                    Fiscal services Private Limited (herein after referred to as
                    “the Company” or “Dhanvikas” or “DFSPL” or “RE”).
                  </p>
                  <p>
                    All customers of the Company shall be treated fairly and
                    consistently and conduct operations in accordance with
                    prevailing regulatory and statutory requirements.
                  </p>
                  <p>
                    The Company’s redressal channels would be made effective and
                    meaningful by putting in place a structured system to ensure
                    that Customer complaints are redressed seamlessly and well
                    within the committed timeframe.
                  </p>
                  <p>
                    In accordance with the above, the Board of Directors of
                    Dhanvikas Fiscal Services Private Limited being a NBFC-ND BL
                    has hereinafter set up an updated framework for Grievance
                    redressal.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}
            <div className="row mt-xl-5 mt-lg-4 mt-3">
              <div className="solfin-heading">
                <h5 className="fw-bold heading-color">Scope of the Policy</h5>
              </div>
              <div className="para-text mt-xl-4 mt-3">
                <p>
                  The processes contained in this Policy are applicable to all
                  employees (permanent as well as contractual), branches,
                  offices and locations of the Company across the Country and to
                  all activities where there is an interaction with prospective
                  or existing customers.
                </p>
                <p>
                  A complaint is an expression of dissatisfaction or resentment
                  either in the form of a representation or an allegation made
                  in writing or through an approved electronic channel
                  containing a grievance alleging deficiency in the following
                  areas:
                </p>
                <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                  <li>
                    <span>
                      Services, products, policies and procedures of the
                      Company;
                    </span>
                  </li>
                  <li>
                    <span>
                      Services provided to customers by any outsourced agent or
                      agency of the Company;
                    </span>
                  </li>
                  <li>
                    <span>
                      Employee behaviour towards the customers of the Company;
                      and
                    </span>
                  </li>
                  <li>
                    <span>
                      Confidentiality and protection of the personal (including
                      sensitive personal information) and financial information
                      of the Company’s customers.
                    </span>
                  </li>
                </ul>
                <p>
                  Requests that deal with data modification (e.g. request for
                  address change, change of bank details/mandate etc.) or
                  enquiries about loan products / schemes, interest rates or
                  other such similar requests do not fall under the purview of
                  this Policy.
                </p>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Grievance Redressal Mechanism
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    This Grievance Redressal Policy is divided into the
                    following parts.
                  </p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        Part 1 (Grievance Redressal Mechanism) describes the
                        Grievance Redressal Mechanism adopted by the Company;
                      </span>
                    </li>
                    <li>
                      <span>
                        Part 2 (Complaint handling) provides a framework for
                        registration and handling of Customer complaints;
                      </span>
                    </li>
                    <li>
                      <span>
                        Part 3 (Nodal Officer) sets out a detailed framework
                        describing the mechanism for appointment of Nodal
                        Officer for receiving and addressing complaints from
                        Customers against the Company; and
                      </span>
                    </li>
                    <li>
                      <span>
                        Part 4 (Review and Oversight) sets out the general terms
                        and conditions, and procedural aspects for evaluation
                        and review of this Grievance Redressal Policy.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">PART 1</h5>
                  <h6 className="fw-bold heading-color">
                    GRIEVANCE REDRESSAL MECHANISM
                  </h6>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>1) Principles</p>
                  <p>
                    The principles governing the Grievance Redressal Mechanism
                    are as follows:
                  </p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        to establish a robust Grievance Redressal Mechanism for
                        the Company;
                      </span>
                    </li>
                    <li>
                      <span>
                        to constantly devise newer and smarter mechanisms to
                        receive and redress Grievances;{' '}
                      </span>
                    </li>
                    <li>
                      <span>
                        to guide and enable all employees of the Company to work
                        in good faith and without prejudice to the interests of
                        the Customers;
                      </span>
                    </li>
                    <li>
                      <span>
                        to prioritise redressal of Grievances of Customers with
                        disabilities;
                      </span>
                    </li>
                    <li>
                      <span>
                        to redress the Customer’s Grievances arisen on account
                        of services provided by any outsourced agency;
                      </span>
                    </li>
                    <li>
                      <span>
                        to deal with all Grievances in a prompt, efficient,
                        timely and{' '}
                      </span>
                    </li>
                    <li>
                      <span>courteous manner;</span>
                    </li>
                    <li>
                      <span>to treat all Customers fairly at all times;</span>
                    </li>
                    <li>
                      <span>
                        to keep the Customer informed about the mechanism to
                        address their complaints, concerns and grievances within
                        Dhanvikas; and
                      </span>
                    </li>
                    <li>
                      <span>
                        to analyze and implement the feedback received from
                        Customers on a continuous basis.
                      </span>
                    </li>
                  </ul>
                  <p>
                    b. Redressal of Grievances of a Customer with disabilities
                    will be
                  </p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        prioritized once a formal request is made and proof of
                        disability is furnished by such Customer. If any
                        Customer requests for a hard copy of agreement,
                        statements or any other documents, a copy of which has
                        already been provided to the Customer in soft copy, such
                        hard copy may be provided to the Customer by Dhanvikas
                        after levying, at Dhanvikas discretion, a charge of a
                        nominal amount.
                      </span>
                    </li>
                  </ul>
                  <p>
                    c. A consolidated report of all reviews conducted under the
                    Grievance Redressal mechanism shall be submitted to the
                    Board at such regular intervals, as specified by it or
                    required under Applicable Laws.
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Team Sensitization on Handling Complaints
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    The following officers are responsible for handling the
                    grievances of Customers:
                  </p>
                  <p>a) GRO</p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>Appointment: Dhanvikas shall appoint a GRO</span>
                    </li>
                    <li>
                      <span>
                        Role: The GRO has the responsibility to handle
                        grievances of the Customers.
                      </span>{' '}
                    </li>
                    <li>
                      <span>
                        Details of the GRO shall be published on the website and
                        offices of the NBFC.
                      </span>
                    </li>
                  </ul>
                  <p>
                    b) The GRO may be assisted by a team comprising of any
                    individuals or employees of Dhanvikas as determined by
                    Dhanvikas time to time.
                  </p>
                  <p>
                    c) The team handling Grievances from Customers:
                    <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                      <li>
                        <span>
                          shall undergo training to ensure that the Grievances
                          and queries from a Customer are handled in an
                          appropriate manner in accordance with this Grievance
                          Redressal Policy; and
                        </span>
                      </li>
                      <li>
                        <span>
                          are encouraged to work in a manner which helps
                          Dhanvikas in offering a first time, efficient and
                          speedy resolution.
                        </span>
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">PART 2</h5>
                  <h6 className="fw-bold heading-color">
                    REGISTRATION OF COMPLAINTS
                  </h6>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>
                    Manner of Registering Complaints: A Customer can register
                    Grievances in accordance with the process set out in this
                    Grievance Redressal Policy. Dhanvikas follows a ‘three
                    level’ process for registering and addressing all Grievances
                    as described below.
                  </p>
                  <p>
                    Level-1 – Customer Service Team:
                    <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                      <li>
                        <span>
                          Approach: A Customer can approach Dhanvikas in the
                          following manner within 30 days from the date of the
                          transaction relating to the Grievance on
                        </span>
                        <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                          <li>
                            <span>
                              Voice support details on 96198-31616; or
                            </span>
                          </li>
                          <li>
                            <span>
                              email details of the Grievance at
                              operations@dhanvikas.com or
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Acknowledgement</span>
                        <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                          <li>
                            <span>
                              All Grievances received in the manner set out in
                              paragraph 3.A.ii above the Customer will be given
                              an acknowledgement within 24 hours.
                            </span>
                          </li>
                          <li>
                            <span>
                              The customer support team initiates action on the
                              Grievance and where necessary contacts the
                              Customer for any additional details as may be
                              necessary to address the Grievance.
                            </span>
                          </li>
                          <li>
                            <span>
                              The Customers are kept informed of the action
                              taken, the progress while redressing grievances,
                              and/or, the reasons for delay if any, in
                              redressing any Grievance.
                            </span>
                          </li>
                          <li>
                            <span>
                              The Customers are informed via e-mail about the
                              follow up action and the turn around times for
                              complaint resolution.
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Resolution</span>
                        <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                          <li>
                            <span>
                              All Grievances received are resolved within the
                              timelines set out in paragraph 8 below.
                            </span>
                          </li>
                          <li>
                            <span>
                              In case any Grievance takes more than the
                              specified resolution time, the Customers are:
                            </span>
                            <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                              <li>
                                <span>intimated accordingly; and</span>
                              </li>
                              <li>
                                <span>
                                  kept updated on the progress / status of the
                                  Grievance on a periodic basis until the
                                  Grievance is resolved.{' '}
                                </span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>Customer Grievance Redress Escalation</span>
                        <span>In case the Customer: </span>
                        <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                          <li>
                            <span>
                              does not receive a response within the timelines
                              set out in Level 1; or
                            </span>
                          </li>
                          <li>
                            <span>
                              is dissatisfied with the response received from
                              Dhanvikas,
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span>
                          the Customer may escalate the Grievance to the next
                          level as indicated below.
                        </span>
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Level-2 – First Appeal:
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        The Customer can address the Grievance to the below
                        address for escalations in accordance with paragraph
                        7.1.4 above:
                      </span>
                    </li>
                    <li>
                      <span>Grievance Redressal Office:</span>
                      <table className="grievance-policy-table">
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td>Jashmita Kohli</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>Dhanvikas Corporate Office</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>Jasmita@dhanvikas.com</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <span>
                        All escalations received are acknowledged within 24
                        Business hours and a resolution is provided by Dhanvikas
                        within the timelines set out in paragraph 8 below.
                      </span>
                    </li>
                    <li>
                      <span>
                        In case any escalation takes more than the specified
                        resolution time, the Customers are:
                      </span>
                      <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                        <li>
                          <span>intimated accordingly; and</span>
                        </li>
                        <li>
                          <span>
                            kept updated on the progress / status of the
                            escalation on a periodic basis until the escalation
                            is resolved.
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>
                        Customers are required to approach Level 2 with the
                        original ticket number generated by Dhanvikas.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">
                    Level-3 – Second Appeal:
                  </h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        In case there is no response within the defined
                        timeframe for Level 2 or in case the response provided
                        is not satisfactory, the Customer can escalate the
                        matter to:
                      </span>
                    </li>
                    <li>
                      <span>Customer Care Head:</span>
                      <table className="grievance-policy-table">
                        <thead></thead>
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <td>Basant Kumar </td>
                          </tr>

                          <tr>
                            {' '}
                            <td>Address</td>
                            <td>Dhanvikas Gurugram Branch Office</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>Basant.kumar@dhanvikas.com</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <span>
                        All escalations received under Level 3 are acknowledged
                        within 48 business hours and a resolution is provided
                        within the timelines set out in paragraph 8 below.
                      </span>
                    </li>
                    <li>
                      <span>
                        In case any escalation takes more than the specified
                        resolution time, the Customers are:
                      </span>
                      <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                        <li>
                          <span>intimated accordingly; and</span>
                        </li>
                        <li>
                          <span>
                            kept updated on the progress / status of the
                            escalation on a periodic basis until the escalation
                            is resolved.
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <span>
                        While making an escalation under Level 3, the Customer
                        is required to send an email to the Customer Care Head
                        setting out a full description of the Grievance and all
                        necessary details and discrepancies due to which the
                        Grievance could not be resolved.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">Time Estimate</h5>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        Dhanvikas endeavours to address all Grievances within
                        the below mentioned timelines, except where an
                        investigation is involved in resolving the same:
                      </span>
                    </li>
                    <li>
                      <span>Level 1 – Customer Service Team</span>
                      <table className="grievance-policy-table">
                        <tbody>
                          <tr>
                            <td>Response to a Customer’s query / concern</td>
                            <td>24 Business hours</td>
                          </tr>
                          <tr>
                            <td>Follow-up queries</td>
                            <td>48 Business hours</td>
                          </tr>
                          <tr>
                            <td>Customer grievances resolution process</td>
                            <td>15 Business days</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <span>Level 2 – First Appeal</span>
                      <table className="grievance-policy-table">
                        <tbody>
                          <tr>
                            <td>Acknowledgement</td>
                            <td>24 Business hours</td>
                          </tr>
                          <tr>
                            <td>Further response</td>
                            <td>5 Business days</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                    <li>
                      <span>Level 3 – Second Appeal</span>
                      <table className="grievance-policy-table">
                        <tbody>
                          <tr>
                            <td>Acknowledgement</td>
                            <td>48 Business hours</td>
                          </tr>
                          <tr>
                            <td>Further response</td>
                            <td>7 Business days</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>

                    <li>
                      <span>
                        Information related to a complaint/dispute on the Credit
                        Information Companies shall be addressed within 15 days
                        of receipt of complaint.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <!-- /row --> */}
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">PART 3</h5>
                  <h6 className="fw-bold heading-color">NODAL OFFICER</h6>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>Appointment of Nodal Officer</p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        Dhanvikas shall appoint a Nodal Officer and a Principal
                        Nodal Officer (if applicable) in accordance with the RBI
                        Master Directions.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Nodal Officer or Principal Nodal Officer (as the
                        case may be) shall be responsible for representing
                        Dhanvikas and furnishing information to the RBI in
                        relation to the complaints against Dhanvikas in
                        accordance the RBI Master Directions.
                      </span>
                    </li>
                    <li>
                      <span>
                        Details of the Nodal Officer appointed by Dhanvikas are
                        set out in Annexure 1 to this Policy and will also be
                        displayed for the benefit of the Customer at the office
                        and website of Dhanvikas.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">PART 4</h5>
                  <h6 className="fw-bold heading-color">
                    REVIEW AND OVERSIGHT
                  </h6>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>Periodicity of Review</p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        This Grievance Redressal Policy will be reviewed by the
                        Board on a periodical basis as may be deemed appropriate
                        by the Board.
                      </span>
                    </li>
                    <li>
                      <span>
                        The Nodal Officer will furnish periodical reports on
                        his/her activities to the Board, preferably at quarterly
                        intervals, but not less than bi-annually.
                      </span>
                    </li>
                  </ul>
                  <p>Amendments</p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        Any amendments to this Grievance Redressal Policy will
                        be reviewed and approved by the Board.
                      </span>
                    </li>
                    <li>
                      <span>
                        Provisions of this Grievance Redressal Policy are
                        subject to amendments in accordance with Applicable Laws
                        (including rules, regulations, notifications) on the
                        subject as may be issued, from time to time. In case any
                        provisions of this Grievance Redressal Policy are
                        inconsistent with Applicable Laws (including any
                        subsequent amendment(s), clarification(s), circular(s),
                        etc.) then such provisions of Applicable Laws shall
                        prevail over the provisions hereunder and this Grievance
                        Redressal Policy shall be deemed to have been amended to
                        such extent.
                      </span>
                    </li>
                  </ul>
                  <p>Publication</p>
                  <ul className="solfin-list list-unstyled ps-lg-4 mt-lg-4">
                    <li>
                      <span>
                        This Grievance Redressal Policy shall be published on
                        Dhanvikas’s website for the information of various
                        stakeholders.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-xl-5 mt-lg-4 mt-3">
                <div className="solfin-heading">
                  <h5 className="fw-bold heading-color">ANNEXURE 1</h5>
                  <h6 className="fw-bold heading-color">DETAILS OF OFFICERS</h6>
                </div>
                <div className="para-text mt-xl-4 mt-3">
                  <p>Details of the Principal Nodal Officer</p>
                  <table className="grievance-policy-table">
                    <tbody>
                      <tr>
                        <td>Name:</td>
                        <td>Jasmita Kaur Kohli</td>
                      </tr>
                      <tr>
                        <td>Address:</td>
                        <td>
                          C 304, Tharwani Heritage, Sector 7, Kharghar – 410
                          210, Navi Mumbai
                        </td>
                      </tr>
                      <tr>
                        <td>Mobile Number:</td>
                        <td>9619831616</td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td>jasmita.kohli@dhanvikas.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /saving-calculator --> */}
      </section>
    </>
  );
};

export default GrievanceRedressalPolicy;
